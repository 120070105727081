import { gql } from 'urql';

export const collectionDetailsCombinedQuery = gql`
  query ($address: Bytes!) {
    collectionData: collections(where: { collectionAddress: $address }) {
      collection: collectionAddress
      entity
      mintWithLinked
      price
      maxPurchase
      start
      end
      checkInNeeded
      maxMint
      offerType
      passport: passportId
      minRewardBalance
      minVisits
      minFriendVisits
    }
    collectionMetadata: collections(where: { collectionAddress: $address }) {
      collection: collectionAddress
      name
      symbol
      dataURI
      isActive
      areaPoints
      areaRadius
      linkedCollections
      collectionType
    }
    collectionDataAdditional: collections(
      where: { collectionAddress: $address }
    ) {
      collection: collectionAddress
      maxBalance
      mintWithLinkedOnly
      isCoupon
      mintModel
    }
  }
`;

export const userOwnedCollectionsQuery = gql`
  query ($user: Bytes!, $collectionType: BigInt!, $skip: Int!, $take: Int!) {
    userOwnedCollections: collectibleTransfers(
      where: {
        or: [
          {
            from: $user
            rl_collectibleTransfer_: { collectionType: $collectionType }
          }
          {
            to: $user
            rl_collectibleTransfer_: { collectionType: $collectionType }
          }
        ]
      }
      skip: $skip
      first: $take
      orderBy: timestamp
      orderDirection: asc
    ) {
      collection: collectionAddress
      from
      to
      tokenId
      blockNumber
      transactionHash
      transactionIndex
      chainId
      meta: rl_collectibleTransfer {
        collection: collectionAddress
        entity
        mintWithLinked
        price
        maxPurchase
        start
        end
        checkInNeeded
        maxMint
        offerType
        passport: passportId
        minRewardBalance
        minVisits
        minFriendVisits
        name
        symbol
        dataURI
        isActive
        areaPoints
        areaRadius
        linkedCollections
        collectionType
        maxBalance
        mintWithLinkedOnly
        isCoupon
        mintModel
      }
    }
  }
`;

export const whitelistedCollectionsQuery = gql`
  query ($address: Bytes!) {
    whitelistedCollectionsForPassport: collections(
      where: { passportId: $address, isWhitelisted: true }
    ) {
      source: collectionAddress
      chainId
    }
  }
`;

export const tokenOwnershipQuery = gql`
  query ($user: Bytes!, $collectible: Bytes!) {
    userOwnedCollectibles: collectibleTransfers(
      where: {
        or: [
          {
            from: $user
            rl_collectibleTransfer_: { collectionAddress: $collectible }
          }
          {
            to: $user
            rl_collectibleTransfer_: { collectionAddress: $collectible }
          }
        ]
      }
      orderBy: timestamp
      orderDirection: asc
    ) {
      collection: collectionAddress
      blockNumber
      timestamp
      tokenId
      transactionIndex
      from
      to
      transactionHash
    }
  }
`;

export const subscriptionPriceQuery = gql`
  query ($collections: [Bytes!]) {
    subscriptionManagerPrices: subscriptionItems(
      where: { id_in: $collections }
    ) {
      collection: id
      price: subscriptionPrice
    }
  }
`;

export const collectionsWithChainIdQuery = gql`
  query collectionsWithChainIdQuery(
    $collections: [Bytes!]
    $onlyActive: [Boolean!]
  ) {
    collectionsWithChainId: collections(
      where: { collectionAddress_in: $collections, isActive_in: $onlyActive }
      orderBy: addedAtTimestamp
      orderDirection: desc
    ) {
      source: collectionAddress
      chainId
      isWhitelisted
      passportId
      collectionType
      collection: collectionAddress
      entity
      mintWithLinked
      price
      maxPurchase
      start
      end
      checkInNeeded
      maxMint
      offerType
      passport: passportId
      minRewardBalance
      minVisits
      minFriendVisits
      name
      symbol
      dataURI
      isActive
      areaPoints
      areaRadius
      linkedCollections
      maxBalance
      mintWithLinkedOnly
      isCoupon
      mintModel
    }
  }
`;

export const allWhiteListedCollectionsQuery = gql`
  query ($skip: Int, $first: Int) {
    collections(skip: $skip, first: $first, where: { isWhitelisted: true }) {
      isWhitelisted
      chainId
      source: collectionAddress
      passportId
    }
  }
`;
