export enum TicketRevertReason {
  GENERIC = 'GENERIC',
  INVALID_TICKET = 'INVALID TICKET',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_OWNER_OF_TICKET = 'NOT OWNER OF TICKET',
  TICKET_ALREADY_REDEEMED = 'TICKET ALREADY REDEEMED',
  INVALID = 'INVALID',
  INVALID_REDEMPTION_SIGNATURE = 'INVALID REDEMPTION SIGNATURE',
}

export enum OperatorType {
  ENTITYADMIN,
  BARTENDER,
  COLLABORATOR,
  GATEKEEPER,
}
