import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  Text,
  View,
  Platform,
  FlatList,
  StyleSheet,
  Clipboard,
} from 'react-native';

import { ActivityIndicator } from 'react-native-paper';
import purchaseIcon from '../assets/Path-31.png';
import voteIcon from '../assets/greekcolumn.png';
import marketplaceIcon from '../assets/network-4.png';

import { useWeb3AuthContext } from '../hooks/web3authContext';
import { useDispatch } from 'react-redux';
import { getAnynetStaticProvider, getAppConfiguration, getNetwork } from '../appconstants';
import AppLoaderComponent from '../components/Loader';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';

import {
  getExternalLinkedAccounts,
  getUserTXHistory,
  loadUserBalance,
} from '../slices/AppUserSlice';
import { AppDispatch } from '../store';
import { defaultTheme } from '../themes/loot8';
import { Loot8Token__factory } from '../typechain';
import { useAppSelector } from '../hooks';
import { ScreenName } from '../enums/screen.enum';
import TXItem from '../components/TXItem';
import HOCContainer from '../components/HOCContainer';
import ProfileAvatar from '../components/ProfileAvatar';
import AddressChip from '../components/WalletHelp/AddressChip';
import MoreGradientTab from '../components/More/MoreGradientTab.tsx';
import GradientPlusButton from '../components/GradientPlusButton';
import { CashoutSvg, LinkAccountSvg } from '../assets/svg/WalletSvgs';
import GradientText from '../components/GradientText';
import SeeAllTextPressable from '../components/SeeAllTextPressable';
import EmptyList from '../components/EmptyList';
import { GradientInfoSvg } from '../assets/svg/HomeSvgs';

export const WALLET_FUNCTIONS = [
  { key: 'Purchase', name: 'Purchase', iconImage: purchaseIcon },
  { key: 'Marketplace', name: 'Marketplace', iconImage: marketplaceIcon },
  { key: 'Vote', name: 'Vote', iconImage: voteIcon },
];

export interface ITokenBalance {
  address: string;
  balance: string;
}

export interface IWalletToken {
  name: string;
  decimal: number;
  address: string;
  chainId: number;
  imageUrl: string;
}
let appConfig;

// const WalletTokenItem = ({
//   props,
//   tokenBalances,
//   isRefreshBalance,
//   onRefreshBalance,
// }: {
//   props: IWalletToken;
//   tokenBalances: ITokenBalance[];
//   isRefreshBalance;
//   onRefreshBalance;
// }) => {
//   let tokenBalance = null;
//   if (tokenBalances && tokenBalances.length > 0) {
//     tokenBalance = tokenBalances.find(p => p.address == props.address).balance;
//   }
//   const windowWidth = Dimensions.get('window').width;

//   return (
//     <View
//       style={{
//         width: '100%',
//         backgroundColor: '#ffffff21',
//         borderRadius: defaultTheme.CONTENT_RADIUS,
//         marginBottom: 15,
//         padding: 10,
//         borderWidth: 2,
//         borderColor: '#E0E5EE',
//       }}>
//       <View
//         style={{
//           flexDirection: 'row',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}>
//         <View style={{ flexDirection: 'row', alignItems: 'center' }}>
//           <Avatar.Image
//             style={[
//               styles.friendsAvatar,
//               {
//                 backgroundColor: defaultTheme.TEXT_COLOR_PRIMARY,
//                 alignSelf: 'center',
//               },
//             ]}
//             size={40}
//             source={{ uri: props.imageUrl }}
//           />
//           <Text
//             style={{
//               marginLeft: 15,
//               color: defaultTheme.TEXT_COLOR_SECONDARY,
//               fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
//               fontSize: defaultTheme.FONT_SIZE_SMALL,
//             }}>
//             {props.name}
//           </Text>
//         </View>
//         <View>
//           <View
//             style={{ flexDirection: 'row', alignItems: 'center', height: 30 }}>
//             <Text
//               style={{
//                 color: defaultTheme.TEXT_COLOR_SECONDARY,
//                 flexGrow: 1,
//                 textAlign: 'right',
//                 fontFamily: defaultTheme.FONT_FAMILY_BOLD,
//                 fontSize: defaultTheme.FONT_SIZE_SMALL,
//               }}>
//               {tokenBalance}
//             </Text>
//             {isRefreshBalance ? (
//               <>
//                 <ActivityIndicator
//                   size={20}
//                   color={defaultTheme.ACTIVE_PRIMARY_COLOR}></ActivityIndicator>
//               </>
//             ) : (
//               <Pressable
//                 onPress={onRefreshBalance}
//                 style={{ paddingLeft: 5, marginBottom: 2.5 }}>
//                 <FontAwesome
//                   name="refresh"
//                   size={18}
//                   color={defaultTheme.ACTIVE_PRIMARY_COLOR}
//                 />
//               </Pressable>
//             )}
//           </View>
//           <Text
//             style={{
//               fontSize:
//                 windowWidth <= 550
//                   ? defaultTheme.FONT_SIZE_XXSMALL
//                   : defaultTheme.FONT_SIZE_XXMEDIUM,
//               color: '#F4B540',
//               fontFamily: defaultTheme.FONT_FAMILY_MAIN,
//             }}>
//             1 LOOT8 = $1
//           </Text>
//         </View>
//       </View>
//     </View>
//   );
// };

const WalletTokenItem = ({
  walletToken,
  tokenBalances,
  variant,
  size,
}: {
  walletToken: IWalletToken[];
  tokenBalances: ITokenBalance[];
  variant?: 'gradient' | null;
  size?: number | null;
}) => {
  let tokenBalance = '0.00';
  if (
    tokenBalances &&
    tokenBalances.length &&
    walletToken &&
    walletToken.length
  ) {
    const chainId = getNetwork();
    tokenBalance =
      tokenBalances?.find?.(
        (_, index) => chainId == walletToken?.[index]?.chainId,
      )?.balance || '0.00';
  }
  if (variant === 'gradient') {
    return <GradientText size={size || 36}>{tokenBalance}</GradientText>;
  }
  return (
    <Text style={[styles.dollarBalanceStyle, size && { fontSize: size }]}>
      {tokenBalance}
    </Text>
  );
};

function WalletScreen({ route, navigation }) {
  const { networkId, staticProvider, address, connected, wallet, userInfo } =
    useWeb3AuthContext();

  const [refreshing, setRefreshing] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [tokenBalances, setTokenBalances] = useState<ITokenBalance[]>();
  const [walletTokens, setWalletTokens] = useState<IWalletToken[]>();
  const [loading, setLoading] = useState(false);
  const [enableCashout, setEnableCashout] = useState(false);
  // const userFactory = User__factory.connect(addresses[networkId].User, staticProvider);
  // const signatureVerifierFactory = Loot8SignatureVerification__factory.connect(addresses[networkId].Loot8SignatureVerification, staticProvider);
  // const [showAccountLinkedModal, setShowAccountLinkedModal] = React.useState(false);
  const [accountToLink, setAcountToLink] = React.useState(String);
  const linkedAccounts = useAppSelector(
    state => state.AppUser.linkedExternalAccounts,
  );
  // const [extAccountConnected, setExtAccountConnected] = React.useState(false);
  const [refreshWalletBalance, setRefreshWalletBalance] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const {
    UserTxHistory,
    UserData: userData,
    txLoading,
  } = useAppSelector(state => state.AppUser);

  let [WalletTabs, setWalletTabs] = useState([
    {
      id: 1,
      navigate: 'TokenPackages',
      name: 'Deposit Funds',
      icon: <GradientPlusButton size={22} />,
    },
    {
      id: 2,
      navigate: 'LinkedWallet',
      name: 'Link Account',
      icon: <LinkAccountSvg />,
    },
  ]);

  const userName = userData
    ? userData.name + ' #' + userData.id
    : userInfo.name;

  // TODO: behram this needs to used since it was used in the old UI
  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await dispatch(
      loadUserBalance({
        networkID: networkId,
        provider: staticProvider,
        address,
      }),
    ).then(() => setRefreshing(false));
    await dispatch(
      getExternalLinkedAccounts({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
      }),
    );
  }, []);

  useEffect(() => {
    const loadRefreshContent = async () => {
      if (connected && wallet) {
        LogToLoot8Console('wallet refresh');
        await dispatch(
          loadUserBalance({
            networkID: networkId,
            provider: staticProvider,
            address,
          }),
        );
        await dispatch(getUserTXHistory({ address, networkId }));
        if (!linkedAccounts || linkedAccounts?.length == 0) {
          await dispatch(
            getExternalLinkedAccounts({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
            }),
          );
        }
      }
    };
    loadRefreshContent();
  }, [connected]);

  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      new URLSearchParams(window.location.search).get('page') ===
      'marketplace-expasses'
    ) {
      navigation.navigate(ScreenName.MARKET_PLACE_COLLECTIBLE_DETAIL);
    }
  }, []);

  const loadBalanceForWalletTokens = async () => {
    //setLoading(true);
    appConfig = await getAppConfiguration();
    const isWeb = Platform.OS === 'web';
    if (appConfig && appConfig.walletTokens) {
      setWalletTokens(appConfig.walletTokens);
    }
    if (appConfig && appConfig.enableCashoutForWhitelistedOnly) {
      // get whitelisted wallet
      if (appConfig && appConfig.whitelistedCashOutAddresses.length) {
        const wallet = appConfig.whitelistedCashOutAddresses.find(
          x => x.toLowerCase() === address.toLowerCase(),
        );
        if (wallet) {
          if (isWeb) {
            setWalletTabs(prev => {
              return [
                ...prev,
                {
                  id: 3,
                  navigate: 'CashOutTokens',
                  name: 'Cashout',
                  icon: <CashoutSvg />,
                },
              ];
            });
            setEnableCashout(true);
          } else if (WalletTabs.length === 2) {
            setWalletTabs(prev => {
              return [
                ...prev,
                {
                  id: 3,
                  navigate: 'SwapScreen',
                  name: 'Learn more',
                  icon: <GradientInfoSvg />,
                },
              ];
            });
          }
        }
      }
    } else {
      if (isWeb) {
        setWalletTabs(prev => {
          return [
            ...prev,
            {
              id: 3,
              navigate: 'CashOutTokens',
              name: 'Cashout',
              icon: <CashoutSvg />,
            },
          ];
        });
        setEnableCashout(true);
      } else if (WalletTabs.length === 2) {
        setWalletTabs(prev => {
          return [
            ...prev,
            {
              id: 3,
              navigate: 'SwapScreen',
              name: 'Learn more',
              icon: <GradientInfoSvg />,
            },
          ];
        });
      }
    }

    setLoading(false);
    setTokenBalances([]);
    if (appConfig.walletTokens) {
      let allWalletBalances: ITokenBalance[] = [];
      for (let i = 0; i < appConfig.walletTokens.length; i++) {
        const walletToken = appConfig.walletTokens[i];
        try {
          const provider = getAnynetStaticProvider(walletToken.chainId);
          const walletBalance = Loot8Token__factory.connect(
            walletToken.address,
            provider,
          );
          const bal =
            Number(await walletBalance.balanceOf(address)) /
            Math.pow(10, walletToken.decimal);
          allWalletBalances.push({
            address: walletToken.address,
            balance: bal.toFixed(2),
          });
        } catch {
          allWalletBalances.push({
            address: walletToken.address,
            balance: '0.00',
          });
        }
      }
      setTokenBalances(allWalletBalances);
    }
  };

  const copytToClipboard = text => {
    Clipboard.setString(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  useEffect(() => {
    loadBalanceForWalletTokens();
  }, [connected, route?.params?.refreshBalance]);

  const navigateToBuyToken = () => {
    navigation.navigate('TokenPackages');
  };
  const navigateToTXHistory = () => {
    navigation.navigate('TXHistory');
  };

  const onRefreshBalance = async () => {
    setRefreshWalletBalance(true);
    await loadBalanceForWalletTokens();
    setRefreshWalletBalance(false);
  };

  const refetchTxHistory = async () => {
    await dispatch(getUserTXHistory({ address, networkId }));
  };
  return (
    <>
      <View style={styles.profileWrapper}>
        {userData ? (
          <ProfileAvatar
            size={54}
            source={
              userData.avatarURI &&
                userData.avatarURI != '' &&
                userData.avatarURI != 'no-avatar' &&
                !userData.avatarURI.includes('ipfs://') &&
                !userData.avatarURI.includes('assets_avatars_p_') &&
                !userData.avatarURI.includes('file:///')
                ? { uri: userData.avatarURI }
                : null
            }
            userAddress={userData?.wallet}
          />
        ) : (
          <ProfileAvatar
            size={54}
            source={{ uri: userInfo.profileImage }}
            userAddress={userData?.wallet}
          />
        )}
        <Text style={styles.userNameStyle}>{userName}</Text>
      </View>
      <View style={styles.balanceWrapper}>
        <Text style={styles.totalBalanceStyle}>Total Balance</Text>
        <View style={styles.innerBalanceWrapper}>
          <WalletTokenItem
            walletToken={walletTokens}
            tokenBalances={tokenBalances}
            variant="gradient"
          />
          <GradientPlusButton
            size={22}
            onPress={() => {
              navigateToBuyToken();
            }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: 4,
          }}>
          <Text style={styles.dollarBalanceStyle}>$</Text>
          <WalletTokenItem
            walletToken={walletTokens}
            tokenBalances={tokenBalances}
          />
        </View>
      </View>
      <View style={styles.chipWrapper}>
        {isCopied ? (
          <View style={styles.copyTextContainer}>
            <Text
              style={{
                color: '#FFFFFF',
                fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                fontSize: 8,
              }}>
              Copied!
            </Text>
          </View>
        ) : (
          <></>
        )}
        <AddressChip
          text={address}
          onPress={() => {
            copytToClipboard(address);
          }}
        />
      </View>
      <FlatList
        style={{ maxHeight: 110 }}
        data={WalletTabs}
        keyExtractor={(item, index) => item?.name + index}
        renderItem={({ item }) => (
          <MoreGradientTab
            item={item}
            onPress={() => {
              if (item.navigate) {
                navigation.navigate(item.navigate);
              } else {
                return;
              }
            }}
            height={94}
            fullWidth
            disabled={!enableCashout && item.id === 2 ? true : false}
          />
        )}
        contentContainerStyle={{ flexGrow: 1 }}
        columnWrapperStyle={styles.columnWrapperStyle}
        scrollEnabled={false}
        numColumns={4}
      />
      {enableCashout && (
        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}>
          <View style={styles.transactionTextWrapper}>
            <Text
              style={{
                color: defaultTheme.PRIMARY_TEXT_COLOR,
                fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                fontSize: 12,
              }}>
              Latest Transactions
            </Text>
            <SeeAllTextPressable
              onPress={() => {
                navigateToTXHistory();
              }}
            />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {txLoading && UserTxHistory?.length < 1 ? (
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <ActivityIndicator
                  size={'small'}
                  color={defaultTheme.SECONDARY_TEXT_COLOR}
                />
              </View>
            ) : UserTxHistory?.length > 0 ? (
              <FlatList
                extraData={UserTxHistory}
                showsVerticalScrollIndicator={false}
                data={UserTxHistory.slice(0, 4)}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item, index }) => <TXItem item={item} />}
                style={{ flex: 1, marginTop: 10, width: '100%' }}
                contentContainerStyle={{ flexGrow: 1, rowGap: 5 }}
                scrollEnabled={false}
              />
            ) : (
              <View style={{ alignSelf: 'center' }}>
                <EmptyList message="No Transactions Found" />
              </View>
            )}
          </View>
        </View>
      )}
    </>
  );
}

export default HOCContainer({
  OriginalComponent: WalletScreen,
  title: 'My Wallet',
});

const styles = StyleSheet.create({
  profileWrapper: {
    alignItems: 'center',
    rowGap: 12,
  },
  userNameStyle: {
    fontSize: 18,
    lineHeight: 18,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    color: '#FFFFFF',
  },
  balanceWrapper: {
    alignItems: 'center',
    marginTop: 10,
  },
  innerBalanceWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 5,
  },
  totalBalanceStyle: {
    fontSize: 16,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: '#FFFFFF',
  },
  dollarBalanceStyle: {
    fontSize: 12,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: '#FFFFFF',
  },
  columnWrapperStyle: {
    // columnGap: 14,
  },
  chipWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
    position: 'relative',
  },
  copyTextContainer: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    padding: 2,
    width: 50,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    position: 'absolute',
    top: 15,
    borderColor: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    right: Dimensions.get('window').width > 480 ? '35%' : 20,
  },
  transactionTextWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});