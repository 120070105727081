import {
  DeviceEventEmitter,
  FlatList,
  NativeEventEmitter,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import * as Device from 'expo-device';
import React, { useCallback, useEffect, useState } from 'react';
import HOCContainer from '../components/HOCContainer';
import FollowingDetailsCard from '../components/FollowingDetailsCard';
import SectionHeading from '../components/SectionHeading';
import SeeAllTextPressable from '../components/SeeAllTextPressable';
import FollowingAnouncementCard from '../components/FollowingAnouncementCard';
import FollowingActivitiesCard from '../components/FollowingActivitiesCard';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  getAnynetStaticProvider,
  getAppConfiguration,
  PASSPORT_CACHE_LAST_FETCH,
} from '../appconstants';
import {
  clearPassportOffers,
  getCollectibleDetails,
  loadAvailableEventsList,
  loadAvailableOffersList,
  loadPassportOfferList,
  loadThirpartyCollectibleList,
} from '../slices/OfferSlice';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import {
  resetSearchPassport,
  setPassportSearchText,
  updateEntirePassportDetail,
  updateSelectedPassport,
} from '../slices/PassportSlice';
import { getData, storeData } from '../helpers/AppStorage';
import { getSyncedTime } from '../helpers/DateHelper';
import { CustomDeviceEvents } from '../enums/screen.enum';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import Collectibles from '../components/Passport/Tabs/Collectibles';
import FeaturedOffers from '../components/Passport/Tabs/FeaturedOffers';
import AppLoaderComponent from '../components/Loader';
import { CollectibleWebBlurView } from '../components/WebBlurView';
import { BlurView } from 'expo-blur';
import { defaultTheme } from '../themes/loot8';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Events from '../components/Passport/Tabs/Events';
import { useCollectibleMint } from '../hooks/useCollectibleMint';

const isWeb = Platform.OS === 'web';

const FollowingDetailsScreen = ({ navigation, route }) => {
  const pathConfig = isWeb
    ? {
        path: window.location.pathname,
        queryParams: new URLSearchParams(window.location.href.split('?')[1]),
      }
    : null;

  const { passportAddress, chainId } =
    pathConfig &&
    pathConfig.queryParams.get('passportAddress') &&
    pathConfig.queryParams.get('passportAddress') !== ''
      ? {
          passportAddress: pathConfig.queryParams.get('passportAddress'),
          chainId: +pathConfig.queryParams.get('chainId'),
        }
      : route.params;

  const { mintLinkedCollectibles } = useCollectibleMint();

  const {
    address,
    wallet,
    networkId,
    staticProvider,
    clearPassPort,
    updatePassPort,
  } = useWeb3AuthContext();
  const dispatch = useAppDispatch();

  const [selectPassport, setSelectPassport] = useState<ICollectibleDetail>();
  const [isPublicVisit, setIsPublicVisit] = useState(
    route.params?.isPublicVisit || false,
  );

  const allCreatorsDetailsList = useAppSelector(
    state => state.Creators.AllCreatorsDetailsList,
  );
  const entityData = useAppSelector(state => state.Entity.EntityData);
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const linkTo = useLinkTo();

  const init = async () => {
    let passport = allCreatorsDetailsList.find(
      p => p.address == passportAddress,
    );
    if (!passport) {
      const providerToPass = getAnynetStaticProvider(chainId);
      passport = await getCollectibleDetails(
        {
          networkID: chainId,
          provider: providerToPass,
          collectibleAddress: passportAddress,
          address,
          wallet,
        },
        { entityData },
        {},
      );
    }

    if (
      !passport?.collectibleIds?.length ||
      passport?.collectibleIds?.length == 0
    ) {
      setIsPublicVisit(true);
    } else {
      setIsPublicVisit(false);
    }

    setSelectPassport(passport);

    if (passport.collectibleIds?.length > 0) {
      updatePassPort(passport);
      dispatch(updateSelectedPassport(passport));
      mintLinkedCollectibles(passport).then(res => {
        if (res) loadPassportDetails(passport);
      });
    }
    // load passport details
    loadPassportDetails(passport);
  };

  useFocusEffect(
    useCallback(() => {
      if (passportAddress) init();
    }, [passportAddress]),
  );

  const loadPassportDetails = async selectedPassport => {
    if (selectedPassport && selectedPassport?.address) {
      dispatch(
        loadPassportOfferList({
          networkID: networkId,
          provider: staticProvider,
          passport: selectedPassport,
          wallet,
          address,
        }),
      ).then(() => {
        // load offers
        dispatch(
          loadAvailableOffersList({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            passport: selectedPassport,
            userLocation: currenLocation,
          }),
        );
        // load events
        dispatch(
          loadAvailableEventsList({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            passport: selectedPassport,
            userLocation: currenLocation,
          }),
        );
      });
      dispatch(
        loadThirpartyCollectibleList({
          networkID: networkId,
          provider: staticProvider,
          passport: selectedPassport,
          wallet,
          address,
        }),
      );

      const lastRefresh = await getData(
        PASSPORT_CACHE_LAST_FETCH(passportAddress),
      );
      const config = await getAppConfiguration();
      if (!lastRefresh) {
        await storeData(
          PASSPORT_CACHE_LAST_FETCH(passportAddress),
          getSyncedTime(),
        );
      } else if (
        getSyncedTime() - Number(lastRefresh) >
        (config?.refreshPassportCacheFrequency || 5 * 60 * 1000)
      ) {
        getCollectibleDetails(
          {
            networkID: chainId,
            provider: getAnynetStaticProvider(chainId),
            collectibleAddress: passportAddress,
            address,
            wallet,
          },
          { entityData },
          { isCache: false },
        ).then(passport => {
          dispatch(updateEntirePassportDetail(passport));
        });
        await storeData(
          PASSPORT_CACHE_LAST_FETCH(passportAddress),
          getSyncedTime(),
        );
      }
    }
  };

  useEffect(() => {
    let event: any = DeviceEventEmitter;
    if (Platform.OS === 'android') {
      event = new NativeEventEmitter();
    }
    event.addListener(CustomDeviceEvents.HomeTabPressedAgain, () => {
      clearPassportSelected();
    });
    return () =>
      event.removeAllListeners(CustomDeviceEvents.HomeTabPressedAgain);
  }, [navigation]);

  const clearPassportSelected = async function () {
    clearPassPort();
    dispatch(clearPassportOffers());
    dispatch(updateSelectedPassport(null));
    dispatch(resetSearchPassport());
    dispatch(setPassportSearchText(''));
    if (Platform.OS != 'web' && Device.isDevice) {
      linkTo('/home');
    } else {
      if (Platform.OS === 'web') {
        window.history.replaceState(null, '', '/');
      }
      navigation.reset({
        index: 0,
        routes: [
          { name: 'HomeTab', params: { screen: 'HomeScreen', path: 'home' } },
        ],
      });
    }
  };

  if (!selectPassport && isPublicVisit) {
    return (
      <AppLoaderComponent
        deemBg={false}
        loaderText={{ text: 'Loading Profile details...' }}
      />
    );
  }

  return (
    <>
      <FollowingDetailsCard
        selectPassport={selectPassport}
        navigation={navigation}
        isPublicVisit={isPublicVisit}
      />
      {isPublicVisit ? (
        <LockedBlurView
          title="Associated Collectibles"
          description="Unlock your free collectibles by following this profile"
        />
      ) : (
        <Collectibles selectPassport={selectPassport} showLimitedCollectibles />
      )}
      <FeaturedOffers showLimitedOffers />

      {isPublicVisit ? (
        <LockedBlurView
          title="Events"
          description="Unlock Events by following this profile"
        />
      ) : (
        <Events selectPassport={selectPassport} />
      )}

      {/* <FollowingAnouncementCard /> */}
      {/* <SectionHeading SmHeading title={`Social Activites`} /> */}
      {/* <FollowingActivitiesCard /> */}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: FollowingDetailsScreen,
});

const LockedBlurView = ({ title = '', description = '' }) => {
  return (
    <>
      <SectionHeading title={title} />

      {Platform.OS === 'web' ? (
        <CollectibleWebBlurView>
          <View style={styles.blurView}>
            <Icon
              name="lock-outline"
              size={26}
              color={defaultTheme.PRIMARY_TEXT_COLOR}
            />
            <Text style={styles.blurText}>{description}</Text>
          </View>
        </CollectibleWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={styles.blurView}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <Icon
            name="lock-outline"
            size={26}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
          <Text style={styles.blurText}>{description}</Text>
        </BlurView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  blurView: {
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.2)',
    minHeight: 170,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blurText: {
    marginTop: 10,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
  },
});
