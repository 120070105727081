import { Client, createRequest, fetchExchange } from 'urql';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import { entityDataQuery } from '../subgraphQueries/entity';
import { expassEventsDataQuery } from '../subgraphQueries/events';
import { marketplaceListingsDataQuery } from '../subgraphQueries/marketplace';

import {
  usersDataQuery,
  userSubscriptionsQuery,
} from '../subgraphQueries/users';
import {
  tokenOwnershipQuery,
  subscriptionPriceQuery,
  userOwnedCollectionsQuery,
  whitelistedCollectionsQuery,
  collectionDetailsCombinedQuery,
  collectionsWithChainIdQuery,
  allWhiteListedCollectionsQuery,
} from '../subgraphQueries/collection';
import { IUsers } from '../subgraphQueries/IUsers';
import { CollectionType } from '../enums/collection.enum';
import { getNetwork, getSubgraphConfig } from '../appconstants';

const fetchDataSubgraph = async (query: any, params: any) => {
  const config = await getSubgraphConfig();
  const nativeChain = getNetwork();

  const endpoint = config.endpoints[nativeChain];

  const client = new Client({
    url: endpoint,
    exchanges: [fetchExchange],
  });

  const request = createRequest(query, {
    ...params,
  });
  try {
    const response = await client.executeQuery(request);
    return response?.data ?? null;
  } catch (e) {
    LogToLoot8Console('Error while fetching GraphQL Data', e);
    return null;
  }
};

export const fetchCollectionDetailsCombined = async (
  collectionAddress: string,
): Promise<{
  collectionDataAdditional: CollectionDataAdditional;
  collectionMetadata: CollectionMetadata;
  collectionData: CollectionData;
} | null> => {
  const responseData = await fetchDataSubgraph(collectionDetailsCombinedQuery, {
    address: collectionAddress,
  });
  return responseData
    ? {
        collectionDataAdditional: responseData?.collectionDataAdditional?.[0]
          ? {
              ...responseData.collectionDataAdditional[0],
              mintModel: Number(
                responseData.collectionDataAdditional[0]?.mintModel,
              ),
            }
          : null,
        collectionMetadata: responseData?.collectionMetadata?.[0]
          ? {
              ...responseData?.collectionMetadata?.[0],
              name: responseData.collectionMetadata[0]?.name ?? '',
              dataURI: responseData.collectionMetadata[0]?.dataURI ?? '',
              symbol: responseData.collectionMetadata[0]?.symbol ?? '',
              areaPoints: JSON.stringify(
                responseData.collectionMetadata[0]?.areaPoints,
              ),
              linkedCollections: JSON.stringify(
                responseData.collectionMetadata[0]?.linkedCollections,
              ),
              collectionType: Number(
                responseData.collectionMetadata[0]?.collectionType,
              ),
            }
          : null,
        collectionData: responseData?.collectionData?.[0]
          ? {
              ...responseData.collectionData[0],
              offerType: Number(responseData.collectionData[0]?.offerType),
            }
          : null,
      }
    : null;
};

export const fetchWhitelistedCollections = async (
  passportAddress: string,
): Promise<WhitelistedCollections[] | null> => {
  const responseData = await fetchDataSubgraph(whitelistedCollectionsQuery, {
    address: passportAddress,
  });
  return responseData?.whitelistedCollectionsForPassport ?? null;
};

export const fetchUsersData = async (
  skip: number,
  pageSize: number,
): Promise<IUsers | null> => {
  const responseData = await fetchDataSubgraph(usersDataQuery, {
    skip,
    first: pageSize,
  });
  return {
    records:
      responseData?.users.map(user => {
        return {
          ...user,
          dataURI: user?.dataURI ?? '',
        };
      }) ?? null,
  };
};

export const fetchUserSubscriptionsEvents = async (
  user: string,
  collection: string = null,
): Promise<IUserSubscriptions[] | null> => {
  try {
    const responseData = await fetchDataSubgraph(userSubscriptionsQuery, {
      user,
      collection,
    });

    return responseData?.subscriptionManagerTradeEvents
      ? responseData.subscriptionManagerTradeEvents?.map((event: any) => ({
          ...event,
          blockNumber: Number(event?.blockNumber),
          transactionIndex: Number(event?.transactionIndex),
        }))
      : [];
  } catch (error) {
    return [];
  }
};

export const fetchUserOwnedCollectionsData = async (
  user: string,
  collectionType: CollectionType,
  skip: number,
  take: number,
): Promise<IUserOwnedCollection[] | null> => {
  const responseData = await fetchDataSubgraph(userOwnedCollectionsQuery, {
    user,
    collectionType,
    skip,
    take,
  });

  return responseData?.userOwnedCollections
    ? responseData?.userOwnedCollections?.map((collection: any) => {
        const { meta, ...rest } = collection;

        return {
          ...rest,
          blockNumber: Number(collection?.blockNumber),
          transactionIndex: Number(collection?.transactionIndex),
          //* CollectionDataAdditional object
          collectionDataAdditional: {
            collection: meta?.collection,
            maxBalance: meta?.maxBalance,
            mintWithLinkedOnly: meta?.mintWithLinkedOnly,
            isCoupon: meta?.isCoupon,
            mintModel: Number(meta?.mintModel),
          },
          //* CollectionMetadata object
          collectionMetadata: {
            collection: meta?.collection,
            name: meta?.name ?? '',
            symbol: meta?.symbol ?? '',
            dataURI: meta?.dataURI ?? '',
            isActive: meta?.isActive,
            areaPoints: JSON.stringify(meta?.areaPoints),
            areaRadius: meta?.areaRadius,
            linkedCollections: JSON.stringify(meta?.linkedCollections),
            collectionType: Number(meta?.collectionType),
          },
          //* CollectionData object
          collectionData: {
            collection: meta?.collection,
            entity: meta?.entity,
            mintWithLinked: meta?.mintWithLinked,
            price: meta?.price,
            maxPurchase: meta?.maxPurchase,
            start: meta?.start,
            end: meta?.end,
            checkInNeeded: meta?.checkInNeeded,
            maxMint: meta?.maxMint,
            offerType: Number(meta?.offerType),
            passport: meta?.passport ?? '',
            minRewardBalance: meta?.minRewardBalance,
            minVisits: meta?.minVisits,
            minFriendVisits: meta?.minFriendVisits,
          },
        };
      })
    : [];
};

export const fetchAllEntityDetails = async (
  skip: number,
  take: number,
  isActive: boolean,
): Promise<EntityDetails[] | null> => {
  const responseData = await fetchDataSubgraph(entityDataQuery, {
    isActive,
    skip,
    take,
  });
  return responseData?.entitiesData
    ? responseData.entitiesData.map((entity: any) => ({
        ...entity,
        name: entity?.name ?? '', // Convert null name to an empty string
        dataURI: entity?.dataURI ?? '',
      }))
    : null;
};

export const fetchTokenOwner = async (
  user: string,
  collectible: string,
): Promise<TokenOwnership[] | null> => {
  const responseData = await fetchDataSubgraph(tokenOwnershipQuery, {
    user,
    collectible,
  });

  return responseData?.userOwnedCollectibles
    ? responseData?.userOwnedCollectibles?.map((collectible: any) => ({
        ...collectible,
        blockNumber: Number(collectible?.blockNumber),
        transactionIndex: Number(collectible?.transactionIndex),
        timestamp: Number(collectible?.timestamp),
      }))
    : null;
};

export const fetchMarketplaceListings = async (
  skip: number,
  pageSize: number,
  collectionFilter: { collectionType: CollectionType; name_contains?: string },
  orderBy: string,
  orderDirection: 'asc' | 'desc',
): Promise<IMarketplaceListing[] | null> => {
  const responseData = await fetchDataSubgraph(marketplaceListingsDataQuery, {
    skip,
    first: pageSize,
    collectionFilter: collectionFilter,
    orderBy,
    orderDirection,
  });

  return responseData?.marketplaceListings
    ? responseData?.marketplaceListings?.map((listing: any) => ({
        ...listing,
        listingType: Number(listing?.listingType),
        amounts: JSON.stringify(listing?.amounts),
        royaltyRecipients: JSON.stringify(listing?.royaltyRecipients),
      }))
    : null;
};

export const fetchSubscriptionPrices = async (
  collections: string[],
): Promise<ISubscriptionPrice[] | null> => {
  const responseData = await fetchDataSubgraph(subscriptionPriceQuery, {
    collections,
  });
  return responseData?.subscriptionManagerPrices ?? null;
};

export const fetchAssociatedEventForExpass = async (
  expass: string,
): Promise<EventDetails[] | []> => {
  const responseData = await fetchDataSubgraph(expassEventsDataQuery, {
    expass,
  });
  const linkedEventsOfExpass = responseData?.collectibleLinkings
    ?.map?.(item => {
      if (item?.rl_collectibleTwo === null) {
        return { event: item?.collectibleTwo }; // Return collectibleTwo if rl_collectibleTwo is null
      }
      if (item?.rl_collectibleOne === null) {
        return { event: item?.collectibleOne }; // Return collectibleOne if rl_collectibleOne is null
      }
      return null; // Exclude items that don't meet the conditions
    })
    .filter(item => item !== null); // Filter out null values

  return linkedEventsOfExpass ?? [];
};

export const fetchCollectionsWithChainId = async (
  collections: string[],
  onlyActive: boolean[],
): Promise<ICollectionsWithChainId[] | null> => {
  const responseData = await fetchDataSubgraph(collectionsWithChainIdQuery, {
    collections,
    onlyActive,
  });

  return responseData?.collectionsWithChainId
    ? responseData?.collectionsWithChainId?.map((collection: any) => ({
        source: collection?.source,
        chainId: collection?.chainId,
        isWhitelisted: collection?.isWhitelisted,
        passportId: collection?.passportId,
        //* CollectionDataAdditional object
        collectionDataAdditional: {
          collection: collection?.collection,
          maxBalance: collection?.maxBalance,
          mintWithLinkedOnly: collection?.mintWithLinkedOnly,
          isCoupon: collection?.isCoupon,
          mintModel: Number(collection?.mintModel),
        },
        //* CollectionMetadata object
        collectionMetadata: {
          collection: collection?.collection,
          name: collection?.name ?? '',
          symbol: collection?.symbol ?? '',
          dataURI: collection?.dataURI ?? '',
          isActive: collection?.isActive,
          areaPoints: JSON.stringify(collection?.areaPoints),
          areaRadius: collection?.areaRadius,
          linkedCollections: JSON.stringify(collection?.linkedCollections),
          collectionType: Number(collection?.collectionType),
        },
        //* CollectionData object
        collectionData: {
          collection: collection?.collection,
          entity: collection?.entity,
          mintWithLinked: collection?.mintWithLinked,
          price: collection?.price,
          maxPurchase: collection?.maxPurchase,
          start: collection?.start,
          end: collection?.end,
          checkInNeeded: collection?.checkInNeeded,
          maxMint: collection?.maxMint,
          offerType: Number(collection?.offerType),
          passport: collection?.passport ?? '',
          minRewardBalance: collection?.minRewardBalance,
          minVisits: collection?.minVisits,
          minFriendVisits: collection?.minFriendVisits,
        },
      }))
    : [];
};

export const getAllWhiteListedCollections = async (
  skip: number,
  first: number,
): Promise<any[] | []> => {
  const responseData = await fetchDataSubgraph(allWhiteListedCollectionsQuery, {
    skip,
    first,
  });
  return responseData?.collections || [];
};
