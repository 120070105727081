// TODO: add signed meta transactions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  addresses,
  checkNewForwarderCompatibility,
  getNetwork,
  getStaticProvider,
  isNativeChain,
  MASTER_KEY,
  META_TX_DEADLINE,
  NetworkId,
  NETWORKS,
  WEBHOOK_URL,
} from '../appconstants';
import { RootState } from '../store';
import { PrivateForwarder } from '../typechain/PrivateForwarder';
import { setAll } from './helpers';

import { ethers, Wallet } from 'ethers';
import * as Device from 'expo-device';
import { LogCustomError, LogErrors, LogWarning } from '../helpers/AppLogger';
import AwaitLock from '../helpers/AwaitLock';
import { showToastMessage } from '../helpers/Gadgets';
import { wait } from '../helpers/ipfs';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { deleteNativeNotificationToken } from '../helpers/Messages';
import {
  LOOT8Forwarder__factory,
  PrivateForwarder__factory,
  PrivateOwnableForwarder__factory,
  User__factory,
} from '../typechain/factories';
import { LOOT8Forwarder } from '../typechain/LOOT8Forwarder';
import { clearUserState, resetAppUser } from './AppUserSlice';
import { resetCatalog } from './CatalogSlice';
import { clearFriendsState } from './friendsSlice';
import {
  IBaseAsyncThunk,
  IForwardRequest,
  IForwardRequestBuilder,
  IMessageMetaData,
  ITxTypeData,
  IWalletOnlyAsyncThunk,
  IWebhookResponse,
} from './interfaces';
import { clearNotifications } from './NotificationSlice';
import { clearPassportOffers } from './OfferSlice';
import { resetOrder } from './OrderSlice';
import { resetPassportMessage } from './PassportMessageSlice';
import { resetPrivateMessage } from './PrivateMessageSlice';

let lock = new AwaitLock();

export const MetaTxLog = (
  type: 'error' | 'warning',
  meta: { message: string; to: string; networkID: number },
  data: any[],
) => {
  if (type === 'error') {
    LogErrors(
      meta.message,
      meta.to,
      meta.networkID,
      data.map(item => ({
        tag: item,
        value:
          typeof item === 'object' && item !== null
            ? JSON.stringify(item) || String(item)
            : String(item),
      })),
    );
  } else {
    LogWarning(
      meta.message,
      meta.to,
      meta.networkID,
      data.map(item => ({
        tag: item,
        value:
          typeof item === 'object' && item !== null
            ? JSON.stringify(item) || String(item)
            : String(item),
      })),
    );
  }
};

const buildRequest_Loot8Forwarder = async (
  args: IForwardRequestBuilder,
): Promise<IForwardRequest | null> => {
  const { forwarder, params, networkID, wallet } = args;

  let request: IForwardRequest = {
    value: 0,
    gas: undefined,
    nonce: undefined,
    deadline: META_TX_DEADLINE(),
    ...params,
  };

  request['nonce'] = Number(await forwarder.nonces(params.from));
  if (networkID === NetworkId.POLYGON_MAINNET) {
    request['gas'] = 1e6;

    return request;
  } else {
    let gas = 2e6;
    const txSubmit = new Date().getTime();

    try {
      request['gas'] = 15e6;

      let { domain, types, message } = buildTypedData_Loot8Forwarder(
        forwarder,
        request,
        networkID,
      );
      let signature = await wallet._signTypedData(domain, types, message);

      const payload = { ...request, signature };
      const data = forwarder.interface.encodeFunctionData('execute', [payload]);

      gas = (
        await forwarder.provider.estimateGas({
          from: addresses[networkID].RelayerAddress,
          to: addresses[networkID].Loot8Forwarder,
          data: data,
        })
      ).toNumber();

      LogToLoot8Console(`gasEstimated: ${gas}`);
    } catch (e) {
      LogToLoot8Console('timeend-error:', new Date().getTime(), e);
      MetaTxLog(
        'error',
        { message: 'META-TX-GAS-ESTIMATE-ERROR', to: params.to, networkID },
        [
          params,
          txSubmit,
          { txSubmit: String(new Date().getTime() - txSubmit) },
        ],
      );
    }

    request['gas'] = gas;

    return request;
  }
};

const buildRequest = async (
  forwarder: PrivateForwarder,
  input: any,
  networkID,
  wallet: Wallet,
): Promise<any> => {
  const nonce = (await forwarder.getNonce(input.from)).toString();
  if (networkID === NetworkId.POLYGON_MAINNET) {
    return { value: 0, gas: 1e6, nonce, ...input };
  } else {
    let gas = 2e6;
    const submissionTime = new Date().getTime();
    try {
      //const gasPrice = await forwarder.provider.getGasPrice();
      const request = { value: 0, gas: 15e6, nonce, ...input };

      let toSign = await buildTypedData(forwarder, request);
      let signature = await wallet._signTypedData(
        toSign.domain,
        toSign.types,
        toSign.message,
      );
      const data = forwarder.interface.encodeFunctionData('execute', [
        request,
        signature,
      ]);

      gas = (
        await forwarder.provider.estimateGas({
          from: addresses[networkID].RelayerAddress,
          to: addresses[networkID].PrivateForwarder,
          data: data,
        })
      ).toNumber();
      LogToLoot8Console('timeend:', new Date().getTime());
      LogToLoot8Console(`gasEstimated: ${gas}`);
    } catch (e) {
      LogToLoot8Console('timeend-error:', new Date().getTime(), nonce, e);
      LogErrors('META-TX-GAS-ESTIMATE-ERROR', input.to, networkID, [
        { tag: 'from', value: input.from },
        { tag: 'to', value: input.to },
        { tag: 'data', value: input.data },
        { tag: 'submissionTime', value: submissionTime },
        { tag: 'processingTime', value: new Date().getTime() - submissionTime },
      ]);
    }
    return { value: 0, gas: gas, nonce, ...input };
  }
};

const buildTypedData_Loot8Forwarder = (
  forwarder: LOOT8Forwarder,
  request: IForwardRequest,
  networkID: NetworkId,
): ITxTypeData => {
  const typeData = getMetaTxTypeData_Loot8Forwarder(
    networkID,
    forwarder.address,
  );

  return { ...typeData, message: request };
};

const buildTypedData = async (forwarder: PrivateForwarder, request: any) => {
  const chainId = (await forwarder.provider.getNetwork()).chainId;
  const typeData = getMetaTxTypeData(chainId, forwarder.address);
  return { ...typeData, message: request };
};

export interface IAvatarData {
  readonly data: IAvatarList[];
}

export interface IAvatarList {
  readonly name: string;
  readonly base64Data: string;
}

export interface IAppData {
  readonly msgLoading: boolean;
  readonly forceLogoutUser: boolean;
  readonly avatarData: IAvatarData;
  readonly nativeNotificationPermitted: boolean;
}

const initialState: IAppData = {
  msgLoading: false,
  forceLogoutUser: false,
  avatarData: null,
  nativeNotificationPermitted: false,
};

const getMetaTxTypeData_Loot8Forwarder = (
  chainId: number,
  verifyingContract: string,
): Omit<ITxTypeData, 'message'> => {
  return {
    types: {
      ForwardRequest: [
        { name: 'from', type: 'address' },
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'gas', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint48' },
        { name: 'data', type: 'bytes' },
      ],
    },
    domain: {
      name: 'LOOT8 Forwarder',
      version: '1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest',
  };
};

const getMetaTxTypeData = (chainId: number, verifyingContract: string): any => {
  return {
    types: {
      ForwardRequest: [
        { name: 'from', type: 'address' },
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'gas', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'data', type: 'bytes' },
      ],
    },
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest',
  };
};

export const SendMetaTX = createAsyncThunk(
  'app/SignMetaTX',
  async (
    {
      networkID,
      provider,
      to,
      wallet,
      data,
      checkUserDetail = true,
    }: IMessageMetaData,
    { dispatch, getState },
  ): Promise<any> => {
    if (!Boolean(NETWORKS[networkID].chainId)) {
      return {
        status: 'INVALID_CHAIN',
        errMsg: 'The provided chain is not supported: ' + networkID,
      };
    }

    console.log('Send Meta TX Calls Async Thunk');
    //Check if User Exists, if not then logout user
    const userFactory = User__factory.connect(
      addresses[getNetwork()].User,
      getStaticProvider(),
    );
    const userAttributes = await userFactory.userAttributes(wallet.address);

    if (
      checkUserDetail &&
      (!userAttributes || (userAttributes && +userAttributes.id == 0))
    ) {
      dispatch(forceLogoutUser());
      return;
    }

    await lock.acquireAsync();
    try {
      LogToLoot8Console('transaction start');
      const response = await SendMetaTXCall({
        networkID,
        provider,
        to,
        wallet,
        data,
      });
      console.log('response from Meta TX');
      console.log(response);
      return response;
    } finally {
      await wait(1000);
      LogToLoot8Console('transaction end');
      lock.release();
    }
  },
);

export const SendMetaTXCall_Loot8Forwarder = async ({
  networkID,
  provider,
  to,
  wallet,
  data,
}: IMessageMetaData): Promise<any> => {
  try {
    console.log('Send Meta TX Calls - Loot8 Forwarder');
    const from = await wallet.getAddress();

    let forwarder = LOOT8Forwarder__factory.connect(
      addresses[networkID].Loot8Forwarder,
      provider,
    );

    const request = await buildRequest_Loot8Forwarder({
      forwarder,
      params: { from, to, data },
      networkID,
      wallet,
    });
    const { domain, types, message } = buildTypedData_Loot8Forwarder(
      forwarder,
      request,
      networkID,
    );

    const signature = await wallet._signTypedData(domain, types, message);

    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const masterSignature = await masterWallet.signMessage(signature);

    let responseData = await fetch(WEBHOOK_URL(networkID), {
      method: 'POST',
      body: JSON.stringify({
        request: { ...request, signature },
        masterSignature,
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    let response: IWebhookResponse = await responseData.json();
    LogToLoot8Console('relay-response', response);

    let waitResponseLogs: any;
    if (response.status == 'success') {
      let result = response?.result ? JSON.parse(response.result) : null;
      // check error in result object in case of any failure.
      if (result && !result.txHash) {
        const resultValue = JSON.parse(result);
        if (
          resultValue &&
          resultValue.error &&
          resultValue.error.toLowerCase() == 'prohibited'
        ) {
          MetaTxLog(
            'warning',
            { message: 'MINTING-CHECK-FAILED', to, networkID },
            [response, result],
          );
        }
      }

      let tx = result?.txHash
        ? result?.txHash
        : JSON.parse(response.result).txHash;
      const txStartTime = new Date().getTime();
      const txReceipt = provider.waitForTransaction(tx);
      LogToLoot8Console('txReceipt', txReceipt);

      let waitResponse: any = await Promise.race([txReceipt, wait(20000)]);
      waitResponseLogs = waitResponse?.logs;
      LogToLoot8Console('waitResponse', waitResponse);

      if (!waitResponse) {
        LogToLoot8Console('calling waitrepsonse again');
        waitResponse = await Promise.race([txReceipt, wait(60000)]);
        LogToLoot8Console('waitResponse', waitResponse);
        if (waitResponse) {
          const transactionEnd = new Date().getTime();
          const processing = transactionEnd - txStartTime;
          waitResponseLogs = waitResponse?.logs;
          MetaTxLog(
            'warning',
            { message: 'META-TX-TIME-WARNING', to, networkID },
            [response, tx, waitResponseLogs, txStartTime, processing],
          );
        } else {
          MetaTxLog('error', { message: 'META-TX-TIMEOUT', to, networkID }, [
            response,
            txStartTime,
            tx,
          ]);

          return {
            status: 'Error',
            errMsg: 'Something went wrong. Please try again later.',
          };
        }
      }

      return {
        status: 'Success',
        response: response,
        eventLogs: waitResponseLogs,
      };
    } else {
      MetaTxLog('error', { message: 'META-TX-ERROR', to, networkID }, [
        response,
      ]);

      // TODO: Add error handling after testing error responses
      return {
        status: 'Error',
        response: response,
        eventLogs: waitResponseLogs,
      };
    }
  } catch (error) {
    MetaTxLog('error', { message: error, to: wallet.address, networkID }, [
      error,
    ]);
    LogCustomError('SendMetaTXCall', error.name, error.message, error.stack);

    // TODO: Add error handling after testing error responses
    return {
      status: 'Error',
      errMsg: 'Something went wrong. Please try again later.',
    };
  }
};

export const SendMetaTXCall = async ({
  networkID,
  provider,
  to,
  wallet,
  data,
}: IMessageMetaData): Promise<any> => {
  try {
    let isNewForwarderCompatible = checkNewForwarderCompatibility(networkID);

    if (isNewForwarderCompatible) {
      return await SendMetaTXCall_Loot8Forwarder({
        networkID,
        provider,
        to,
        wallet,
        data,
      });
    }

    console.log('Send Meta TX Calls');
    const from = await wallet.getAddress();
    let private_forwarder: any;
    const ADDRESS_FORWADER = addresses[networkID].PrivateForwarder;
    if (isNativeChain(networkID)) {
      private_forwarder = PrivateForwarder__factory.connect(
        ADDRESS_FORWADER,
        provider,
      );
    } else {
      private_forwarder = PrivateOwnableForwarder__factory.connect(
        ADDRESS_FORWADER,
        provider,
      );
    }
    const request = await buildRequest(
      private_forwarder,
      { from, to, data },
      networkID,
      wallet,
    );
    const toSign = await buildTypedData(private_forwarder, request);
    const signature = await wallet._signTypedData(
      toSign.domain,
      toSign.types,
      toSign.message,
    );

    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const masterSignature = await masterWallet.signMessage(signature);

    const submissionTime = new Date().getTime();
    let responseData = await fetch(WEBHOOK_URL(networkID), {
      method: 'POST',
      body: JSON.stringify({ signature, request, masterSignature }),
      headers: { 'Content-Type': 'application/json' },
    });
    let response = await responseData.json();
    LogToLoot8Console('relay-response', response);
    let waitResponseLogs: any;
    if (response.status == 'success') {
      let result = response?.result ? JSON.parse(response.result) : null;
      // check error in result object in case of any failure.
      if (result && !result.txHash) {
        const resultValue = JSON.parse(result);
        if (
          resultValue &&
          resultValue.error &&
          resultValue.error.toLowerCase() == 'prohibited'
        ) {
          LogWarning('MINTING-CHECK-FAILED', to, networkID, [
            { tag: 'autotaskId', value: response.autotaskId },
            { tag: 'autotaskRunId', value: response.autotaskRunId },
            { tag: 'requestId', value: response.requestId },
            { tag: 'result', value: result },
          ]);
        }
      }
      let tx = result?.txHash
        ? result?.txHash
        : JSON.parse(response.result).txHash;
      const transactionStart = new Date().getTime();
      const txReceipt = provider.waitForTransaction(tx);
      LogToLoot8Console('txReceipt', txReceipt);
      let waitResponse: any = await Promise.race([txReceipt, wait(20000)]); //timeout in millisecond
      LogToLoot8Console('waitResponse', waitResponse);
      waitResponseLogs = waitResponse?.logs;
      if (!waitResponse) {
        LogToLoot8Console('calling waitrepsonse again');
        waitResponse = await Promise.race([txReceipt, wait(60000)]); //timeout in millisecond
        LogToLoot8Console('waitResponse', waitResponse);
        if (waitResponse) {
          const transactionEnd = new Date().getTime();
          const timeDiffernt = transactionEnd - transactionStart;
          waitResponseLogs = waitResponse?.logs;
          //transactionHash,autoTaskRunId,blockNumber,networkID,submissionTime
          LogWarning('META-TX-TIME-WARNING', to, networkID, [
            { tag: 'autotaskId', value: response.autotaskId },
            { tag: 'autotaskRunId', value: response.autotaskRunId },
            { tag: 'blockNumber', value: waitResponse?.blockNumber },
            { tag: 'processingTime', value: timeDiffernt },
            { tag: 'processStartTime', value: transactionStart },
            { tag: 'submissionTime', value: submissionTime },
            { tag: 'txReceipt', value: tx },
          ]);
        } else {
          LogErrors('META-TX-TIMEOUT', to, networkID, [
            { tag: 'autotaskId', value: response.autotaskId },
            { tag: 'autotaskRunId', value: response.autotaskRunId },
            { tag: 'atLog', value: response.autotaskRunId },
            { tag: 'submissionTime', value: submissionTime },
            { tag: 'processStartTime', value: transactionStart },
            { tag: 'txReceipt', value: tx },
          ]);
          return {
            status: 'Error',
            errMsg: 'Something went wrong. Please try again later.',
          };
        }
      }
      return {
        status: 'Success',
        response: response,
        eventLogs: waitResponseLogs,
      };
    } else {
      LogErrors('META-TX-ERROR', to, networkID, [
        { tag: 'autotaskId', value: response.autotaskId },
        { tag: 'autotaskRunId', value: response.autotaskRunId },
        { tag: 'status', value: response.status },
        { tag: 'message', value: response.message },
      ]);
      return {
        status: 'Error',
        response: response,
        eventLogs: waitResponseLogs,
      };
    }
  } catch (e) {
    LogToLoot8Console(e);
    LogErrors(e, wallet.address, networkID, [
      {
        tag: 'message',
        value: e.message,
      },
    ]);
    LogCustomError('SendMetaTXCall', e.name, e.message, e.stack);
    return {
      status: 'Error',
      errMsg: 'Something went wrong. Please try again later.',
    };
  }
};

export const logoutUserActions = createAsyncThunk(
  'app/logoutUserActions',
  async (
    { networkID, provider, wallet }: IWalletOnlyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    dispatch(clearFriendsState());
    dispatch(clearUserState());
    dispatch(clearNotifications());
    dispatch(clearPassportOffers());
    dispatch(resetOrder());
    dispatch(resetCatalog());
    dispatch(resetApp());
    dispatch(resetAppUser());
    dispatch(resetPassportMessage());
    dispatch(resetPrivateMessage(null));
    if (Device.isDevice) {
      dispatch(
        removeNotificationToken({ networkID: null, provider: null, wallet }),
      );
    }
  },
);

export const loadAvatarData = createAsyncThunk(
  'app/loadAvatarData',
  async (
    { networkID, provider }: IBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    // const customData = require('../assets/ImagesBase64.json');
    const customData = await (
      await fetch(
        'https://ipfs-prod-01.loot8-services.dev/ipfs/QmTxFdmen3nPLqxWJWZgWd4HVGjGD7E1Z8W7gJXaghVp5f',
      )
    ).json();
    return { avatarData: customData };
  },
);

export const removeNotificationToken = createAsyncThunk(
  'app/removeNotificationToken',
  async ({
    networkID,
    provider,
    wallet,
  }: IWalletOnlyAsyncThunk): Promise<any> => {
    try {
      await deleteNativeNotificationToken(wallet);
    } catch (error) {
      LogCustomError(
        'removeNotificationToken',
        error.name,
        error.message,
        error.stack,
      );
    }
  },
);

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    forceLogoutUser(state) {
      state.forceLogoutUser = true;
    },
    resetApp(state) {
      setAll(state, initialState);
    },
    setNativeNotificationPermission(state, action) {
      state.nativeNotificationPermitted = action.payload.permissionGranted;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SendMetaTX.pending, (state: { msgLoading: boolean }) => {
        state.msgLoading = true;
      })
      .addCase(SendMetaTX.fulfilled, (state, action) => {
        state.msgLoading = false;
        if (action.payload.status == 'Error') {
          showToastMessage(action.payload.errMsg);
        }
        if (action.payload.status == 'INVALID_CHAIN') {
          LogCustomError(
            'SendMetaTX',
            action.payload.status,
            action.payload.errMsg,
            ' ',
          );
        }
      })
      .addCase(
        SendMetaTX.rejected,
        (state: { msgLoading: boolean }, { error }: any) => {
          state.msgLoading = false;
          showToastMessage();
          LogCustomError('SendMetaTX', error.name, error.message, error.stack);
        },
      )
      .addCase(loadAvatarData.fulfilled, (state, action) => {
        state.avatarData = action.payload.avatarData;
      })
      .addCase(
        loadAvatarData.rejected,
        (state: { msgLoading: boolean }, { error }: any) => {
          showToastMessage();
          LogCustomError(
            'loadAvatarData',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const AppSliceReducer = AppSlice.reducer;

const baseInfo = (state: RootState) => state.App;

export const {
  fetchAppSuccess,
  resetApp,
  forceLogoutUser,
  setNativeNotificationPermission,
} = AppSlice.actions;

export const getAppState = createSelector(baseInfo, (app: any) => app);
