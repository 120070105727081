import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  addresses,
  APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS,
  getAnynetStaticProvider,
  getAppConfiguration,
  getSubgraphConfig,
  isNativeChain,
  MINTED_COLLECTION,
  NetworkId,
  NETWORKS,
  ZERO_ADDRESS,
} from '../appconstants';
import { CatalogTypes } from '../enums/catalog.enum';
import { ICatalog } from '../interfaces/ICatalog.interface';
import { RootState } from '../store';
import { setAll } from './helpers';
import { IUserBaseAsyncThunk } from './interfaces';
import { showToastMessage } from '../helpers/Gadgets';
import { LogCustomError } from '../helpers/AppLogger';
import {
  getAllThirdParty,
  getCollectibleDetails,
  getCollectibleIdsForPatron,
  getThirdPartyCollectiableDetails,
  throttledGetCollectibleDetails,
} from './OfferSlice';
import {
  CollectionManager__factory,
  Loot8Collection__factory,
} from '../typechain';
import { CollectionType } from '../enums/collection.enum';
import {
  filterKeys,
  getData,
  removeData,
  storeData,
} from '../helpers/AppStorage';
import { uniqWith } from 'lodash';
import { BigNumber } from 'ethers';
import { getExternalLinkedAccounts } from './AppUserSlice';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import {
  fetchCollectionsWithChainId,
  fetchUserOwnedCollectionsData,
} from '../helpers/GraphQLHelperSubgraph';
import { parseOnlyActiveParam } from '../helpers/APIHelper';

export interface ICatalogSliceData {
  readonly CatalogPassportDetailsList: ICatalog[];
  readonly CatalogMultiplePassportsDetailsList: ICatalog[];
  readonly CatalogEventDetailsList: ICatalog[];
  readonly CatalogCollectiableDetailsList: ICatalog[];
  readonly CatalogThirdyPartyCollectiableDetailsList: ICatalog[];
  readonly loading: boolean;
  readonly loadingPassport: boolean;
  readonly loadingEvent: boolean;
  readonly loadingCollectiable: boolean;
  readonly loadingThirdParty: boolean;
  readonly CatalogOffersDetailsList: ICatalog[];
  readonly loadingOffers: boolean;
}

const initialState: ICatalogSliceData = {
  CatalogPassportDetailsList: [],
  CatalogMultiplePassportsDetailsList: [],
  CatalogEventDetailsList: [],
  CatalogCollectiableDetailsList: [],
  CatalogThirdyPartyCollectiableDetailsList: [],
  loading: false,
  loadingPassport: true,
  loadingEvent: false,
  loadingCollectiable: false,
  loadingThirdParty: false,
  CatalogOffersDetailsList: [],
  loadingOffers: false,
};

export interface ITransferResponse {
  showModal: boolean;
  title: string;
  message: string;
}

async function getUserOwnedCollections(
  userAddress: string,
  collectionType: CollectionType,
): Promise<{ data: IUserOwnedCollection; tokens: string[] }[]> {
  const transfersData: Record<string, IUserOwnedCollection> = {};

  let retries = 3;
  let hasMoreData = true;

  let skip = 0;
  const take = 100;

  while (hasMoreData) {
    try {
      const result = await fetchUserOwnedCollectionsData(
        userAddress,
        collectionType,
        skip,
        take,
      );

      result?.forEach?.(item => {
        const key = `${item.collection}_${item.chainId}`; // Create a unique key for each collection

        // If the collection already exists in transfersData, you can update it or skip
        if (!transfersData[key]) {
          transfersData[key] = item;
        }
      });

      hasMoreData = result.length === take;
    } catch (error) {
      LogCustomError(
        '~ getUserOwnedCollections-fetchUserOwnedCollectionsData',
        error?.message,
        ' ',
        ' ',
      );
      if (retries > 0) {
        retries--;
      } else {
        hasMoreData = false;
      }
    }

    skip += take;
  }

  const ownedTokensByCollection: Record<
    string,
    Record<string, Set<string>>
  > = {};

  Object.values(transfersData).forEach(transfer => {
    const { tokenId, from, to, collection, chainId } = transfer;

    if (!ownedTokensByCollection[collection]) {
      ownedTokensByCollection[collection] = {};
    }

    if (!ownedTokensByCollection[collection][chainId]) {
      ownedTokensByCollection[collection][chainId] = new Set();
    }

    if (to.toLowerCase() === userAddress.toLowerCase()) {
      // User received this token, add it to the collection
      ownedTokensByCollection[collection][chainId].add(tokenId);
    } else if (from.toLowerCase() === userAddress.toLowerCase()) {
      // User sent this token, remove it from the collection
      ownedTokensByCollection[collection][chainId].delete(tokenId);
    }
  });

  // Convert the sets to arrays and filter out collections with no owned tokens
  const result: {
    data: IUserOwnedCollection;
    tokens: string[];
  }[] = [];

  for (const [collection, chains] of Object.entries(ownedTokensByCollection)) {
    for (const [chainId, tokensSet] of Object.entries(chains)) {
      const tokens = Array.from(tokensSet); // Convert Set to array

      if (tokens.length > 0) {
        const key = `${collection}_${chainId}`;
        result.push({ data: transfersData[key], tokens });
      }
    }
  }

  return result;
}

export const getAllMintedCollectionForPatron = async (
  {
    networkID,
    provider,
    address,
    collectionType,
  }: { networkID; provider; address; collectionType },
  isCache = true,
  skipIndexer = false,
): Promise<
  {
    source: string;
    chainId: number;
    prefetchedData?: {
      collectionMetadata?: CollectionMetadata;
      collectionData?: CollectionData;
      collectionDataAdditional?: CollectionDataAdditional;
    };
  }[]
> => {
  let data = isCache
    ? await getData(
        APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS(collectionType),
      )
    : null;

  let availableCollection = data?.availableCollection || null;

  let appConfig;
  try {
    appConfig = await getAppConfiguration();
  } catch (err) {
    LogToLoot8Console(
      'getAllMintedCollectionForPatron: Error while reading app config',
    );
  }

  if (data && data?.timeOfCaching && availableCollection) {
    const now: any = new Date();
    // Check how much time has elasped since data has been cached in milliseconds
    const timeElapsed =
      now?.getTime() - new Date(data?.timeOfCaching)?.getTime?.();
    // Convert milliseconds to seconds
    const timeElapsedSecs = timeElapsed / 1000;
    // Check cache TTL, if not available then default is 30 minutes
    const thresholdSeconds = appConfig?.cacheTTL
      ? appConfig?.cacheTTL * 60
      : 30 * 60;

    if (timeElapsedSecs < thresholdSeconds) {
      // Cache is still valid
      if (availableCollection?.length > 0) return availableCollection;
    } else {
      // Cache has expired
      // Do nothing, Let the data load
    }
  }

  const subgraphConfig = await getSubgraphConfig();

  if (
    subgraphConfig &&
    subgraphConfig.modules &&
    subgraphConfig.modules.userOwnedCollections &&
    !skipIndexer
  ) {
    const response = await getUserOwnedCollections(address, collectionType);
    availableCollection = [];

    if (response?.length > 0) {
      response?.map(collectionItem => {
        const chain = BigNumber.from(collectionItem.data.chainId).toNumber();
        if (Boolean(NETWORKS[chain].chainId))
          availableCollection.push({
            source: collectionItem.data.collection,
            chainId: chain,
            //* Attach Collection Details if available from Subgraph Query
            prefetchedData: {
              collectionData: collectionItem?.data?.collectionData ?? undefined,
              collectionDataAdditional:
                collectionItem?.data?.collectionDataAdditional ?? undefined,
              collectionMetadata:
                collectionItem?.data?.collectionMetadata ?? undefined,
            },
          });
      });
    }
  } else {
    const collectionManager = CollectionManager__factory.connect(
      addresses[networkID].CollectionManager,
      provider,
    );
    availableCollection = (
      await collectionManager.getAllCollectionsForPatron(
        collectionType,
        address,
        false,
      )
    ).map(p => {
      return { source: p, chainId: networkID };
    });

    const allCollectionsWithForeignChains =
      await collectionManager.getListForCollectionType(collectionType);

    const onlyActive = parseOnlyActiveParam(false);
    let parsedCollectionsWithForeignChains = [];

    const ChunkSize = 100;
    const Chunks = [];
    let i = 0;

    // Break the array into chunks of 100
    for (i; i < allCollectionsWithForeignChains.length; i += ChunkSize) {
      Chunks.push(allCollectionsWithForeignChains.slice(i, i + ChunkSize));
    }

    // Process each chunk sequentially
    for (const chunk of Chunks) {
      try {
        //* Fetch from Subgraph
        const result = await fetchCollectionsWithChainId(chunk, onlyActive);
        const parsed = result.map(res => {
          return {
            source: res.source,
            chainId: Number(res.chainId),
            prefetchedData: {
              collectionData: res?.collectionData ?? undefined,
              collectionDataAdditional:
                res?.collectionDataAdditional ?? undefined,
              collectionMetadata: res?.collectionMetadata ?? undefined,
            },
          };
        });
        //* Only push source and chainId into the array
        parsedCollectionsWithForeignChains.push(...parsed);
      } catch (error) {
        LogCustomError(
          'Subgraph call failed, fetching current chunk data directly from smart contract:',
          error?.name,
          error?.message,
          ' ',
        );

        //* Fallback: fetch data from smart contracts
        const contractResults = [];

        for (const collection of chunk) {
          try {
            //* Determine if you need the isActive Status or not
            //* If onlyActive is false (means all results are independent of isActive Status, then don't fetch it)
            //* If onlyActive is true (means only isActive = true should be processed, then fetch and ignore any collection where isActive = false)
            if (onlyActive.length === 1) {
              const isActive = await collectionManager.collectionIsActive(
                collection,
              );
              if (!isActive) continue;
            }
            const chainId = await collectionManager.collectionChainId(
              collection,
            );
            //* Only push source and chainId into the array
            contractResults.push({
              source: collection,
              chainId: BigNumber.from(chainId).toNumber(),
            });
          } catch (contractError) {
            console.error('Smart contract call failed:', contractError);
          }
        }

        parsedCollectionsWithForeignChains.push(...contractResults);
      }
    }

    parsedCollectionsWithForeignChains =
      parsedCollectionsWithForeignChains.filter(item => {
        //* Only Non-Native chains should be considered and,
        //* Non-Native chain should be supported by the platform
        if (
          !isNativeChain(item.chainId) &&
          Boolean(NETWORKS[item.chainId].chainId)
        ) {
          return true;
        }
        return false;
      });

    // const allCollectionsWithForeignChains = (
    //   await collectionManager.getAllCollectionsWithChainId(
    //     collectionType,
    //     false,
    //   )
    // )
    //   .map(p => {
    //     if (p) {
    //       const chain = BigNumber.from(p.chainId).toNumber();
    //       if (!isNativeChain(chain)) {
    //         //* Additional check to ensure Non-Native chain is supported
    //         if (Boolean(NETWORKS[chain].chainId))
    //           return { source: p.source, chainId: chain };
    //       }
    //     }
    //   })
    //   .filter(p => p);

    for (let j = 0; j < parsedCollectionsWithForeignChains.length; j++) {
      const collectionOnForeign = parsedCollectionsWithForeignChains[j];
      const providerToPass = getAnynetStaticProvider(
        collectionOnForeign.chainId,
      );
      const loot8Collectible = Loot8Collection__factory.connect(
        collectionOnForeign.source,
        providerToPass,
      );
      try {
        if (Number(await loot8Collectible.balanceOf(address)) > 0) {
          availableCollection.push({
            source: collectionOnForeign.source,
            chainId: collectionOnForeign.chainId,
            prefetchedData: {
              collectionData: collectionOnForeign?.collectionData ?? undefined,
              collectionDataAdditional:
                collectionOnForeign?.collectionDataAdditional ?? undefined,
              collectionMetadata:
                collectionOnForeign?.collectionMetadata ?? undefined,
            },
          });
        }
      } catch (error) {
        LogCustomError(
          'getAllMintedCollectionForPatron-allCollectionsWithForeignChains-loot8Collectible.balanceOf-' +
            collectionOnForeign.source +
            '-' +
            collectionOnForeign.chainId,
          error?.name,
          error?.message,
          ' ',
        );
      }
    }
  }

  const timeOfCaching = new Date();
  await storeData(
    APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS(collectionType),
    { availableCollection, timeOfCaching },
  );
  return availableCollection;
};

export const getCatalogBalance = async ({
  networkID,
  provider,
  catalogAddress,
  address,
  catType,
  isThirdParty,
}: {
  networkID;
  provider;
  catalogAddress;
  address;
  catType;
  isThirdParty;
}): Promise<any> => {
  try {
    if (isThirdParty) {
      let catalog = Loot8Collection__factory.connect(
        catalogAddress,
        getAnynetStaticProvider(networkID),
      );
      return (await catalog?.balanceOf(address)) ?? 0;
    } else {
      let catalog = Loot8Collection__factory.connect(catalogAddress, provider);
      return (await catalog?.balanceOf(address)) ?? 0;
    }
  } catch (e) {
    LogCustomError('getCatalogBalance', e.name, e.message, ' ', address);
  }
  return 0;

  // if (catType === CatalogTypes.PASSPORT) {
  //   catalog = Loot8Collection__factory.connect(catalogAddress, provider);
  // }
  // else if (catType === CatalogTypes.COLLECTIBLES) {
  //   catalog = Loot8Collection__factory.connect(catalogAddress, provider);
  // }
  // else if (catType === CatalogTypes.EVENT) {
  //   catalog
  // }
};

export const loadCatalogEvent = createAsyncThunk(
  'catalog/loadCatalogEvent',
  async (
    { networkID, provider, address, wallet, userInfo }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    //const allPatronEvents = await getAllCollectionForPatron({ networkID, provider, address, collectionType: CollectionType.EVENT }, true, false);
    const allPatronEvents = await getAllMintedCollectionForPatron(
      { networkID, provider, address, collectionType: CollectionType.TICKET },
      true,
    );

    let catType = CatalogTypes.TICKET;
    let EventList = [];
    await Promise.all(
      allPatronEvents
        .filter(x => x.source !== ZERO_ADDRESS)
        .map(async event => {
          const eventAddress = event.source;
          const chainId = event.chainId;
          let providerToPass = provider;
          if (!isNativeChain(chainId)) {
            providerToPass = getAnynetStaticProvider(chainId);
          }
          const eventBalance = Number(
            await getCatalogBalance({
              networkID: chainId,
              provider: providerToPass,
              catalogAddress: eventAddress,
              address,
              catType,
              isThirdParty: false,
            }),
          );
          if (eventBalance > 0) {
            let collectiable: ICollectibleDetail =
              await throttledGetCollectibleDetails(
                {
                  networkID: chainId,
                  provider: providerToPass,
                  collectibleAddress: eventAddress,
                  address,
                  wallet,
                  prefetchedData: event?.prefetchedData ?? null,
                },
                { entityData },
                {},
              );
            let eventData: ICatalog = {
              image: collectiable.image,
              symbol: collectiable.symbol,
              name: collectiable.name,
              address: collectiable.address,
              catalogType: catType,
              catalogCount: eventBalance,
              details: collectiable.details,
              subTitle: collectiable.subTitle,
              entityAddress: collectiable.entityAddress,
              isActive: collectiable.isActive,
              thumbnailImage: collectiable.thumbnailImage,
              thumbnailImageSize: collectiable.thumbnailImageSize,
              optimizedImage: collectiable.optimizedImage,
              optimizedImageSize: collectiable.optimizedImageSize,
              socialLinks: collectiable.socialLinks,
            };
            dispatch(pushCatalogEvent(eventData));
          }
        }),
    );
  },
);

export const loadCatalogCollectiable = createAsyncThunk(
  'catalog/loadCatalogCollectiable',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userInfo,
      isCache = true,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    // //COLLECTIBLES
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    //const allPatronCollectibles = await getAllCollectionForPatron({ networkID, provider, address, collectionType: CollectionType.COLLECTION }, isCache, false);
    let allPatronCollectibles = await getAllMintedCollectionForPatron(
      {
        networkID,
        provider,
        address,
        collectionType: CollectionType.COLLECTION,
      },
      isCache,
    );
    let catType = CatalogTypes.COLLECTIBLES;

    // user minted collection.
    let userMintedCollection = await filterMintedCollectionNotInSync(
      CollectionType.COLLECTION,
    );
    if (userMintedCollection?.length > 0) {
      userMintedCollection?.map(x => {
        allPatronCollectibles.push({
          source: x?.address,
          chainId: x.chainId,
        });
      });
    }

    await Promise.all(
      allPatronCollectibles
        .filter(x => x.source !== ZERO_ADDRESS)
        .map(async collectible => {
          const collectibleAddress = collectible.source;
          const chainId = collectible.chainId;
          let providerToPass = provider;
          if (!isNativeChain(chainId)) {
            providerToPass = getAnynetStaticProvider(chainId);
          }
          const collectibleBalance = Number(
            await getCatalogBalance({
              networkID: chainId,
              provider: providerToPass,
              catalogAddress: collectibleAddress,
              address,
              catType,
              isThirdParty: false,
            }),
          );
          if (collectibleBalance > 0) {
            // to check if balance of collection is updated
            const tokenLists = await getCollectibleIdsForPatron({
              networkID: chainId,
              provider: providerToPass,
              collectibleAddress,
              address,
            });
            let balanceRefresh = false;
            if (tokenLists && tokenLists.length !== collectibleBalance) {
              balanceRefresh = true;
            }

            // refresh balance to get the lastet timestamp in case minted collection not in sync with Indexer API.
            let isBalanceRefresh =
              userMintedCollection?.length > 0
                ? userMintedCollection?.find(
                    x =>
                      x?.address?.toLowerCase() ==
                      collectible?.source?.toLowerCase(),
                  )
                : null;

            let collectiable: ICollectibleDetail =
              await throttledGetCollectibleDetails(
                {
                  networkID: chainId,
                  provider: providerToPass,
                  collectibleAddress: collectibleAddress,
                  address,
                  wallet,
                  prefetchedData: collectible?.prefetchedData ?? null,
                },
                { entityData },
                {
                  isCache: false,
                  isBalanceRefresh: isBalanceRefresh ? true : balanceRefresh,
                  isMarketPlaceRefresh: false,
                },
              );

            for (let i = 0; i < collectibleBalance; i++) {
              let collectibleData: ICatalog = {
                image: collectiable.image,
                symbol: collectiable.symbol,
                name: collectiable.name,
                address: collectiable.address,
                catalogType: catType,
                catalogCount: collectibleBalance,
                details: collectiable.details,
                subTitle:
                  collectiable.collectibleIds.length > 0 &&
                  collectiable.collectibleIds[i]
                    ? '#' + collectiable.collectibleIds[i]
                    : '',
                entityAddress: collectiable.entityAddress,
                isActive: collectiable.isActive,
                timestamp: collectiable.timestamp,
                chainId,
                collectibleId: collectiable.collectibleIds[i],
                video: collectiable.video,
                isMp4Collectible: collectiable.isMp4Collectible,
                animationUrl: collectiable.animationUrl,
                interactiveCollectible: collectiable?.animationUrl
                  ? true
                  : false,
                thumbnail: collectiable.thumbnail,
                marketPlaceConfig: collectiable?.marketPlaceConfig,
                linkCollectible: collectiable?.linkCollectible,
                thumbnailImage: collectiable.thumbnailImage,
                thumbnailImageSize: collectiable.thumbnailImageSize,
                optimizedImage: collectiable.optimizedImage,
                optimizedImageSize: collectiable.optimizedImageSize,
                socialLinks: collectiable.socialLinks,
              };
              dispatch(pushCatalogCollectible(collectibleData));
            }
          }
        }),
    );
  },
);

export const loadCatalogThirdPartyCollectiable = createAsyncThunk(
  'catalog/loadCatalogThirdPartyCollectiable',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userInfo,
      isCache = true,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let state = getState() as RootState;
    let entityData = state.Entity.EntityData;
    const allThirdPartyCollectibles = await getAllThirdParty(
      { networkID, provider, entityData, address, wallet },
      isCache,
    );
    let CollectiableList = [];
    let catType = CatalogTypes.COLLECTIBLES;
    await Promise.all(
      uniqWith(
        allThirdPartyCollectibles.filter(
          x => x !== ZERO_ADDRESS && x.passportAddress !== ZERO_ADDRESS,
        ),
        (a, b) =>
          a.source.toLowerCase() == b.source.toLowerCase() &&
          a.chainId == b.chainId,
      ).map(async collectiable => {
        const collectiableBalance = Number(
          await getCatalogBalance({
            networkID: collectiable.chainId,
            provider,
            catalogAddress: collectiable.source,
            address,
            catType,
            isThirdParty: true,
          }),
        );
        if (collectiableBalance && collectiableBalance > 0) {
          let collectiableDetails = await getThirdPartyCollectiableDetails(
            {
              chainId: collectiable.chainId,
              provider,
              collectiableAddress: collectiable.source,
              address,
            },
            isCache,
          );
          if (collectiableDetails.length > 0) {
            for (let i = 0; i < collectiableDetails.length; i++) {
              dispatch(
                pushThirdPartyCollectible(
                  getCollectibleObject(
                    catType,
                    collectiableDetails[i],
                    collectiableBalance,
                    collectiable,
                  ),
                ),
              );
            }
          }
        }
      }),
    );

    // Load Third Party Collectibles held by External Wallet associated with Loot8 Wallet.
    if (
      state.AppUser.linkedExternalAccounts?.length == 0 &&
      !state.AppUser.initialLinkedExtAccLoaded
    ) {
      await dispatch(
        getExternalLinkedAccounts({ networkID, provider, address, wallet }),
      );
      state = getState() as RootState;
    }

    if (state.AppUser.linkedExternalAccounts?.length > 0) {
      await Promise.all(
        uniqWith(
          allThirdPartyCollectibles.filter(
            x => x !== ZERO_ADDRESS && x.passportAddress === ZERO_ADDRESS,
          ),
          (a, b) =>
            a.source.toLowerCase() == b.source.toLowerCase() &&
            a.chainId == b.chainId,
        ).map(async collectiable => {
          for (
            let i = 0;
            i < state.AppUser.linkedExternalAccounts.length;
            i++
          ) {
            const extWalletAddress = state.AppUser.linkedExternalAccounts[i];
            const collectiableBalance = Number(
              await getCatalogBalance({
                networkID: collectiable.chainId,
                provider,
                catalogAddress: collectiable.source,
                address: extWalletAddress,
                catType,
                isThirdParty: true,
              }),
            );
            if (collectiableBalance && collectiableBalance > 0) {
              let collectiableDetails = await getThirdPartyCollectiableDetails(
                {
                  chainId: collectiable.chainId,
                  provider,
                  collectiableAddress: collectiable.source,
                  address: extWalletAddress,
                },
                isCache,
              );
              if (collectiableDetails.length > 0) {
                for (let i = 0; i < collectiableDetails.length; i++) {
                  dispatch(
                    pushThirdPartyCollectible(
                      getCollectibleObject(
                        catType,
                        collectiableDetails[i],
                        collectiableBalance,
                        collectiable,
                        extWalletAddress,
                      ),
                    ),
                  );
                }
              }
            }
          }
        }),
      );
    }
  },
);

export const loadCatalogPassport = createAsyncThunk(
  'catalog/loadCatalogPassport',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userInfo,
      isCache = true,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    let catType = CatalogTypes.PASSPORT;
    let passportList: ICatalog[] = [];
    await dispatch(clearCatalogMultiplePassportList({}));
    // let multiplePassportsList: ICatalog[] = [];
    const entityData = state.Entity.EntityData;
    let allPatronPassports = await getAllMintedCollectionForPatron(
      { networkID, provider, address, collectionType: CollectionType.PASSPORT },
      isCache,
    );

    // user minted collection.
    let userMintedCollection = await filterMintedCollectionNotInSync(
      CollectionType.PASSPORT,
    );
    if (userMintedCollection?.length > 0) {
      userMintedCollection?.map(x => {
        allPatronPassports.push({
          source: x?.address,
          chainId: x.chainId,
        });
      });
    }

    let passportChunks = [];
    const chunkSize = 10;
    const validPassports = allPatronPassports?.filter(
      passportAddress => passportAddress.source !== ZERO_ADDRESS,
    );

    for (let i = 0; i < validPassports.length; i += chunkSize) {
      passportChunks.push(validPassports.slice(i, i + chunkSize));
    }

    for (let i = 0; i < passportChunks?.length; i++) {
      await Promise.all(
        passportChunks[i]
          ?.filter(x => x.source !== ZERO_ADDRESS)
          .map(async (collectible: any) => {
            const collectibleAddress = collectible.source;
            const chainId = collectible.chainId;
            let providerToPass = provider;
            if (!isNativeChain(chainId)) {
              providerToPass = getAnynetStaticProvider(chainId);
            }
            // refresh balance to get the lastet timestamp in case minted collection not in sync with Indexer API.
            let isBalanceRefresh =
              userMintedCollection?.length > 0
                ? userMintedCollection?.find(
                    x =>
                      x?.address?.toLowerCase() ==
                      collectible?.source?.toLowerCase(),
                  )
                : null;

            let collectiable: ICollectibleDetail =
              await throttledGetCollectibleDetails(
                {
                  networkID: chainId,
                  provider: providerToPass,
                  collectibleAddress: collectibleAddress,
                  address,
                  wallet,
                  prefetchedData: collectible?.prefetchedData ?? null,
                },
                { entityData },
                { isBalanceRefresh: isBalanceRefresh ? true : false },
              );
            if (collectiable && collectiable.collectibleIds.length > 0) {
              let collectibleToPush = {
                image: collectiable.image,
                symbol: collectiable.symbol,
                name: collectiable.name,
                address: collectiable.address,
                catalogType: catType,
                catalogCount: collectiable.collectibleCount,
                details: collectiable.details,
                passportId:
                  collectiable.collectibleIds.length > 0
                    ? collectiable.collectibleIds[0]
                    : '',
                collectibleId:
                  collectiable.collectibleIds.length > 0
                    ? collectiable.collectibleIds[0]
                    : '',
                subTitle:
                  'Passport #' +
                  (collectiable.collectibleIds.length > 0
                    ? collectiable.collectibleIds[0]
                    : ''),
                entityAddress: collectiable.entityAddress,
                isActive: collectiable.isActive,
                //discord: collectiable?.discord,
                //telegram: collectiable?.telegram,
                socialLinks: collectiable?.socialLinks,
                passportType: collectiable?.passportType,
                marketPlaceConfig: collectiable?.marketPlaceConfig,
                chainId,
                thirdPartyVerifiedURL: collectiable?.thirdPartyVerifiedURL,
                buyPrice: collectiable?.buyPrice,
                collectibleIds: collectiable.collectibleIds,
                thumbnailImage: collectiable.thumbnailImage,
                thumbnailImageSize: collectiable.thumbnailImageSize,
                optimizedImage: collectiable.optimizedImage,
                optimizedImageSize: collectiable.optimizedImageSize,
                timestamp: collectiable.timestamp,
                isPremium: collectiable.isPremium,
                category: collectiable.category,
              };
              passportList.push(collectibleToPush);
              // multiplePassportsList.push(collectibleToPush);
              await dispatch(
                updateCatalogMultiplePassportList(collectibleToPush),
              );
              //For same kind of multiple passports bought from marketplace, display them separately on Portfolio list page
              if (collectiable.collectibleIds.length > 1) {
                for (let i = 1; i < collectiable.collectibleIds.length; i++) {
                  collectibleToPush = {
                    ...collectibleToPush,
                    collectibleId: collectiable.collectibleIds[i],
                    subTitle: 'Passport #' + collectiable.collectibleIds[i],
                  };
                  // multiplePassportsList.push(collectibleToPush);
                  await dispatch(
                    updateCatalogMultiplePassportList(collectibleToPush),
                  );
                }
              }
            }
          }),
      );
    }

    return { CatalogPassportDetailsList: passportList };
  },
);

export const loadCatalogOffers = createAsyncThunk(
  'catalog/loadCatalogOffers',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userInfo,
      isCache = true,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    // OFFERS
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    dispatch(clearCatalogOffers());
    const allPatronOffers = await getAllMintedCollectionForPatron(
      { networkID, provider, address, collectionType: CollectionType.OFFER },
      isCache,
    );
    let catType = CatalogTypes.COLLECTIBLES;
    await Promise.all(
      allPatronOffers
        .filter(x => x.source !== ZERO_ADDRESS)
        .map(async collectible => {
          const collectibleAddress = collectible.source;
          const chainId = collectible.chainId;
          let providerToPass = provider;
          if (!isNativeChain(chainId)) {
            providerToPass = getAnynetStaticProvider(chainId);
          }
          const collectibleBalance = Number(
            await getCatalogBalance({
              networkID: chainId,
              provider: providerToPass,
              catalogAddress: collectibleAddress,
              address,
              catType,
              isThirdParty: false,
            }),
          );
          if (collectibleBalance > 0) {
            let collectiable: ICollectibleDetail =
              await throttledGetCollectibleDetails(
                {
                  networkID: chainId,
                  provider: providerToPass,
                  collectibleAddress: collectibleAddress,
                  address,
                  wallet,
                  prefetchedData: collectible?.prefetchedData ?? null,
                },
                { entityData },
                {},
              );
            if (collectiable && collectiable.isOrderPlaced) {
              for (let i = 0; i < collectibleBalance; i++) {
                let collectibleData: ICatalog = {
                  image: collectiable.image,
                  symbol: collectiable.symbol,
                  name: collectiable.name,
                  address: collectiable.address,
                  catalogType: catType,
                  catalogCount: collectibleBalance,
                  details: collectiable.details,
                  subTitle:
                    collectiable.collectibleIds.length > 0 &&
                    collectiable.collectibleIds[i]
                      ? '#' + collectiable.collectibleIds[i]
                      : '',
                  entityAddress: collectiable.entityAddress,
                  isActive: collectiable.isActive,
                  timestamp: collectiable.timestamp,
                  chainId,
                  isCoupon: collectiable.isCoupon,
                  collectibleId: collectiable.collectibleIds[i],
                  tokenId:
                    collectiable.collectibleIds[i] || collectiable?.tokenId,
                  collectionType: collectiable?.collectionType,
                  thumbnailImage: collectiable.thumbnailImage,
                  thumbnailImageSize: collectiable.thumbnailImageSize,
                  optimizedImage: collectiable.optimizedImage,
                  optimizedImageSize: collectiable.optimizedImageSize,
                  socialLinks: collectiable.socialLinks,
                };
                dispatch(pushCatalogOffers(collectibleData));
              }
            }
          }
        }),
    );
  },
);

export const loadAllCatList = createAsyncThunk(
  'catalog/loadAllCatalogs',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userInfo,
      loadPassportList = true,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    if (loadPassportList) {
      dispatch(
        reloadCatPassportList({
          networkID,
          provider,
          address,
          wallet,
          userInfo,
        }),
      );
    }
    dispatch(
      reloadCatEventList({ networkID, provider, address, wallet, userInfo }),
    );
    dispatch(
      reloadCatCollectibleList({
        networkID,
        provider,
        address,
        wallet,
        userInfo,
      }),
    );
  },
);

export const reloadCatPassportList = createAsyncThunk(
  'catalog/reloadCatPassportList',
  async (
    { networkID, provider, address, wallet, userInfo }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    await dispatch(
      loadCatalogPassport({ networkID, provider, address, wallet, userInfo }),
    );
  },
);

export const reloadCatEventList = createAsyncThunk(
  'catalog/reloadCatEventList',
  async (
    { networkID, provider, address, wallet, userInfo }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    await dispatch(clearCatalogEvent());
    await dispatch(
      loadCatalogEvent({ networkID, provider, address, wallet, userInfo }),
    );
  },
);

export const reloadCatCollectibleList = createAsyncThunk(
  'catalog/reloadCatCollectibleList',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userInfo,
      isCache = true,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    // await dispatch(clearThirdPartyCollectible());
    await dispatch(
      loadCatalogCollectiable({
        networkID,
        provider,
        address,
        wallet,
        userInfo,
        isCache,
      }),
    );
    await dispatch(
      loadCatalogThirdPartyCollectiable({
        networkID,
        provider,
        address,
        wallet,
        userInfo,
        isCache,
      }),
    );
    await dispatch(
      loadCatalogOffers({
        networkID,
        provider,
        address,
        wallet,
        userInfo,
        isCache,
      }),
    );
  },
);

// filter minted collection with indexer api and return those collection which is not in sync in indexer API.
export const filterMintedCollectionNotInSync = async (
  collectionType: CollectionType,
) => {
  // get minted collection from cache.
  let fKeys = await filterKeys(MINTED_COLLECTION(''));
  let mintedCollection = [];
  if (fKeys?.length > 0) {
    await Promise.all(
      fKeys?.map(async x => {
        let collection = await getData(x);
        if (collection?.collectionType == collectionType) {
          mintedCollection.push(collection);
        }
      }),
    );
  }
  let userMintedCollection = [];
  if (mintedCollection?.length > 0) {
    await Promise.all(
      mintedCollection?.map(async x => {
        // remove minted collection from cache if indexer API is in sync.
        await removeData(MINTED_COLLECTION(x?.address));
      }),
    );
  }
  return userMintedCollection;
};

const getCollectibleObject = (
  catType,
  collectiableDetails,
  collectiableBalance,
  collectiable,
  extWalletAddress = undefined,
) => {
  return {
    image: collectiableDetails.image,
    symbol: '',
    name: collectiableDetails.name,
    address: collectiableDetails.address,
    catalogType: catType,
    catalogCount: collectiableBalance,
    video: collectiableDetails.video,
    thumbnail: collectiableDetails.thumbnail,
    isMp4Collectible: collectiableDetails.isMp4Collectible,
    is3rdPartyCollectible: true, //for now we have  3rd party collectible only
    details: collectiableDetails.details,
    subTitle: collectiableDetails.tokenId
      ? '#' + collectiableDetails.tokenId
      : '',
    entityAddress: collectiableDetails.entityAddress,
    isActive: collectiableDetails.isActive,
    timestamp: collectiableDetails.timestamp,
    collectibleId: collectiableDetails.tokenId,
    chainId: collectiable.chainId,
    isThirdParty: true,
    animationUrl: collectiableDetails.animationUrl,
    interactiveCollectible: collectiableDetails?.animationUrl ? true : false,
    fromExternalWallet: extWalletAddress ? true : false,
    extWalletAddress,
    thumbnailImage: collectiableDetails.thumbnailImage,
    thumbnailImageSize: collectiableDetails.thumbnailImageSize,
    optimizedImage: collectiableDetails.optimizedImage,
    optimizedImageSize: collectiableDetails.optimizedImageSize,
  };
};

const CatalogSlice = createSlice({
  name: 'CatalogDetails',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    clearCatalogEvent(state) {
      state.CatalogEventDetailsList = [];
    },
    pushCatalogEvent(state, action) {
      let existing = state.CatalogEventDetailsList.find(
        x => x.address == action.payload.address,
      );
      if (!existing) {
        state.CatalogEventDetailsList.push(action.payload);
      } else {
        state.CatalogEventDetailsList = state.CatalogEventDetailsList.map(x => {
          if (x.address == action.payload.address) {
            return action.payload;
          } else {
            return x;
          }
        });
      }
    },
    pushCatalogCollectible(state, action) {
      let existing = state.CatalogCollectiableDetailsList.find(
        x =>
          x.address == action.payload.address &&
          x.collectibleId == action.payload.collectibleId,
      );
      if (!existing) {
        state.CatalogCollectiableDetailsList.push(action.payload);
      } else {
        state.CatalogCollectiableDetailsList =
          state.CatalogCollectiableDetailsList.map(x => {
            if (
              x.address == action.payload.address &&
              x.collectibleId == action.payload.collectibleId
            ) {
              return action.payload;
            } else {
              return x;
            }
          });
      }
    },
    clearThirdPartyCollectible(state) {
      state.CatalogThirdyPartyCollectiableDetailsList = [];
    },
    clearSpecificCatalogCollectible(state, action) {
      let { collectionAddress, tokenId } = action.payload;
      if (state.CatalogCollectiableDetailsList?.length > 0) {
        state.CatalogCollectiableDetailsList =
          state.CatalogCollectiableDetailsList.filter(
            p =>
              !(
                p.address === collectionAddress &&
                Number(p.collectibleId) === Number(tokenId)
              ),
          );
      }
    },
    pushThirdPartyCollectible(state, action) {
      let existing = state.CatalogThirdyPartyCollectiableDetailsList.find(
        x =>
          x.address == action.payload.address &&
          x.collectibleId == action.payload.collectibleId,
      );
      if (!existing) {
        state.CatalogThirdyPartyCollectiableDetailsList.push(action.payload);
      } else {
        state.CatalogThirdyPartyCollectiableDetailsList =
          state.CatalogThirdyPartyCollectiableDetailsList.map(x => {
            if (
              x.address == action.payload.address &&
              x.collectibleId == action.payload.collectibleId
            ) {
              return action.payload;
            } else {
              return x;
            }
          });
      }
    },
    clearSpecificThirdPartyCollectible(state, action) {
      let { collectionAddress, tokenId } = action.payload;
      if (state.CatalogThirdyPartyCollectiableDetailsList?.length > 0) {
        state.CatalogThirdyPartyCollectiableDetailsList =
          state.CatalogThirdyPartyCollectiableDetailsList.filter(
            p =>
              !(
                p.address === collectionAddress &&
                Number(p.collectibleId) === Number(tokenId)
              ),
          );
      }
    },
    resetCatalog(state) {
      setAll(state, initialState);
    },
    pushCatalogOffers(state, action) {
      let existing = state.CatalogOffersDetailsList.find(
        x =>
          x.address == action.payload.address &&
          x.collectibleId == action.payload.collectibleId,
      );
      if (!existing) {
        state.CatalogOffersDetailsList.push(action.payload);
      } else {
        state.CatalogOffersDetailsList = state.CatalogOffersDetailsList.map(
          x => {
            if (
              x.address == action.payload.address &&
              x.collectibleId == action.payload.collectibleId
            ) {
              return action.payload;
            } else {
              return x;
            }
          },
        );
      }
    },
    clearCatalogOffers(state) {
      state.CatalogOffersDetailsList = [];
    },
    updateCatalogMultiplePassportList(state, action) {
      // state.CatalogMultiplePassportsDetailsList = action.payload.multiplePassportsDetailsList
      state.CatalogMultiplePassportsDetailsList =
        state.CatalogMultiplePassportsDetailsList.filter(x => {
          return (
            x.address?.toLowerCase() != action.payload.address?.toLowerCase()
          );
        });
      state.CatalogMultiplePassportsDetailsList.push(action.payload);
    },
    clearCatalogMultiplePassportList(state, action) {
      state.CatalogMultiplePassportsDetailsList = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAllCatList.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loadAllCatList.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(
        loadAllCatList.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadAllCatList',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadCatalogPassport.fulfilled, (state, action) => {
        state.CatalogPassportDetailsList =
          action.payload.CatalogPassportDetailsList;
        state.loadingPassport = false;
      })
      .addCase(
        loadCatalogPassport.pending,
        (state: { loadingPassport: boolean }) => {
          state.loadingPassport = true;
        },
      )
      .addCase(
        loadCatalogPassport.rejected,
        (state: { loadingPassport: boolean }, { error }: any) => {
          state.loadingPassport = false;
          showToastMessage();
          LogCustomError(
            'loadCatalogPassport',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadCatalogEvent.fulfilled, (state, action) => {
        // state.CatalogEventDetailsList = action.payload.CatalogEventDetailsList;
        state.loadingEvent = false;
      })
      .addCase(loadCatalogEvent.pending, (state: { loadingEvent: boolean }) => {
        state.loadingEvent = true;
      })
      .addCase(
        loadCatalogEvent.rejected,
        (state: { loadingEvent: boolean }, { error }: any) => {
          state.loadingEvent = false;
          showToastMessage();
          LogCustomError(
            'loadCatalogEvent',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadCatalogCollectiable.fulfilled, (state, action) => {
        state.loadingCollectiable = false;
      })
      .addCase(
        loadCatalogCollectiable.pending,
        (state: { loadingCollectiable: boolean }) => {
          state.loadingCollectiable = true;
        },
      )
      .addCase(
        loadCatalogCollectiable.rejected,
        (state: { loadingCollectiable: boolean }, { error }: any) => {
          state.loadingCollectiable = false;
          showToastMessage();
          LogCustomError(
            'loadCatalogCollectiable',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadCatalogThirdPartyCollectiable.fulfilled, (state, action) => {
        // state.CatalogThirdyPartyCollectiableDetailsList = action.payload.CatalogThirdyPartyCollectiableDetailsList;
        state.loadingThirdParty = false;
      })
      .addCase(
        loadCatalogThirdPartyCollectiable.pending,
        (state: { loadingThirdParty: boolean }) => {
          state.loadingThirdParty = true;
        },
      )
      .addCase(
        loadCatalogThirdPartyCollectiable.rejected,
        (state: { loadingThirdParty: boolean }, { error }: any) => {
          state.loadingThirdParty = false;
          showToastMessage();
          LogCustomError(
            'loadCatalogThirdPartyCollectiable',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadCatalogOffers.fulfilled, (state, action) => {
        state.loadingOffers = false;
      })
      .addCase(
        loadCatalogOffers.pending,
        (state: { loadingOffers: boolean }) => {
          state.loadingOffers = true;
        },
      )
      .addCase(
        loadCatalogOffers.rejected,
        (state: { loadingOffers: boolean }, { error }: any) => {
          state.loadingOffers = false;
          showToastMessage();
          LogCustomError(
            'loadCatalogOffers',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const CatalogSliceReducer = CatalogSlice.reducer;

const baseInfo = (state: RootState) => state.Catalogs;

export const {
  fetchAppSuccess,
  clearCatalogEvent,
  pushCatalogEvent,
  pushCatalogCollectible,
  clearThirdPartyCollectible,
  pushThirdPartyCollectible,
  resetCatalog,
  clearSpecificCatalogCollectible,
  clearSpecificThirdPartyCollectible,
  pushCatalogOffers,
  clearCatalogOffers,
  updateCatalogMultiplePassportList,
  clearCatalogMultiplePassportList,
} = CatalogSlice.actions;

export const getCatalogState = createSelector(
  baseInfo,
  CatalogSlice => CatalogSlice,
);
