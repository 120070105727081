import React, { useEffect, useState } from 'react';
import {
  DeviceEventEmitter,
  NativeEventEmitter,
  Platform,
  Pressable,
  Text,
  View,
} from 'react-native';
import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';
import CachedImage from '../CachedImage';
import { TouchableRipple } from 'react-native-paper';
import ThreeDotsSettingsButton from '../ThreeDotsSettingsButton';
import { Image } from 'react-native';
import { CustomDeviceEvents, ScreenName } from '../../enums/screen.enum';
import { CatalogTypes } from '../../enums/catalog.enum';
import { ToastCustomMessageType } from '../../appconstants';
import { getData } from '../../helpers/AppStorage';
import {
  approveForSubscription,
  getPassportFinalBuyPrice,
  updatePassportBuyPrice,
} from '../../slices/PassportSlice';
import { useAppDispatch } from '../../hooks';
import {
  formatPriceUpToTrillion,
  showToastMessage,
} from '../../helpers/Gadgets';
import token from '../../assets/Loot8TokenBlack.png';
import { getMarketplaceListingsData } from '../../helpers/MarketPlace';
import { PassportType } from '../../enums/passportCategory.enum';
import { useNavigation } from '@react-navigation/native';
import GradientPrice from '../GradientPrice';
import FollowUnFollowButton from '../FollowUnFollowButton';
import useIsResponsive from '../../hooks/useIsResponsive';
import ModalComponent from '../Modal';
import GradientButton from '../GradientButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { followCreator } from '../../slices/CreatorsSlice';

interface TrendingExpassCardProps {
  item: any;
  navigation: any;
}
const isWeb = Platform.OS === 'web';

const TrendingExpassCard: React.FC<TrendingExpassCardProps> = ({
  item,
  navigation,
}) => {
  const isResponsive = useIsResponsive();
  const dispatch = useDispatch<AppDispatch>();

  const { userInfo, networkId, staticProvider, wallet, address } =
    useWeb3AuthContext();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [subscribing, setSubscribing] = React.useState(false);

  async function onFollowPressed() {
    try {
      setSubscribing(true);
      setIsModalVisible(false);

      // * Check if the Free Profile is Limited
      if (
        item?.maxTokens &&
        item.maxTokens > 0 &&
        item.collectibleIds?.length == 0
      ) {
        // * Check if the Mint Limit has not exceeded yet
        let subscriptionAllowed =
          item.collectionCollectibleIds - 2 < item.maxTokens;

        if (!subscriptionAllowed) {
          // * The Limited Expass has reached mint limit
          showToastMessage(
            ToastCustomMessageType.INFO,
            "This Profile has reached it's follow limit",
          );
          setSubscribing(false);
          return;
        }
      }

      // * Approve For Subscribe/Follow
      await dispatch(
        approveForSubscription({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
        }),
      );

      // * Follow/Subscribe Creator
      const response = await dispatch(
        followCreator({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: item.address,
          passportSubscribePrice: item?.buyPrice,
          userInfo: userInfo,
          collectionType: CatalogTypes.PASSPORT,
        }),
      );

      if (response.payload) {
        navigation.navigate('Success', {
          screen: ScreenName.SUCCESS_SCREEN,
          params: {
            message: `You have successfully followed ${item?.name}`,
            buttonLabel: 'Open Profile',
            navigateTo: 'BottomTab',
            navigationParams: {
              screen: 'HomeTab',
              params: {
                screen: 'FollowingDetails',
                params: {
                  passportAddress: item.address,
                  chainId: item.chainId,
                },
              },
            },
          },
        });
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setSubscribing(false);
    }
  }

  const navigateToFollowingDetail = () => {
    if (Platform.OS === 'web') {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set('page', 'expasses');
      urlSearchParams.set('passportAddress', item.address);
      urlSearchParams.set('chainId', item.chainId);
      urlSearchParams.set('type', 'profile');
      const newURL = window.location.origin + '?' + urlSearchParams.toString();
      window.history.pushState(null, 'expasses', newURL);
    }

    navigation.navigate('FollowingDetails', {
      passportAddress: item.address,
      chainId: item.chainId,
      isPublicVisit: true,
    });
  };

  return (
    <Pressable
      onPress={subscribing ? undefined : navigateToFollowingDetail}
      style={[styles.container, { flex: isResponsive ? 0.49 : 1 }]}>
      <RenderCachedImage
        item={item}
        isResponsive={false}
        resizeMode={'cover'}
        tileWidth={125}
        tileHeight={114}
      />
      <View style={styles.right}>
        <View style={styles.row}>
          <View style={{ flex: 0.95 }}>
            <Text style={styles.title} numberOfLines={2}>
              {item?.name}
            </Text>
            <Text style={styles.subtitle} numberOfLines={1}>
              {item.category}
            </Text>
          </View>
          <FollowUnFollowButton
            onPress={() => setIsModalVisible(true)}
            width={95}
            status={item.status}
            loading={subscribing}
          />
        </View>

        {/* {item.subitle && (
          <Text style={styles.subtitle} numberOfLines={1}>
            {item.creator && `@${item.creator} |`} {item.subtitle}
          </Text>
        )} */}

        {item.details && (
          <Text style={styles.description} numberOfLines={2}>
            {item.details}
          </Text>
        )}
      </View>

      <ModalComponent
        showModal={isModalVisible}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => setIsModalVisible(false)}>
        <View style={{ padding: 30, alignContent: 'center' }}>
          <Text style={{ color: 'white' }}>
            Are you sure you want to Follow this Creator?
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}>
          <GradientButton
            width="48%"
            type="secondary"
            buttonLabel="Cancel"
            onPress={() => setIsModalVisible(false)}
          />
          <GradientButton
            width="48%"
            buttonLabel="Follow"
            onPress={onFollowPressed}
            type="alternate"
          />
        </View>
      </ModalComponent>

      <ModalComponent
        showModal={showErrorModal}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => {
          setShowErrorModal(false);
        }}>
        <View style={{ padding: 30, alignContent: 'center' }}>
          <Text style={styles.modalTextStyle}>Something went wrong.</Text>
          <Text style={styles.modalTextStyle}>Please try again!!</Text>
        </View>
      </ModalComponent>
    </Pressable>
  );
};

export default TrendingExpassCard;

const RenderCachedImage = ({
  item,
  isResponsive,
  type = undefined,
  hovered = undefined,
  tileWidth,
  tileHeight,
  resizeMode,
}) => {
  return (
    <CachedImage
      isBackground
      isResponsive={isResponsive}
      item={item}
      type={type}
      hovered={hovered}
      noImageOnError={true}
      source={{
        uri: item?.thumbnailImage !== '' ? item?.thumbnailImage : item?.image,
      }}
      isThumbnailImage={item?.thumbnailImage !== '' ? true : false}
      imageStyle={{ borderRadius: defaultTheme.CONTENT_RADIUS }}
      style={{
        maxWidth: isResponsive ? '100%' : tileWidth - 12,
        minHeight: tileHeight !== null ? tileHeight : tileWidth - 60,
        aspectRatio: tileHeight !== null ? tileWidth / tileHeight : 41 / 25,
        borderRadius: defaultTheme.CONTENT_RADIUS,
        borderWidth: 1,
        borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
      }}
      contentFit={Platform.OS == 'android' ? 'cover' : resizeMode}
      imageSize={item?.imageSize}
      thumbnailImageSize={item?.thumbnailImageSize}
      optimizedImageSize={item?.optimizedImageSize}></CachedImage>
  );
};

const PassportPriceComponent = ({
  type,
  item,
  updateBuyPrice,
  onPriceTagPress,
}) => {
  const dispatch = useAppDispatch();
  const [latestBuyPrice, setLatestBuyPrice] = useState(
    type === CatalogTypes.PREMIUMCHAT ? item?.price : item?.buyPrice,
  );

  // uncomment this code after integration

  // useEffect(() => {
  //   if (updateBuyPrice) {
  //     (async () => {
  //       // Fetch latest price before proceeding for purchase
  //       const buyPrice = await getPassportFinalBuyPrice(item?.address);
  //       dispatch(
  //         updatePassportBuyPrice({
  //           address: item?.address,
  //           buyPrice: buyPrice,
  //         }),
  //       );
  //       setLatestBuyPrice(buyPrice);
  //     })();
  //   }

  //   let event: any = DeviceEventEmitter;
  //   if (Platform.OS === 'android') {
  //     event = new NativeEventEmitter();
  //   }
  //   event.addListener(CustomDeviceEvents.ExPassPriceUpdated, async e => {
  //     const localStoredData = await getData(
  //       APP_STORAGE_GET_COLLECTIBLEDETAILS(item.address),
  //     );
  //     if (localStoredData) {
  //       setLatestBuyPrice(localStoredData.buyPrice);
  //     }
  //   });
  //   return () =>
  //     event.removeAllListeners(CustomDeviceEvents.ExPassPriceUpdated);
  // }, []);

  // useEffect(() => {
  //   // update local price state when item state changes
  //   setLatestBuyPrice(
  //     type === CatalogTypes.PREMIUMCHAT ? item?.price : item?.buyPrice,
  //   );
  // }, [item?.buyPrice, item?.price]);

  return (
    <GradientPrice
      onPress={onPriceTagPress}
      price={formatPriceUpToTrillion(latestBuyPrice)}
    />
  );
};
