import { gql } from 'urql';

export const marketplaceListingsDataQuery = gql`
  query marketplaceListings(
    $skip: Int!
    $first: Int!
    $orderBy: Listing_orderBy
    $orderDirection: OrderDirection
    $collectionFilter: Collection_filter
  ) {
    marketplaceListings: listings(
      skip: $skip
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { rl_listings_: $collectionFilter, status: ACTIVE }
    ) {
      listingId: id
      collection
      tokenId
      price
      listingType
      blockNumber: listedAtBlockNumber
      paymentToken
      price
      seller
      passport
      sellerShare
      royaltyRecipients
      amounts
      marketplaceFees: fees
      rl_listings {
        name
        chainId
      }
    }
  }
`;
