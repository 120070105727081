import { gql } from 'urql';

export const usersDataQuery = gql`
  query ($skip: Int, $first: Int) {
    users(
      skip: $skip
      first: $first
      orderDirection: asc
      orderBy: registeredAtTimestamp
    ) {
      wallet: id
      name
      userId
      dataURI
      avatarURI
    }
  }
`;

export const userSubscriptionsQuery = gql`
  query ($user: Bytes!, $collection: Bytes!) {
    subscriptionManagerTradeEvents: subscriptionTrades(
      where: { trader: $user, collection: $collection }
    ) {
      price
      isBuy
      blockNumber
      transactionHash
      collectionAmount
      transactionIndex
    }
  }
`;
