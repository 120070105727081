import { gql } from 'urql';

export const expassEventsDataQuery = gql`
  query linkedEventsOfExpass($expass: Bytes!) {
    collectibleLinkings(
      where: {
        or: [
          { collectibleOne: $expass, isLinked: true }
          { collectibleTwo: $expass, isLinked: true }
        ]
      }
    ) {
      collectibleTwo
      collectibleOne
      isLinked
      rl_collectibleOne {
        name
      }
      rl_collectibleTwo {
        name
      }
    }
  }
`;
