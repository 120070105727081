import { ethers } from 'ethers';
import { createSelector } from 'reselect';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { convertAreaData } from '../../slices/helpers';
import { CollectionType } from '../../enums/collection.enum';
import { getIPFSData, getIPFSLink } from '../../helpers/ipfs';
import { getData, storeData } from '../../helpers/AppStorage';
import { getAllWhitelistedCollectibleList } from './collectibleSlice';
import { ICollectibleDetail } from '../interfaces/ICollection.interface';
import { getSocialMediaAccess } from '../../slices/PassportMessageSlice';
// import { fetchCollectionDetailsCombined } from '../../helpers/GraphQLHelper';
// LOOT8-5387
import { fetchCollectionDetailsCombined } from '../../helpers/GraphQLHelperSubgraph';
import {
  AddFreeExPassAsyncThunk,
  ICollectibleAsyncThunk,
  ICollectionBaseAsyncThunk,
  IMessageMetaData,
} from './interfaces';
import {
  CATEGORIES,
  OfferType,
  EstPortalPassportType,
} from '../enums/offers.enum';
import {
  addresses,
  getAnynetStaticProvider,
  APP_STORAGE_GET_COLLECTIBLEDETAILS,
  getAppConfiguration,
  NetworkId,
  ZERO_ADDRESS,
  getSubgraphConfig,
} from '../../appconstants';
import {
  CollectionManager__factory,
  CollectionHelper__factory,
  Loot8UniformCollection__factory,
  SubscriptionManager__factory,
  Loot8TieredCouponCollection__factory,
} from '../../typechain';
import {
  GetAddCollectionMessage,
  GetAddSubscriptionPassportLogData,
  GetCollectionCreatedLogData,
  GetCreateCollectionMessage,
  GetMarketPlaceTradablityMessage,
  GetSubscriptionPassportDataMessage,
} from '../helpers/MsgHelper';
import { SendMetaTX } from './AppSlice';
import { getZeroArray } from './helpers';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';

export interface IPassportSliceData {
  readonly loading: boolean;
  readonly AllEntityPassportDetails: ICollectibleDetail[];
}

// TODO: 5687 IExternalCollectionManager: Imported from typechain > factories > CollectionManager
// export const getAllCollectibleList = async ({ networkID, provider, collectibleType, entity }: { networkID, provider, collectibleType, entity }): Promise<IExternalCollectionManager.ContractDetailsStructOutput[]> => {
export const getAllCollectibleList = async ({
  networkID,
  provider,
  collectibleType,
  entity,
}: {
  networkID;
  provider;
  collectibleType;
  entity;
}): Promise<any[]> => {
  const CollectionManager = CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  const availableCollectibles = await CollectionManager.getCollectionsForEntity(
    entity,
    collectibleType,
    false,
  );
  return availableCollectibles;
};

export const getCollectibleDetails = async (
  {
    networkID,
    provider,
    userAddress,
    collectibleData,
    index,
    entityAddress,
    isSuperAdmin,
  }: ICollectibleAsyncThunk,
  { isCache = true, fetchLatest = false },
): Promise<ICollectibleDetail> => {
  let collectible: ICollectibleDetail = null;
  let collectibleAddress = collectibleData.source;
  let collectibleChainID = Number(collectibleData.chainId);

  const collectionManager = CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  const collectionHelper = CollectionHelper__factory.connect(
    addresses[networkID].CollectionHelper,
    provider,
  );
  const loot8Collection = Loot8UniformCollection__factory.connect(
    collectibleAddress,
    getAnynetStaticProvider(collectibleChainID),
  );
  const subscriptionManager = SubscriptionManager__factory.connect(
    addresses[networkID].SubscriptionManager,
    provider,
  );
  let cachedCollectible = await getData(
    APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress),
  );

  const loot8CollectionOwnerAddress = await loot8Collection.owner();

  if (
    !(
      isSuperAdmin ||
      userAddress.toLowerCase() == loot8CollectionOwnerAddress.toLowerCase()
    )
  ) {
    return;
  }

  if (
    isCache &&
    cachedCollectible &&
    cachedCollectible?.entityAddress === entityAddress
  ) {
    return cachedCollectible;
  }

  collectible = {
    index: index,
    dataURI: '',
    imageProps: {
      image: '',
      imageSize: 0,
    },
    details: '',
    name: '',
    address: '',
    symbol: '',
    isActive: false,
    entityAddress: '',
    price: '0.00',
    priceRate: 0,
    start: null,
    end: null,
    category: CATEGORIES.OTHER,
    offerType: OfferType.NOTANYOFFER,
    collectionType: CollectionType.ANY,
    linkCollectible: [],
    subTitle: '',
    whitelistedCollections: [],
    totalSupply: 0,
    tokensEarned: 0,
    privateMessageCap: 0,
    maxMint: 0,
    maxPurchase: 0,
    chain: null,
    htmlTemplate: null,
    mintWithLinked: false,
    isPremium: false,
    isVideoCollectible: false,
    video: '',
    isCoupon: false,
    mintWithLinkedOnly: false,
    area: {
      latitude: '',
      longitude: '',
      radius: 0,
    },
    maxBalance: 0,
    socialMedia: true,
    socialLinks: null,
    passportType: EstPortalPassportType.REGULAR,
  };

  let collectionAdditionalData: CollectionDataAdditional,
    collectionMetaData: CollectionMetadata,
    collectionData: CollectionData;

  const subgraphConfig = await getSubgraphConfig();

  if (
    subgraphConfig &&
    subgraphConfig.modules &&
    subgraphConfig.modules.collectibleDetails &&
    !fetchLatest
  ) {
    const combinedData = await fetchCollectionDetailsCombined(
      collectibleAddress,
    );
    collectionData = combinedData?.collectionData;
    collectionAdditionalData = combinedData?.collectionDataAdditional;
    collectionMetaData = combinedData?.collectionMetadata;
  }

  try {
    let collectibleData;
    if (
      !collectionData ||
      !collectionAdditionalData ||
      !collectionMetaData ||
      fetchLatest
    ) {
      collectibleData = await collectionManager.getCollectionInfo(
        collectibleAddress,
      ); // refresh item
    }

    const entity = collectionData?.entity ?? collectibleData?._data?.entity;
    if (entity && entity?.toLowerCase() !== entityAddress?.toLowerCase())
      return collectible;

    let totalSupply = await loot8Collection.collectionCollectibleIds();
    let collectibleName =
      collectionMetaData?.name ?? collectibleData?._name ?? '';
    let collectibleSymbol =
      collectionMetaData?.symbol ?? collectibleData?._symbol ?? '';
    collectible.dataURI =
      collectionMetaData?.dataURI ?? collectibleData?._dataURI ?? '';

    if (
      collectibleChainID !== NetworkId.ARBITRUM_NOVA &&
      collectibleChainID !== NetworkId.ARBITRUM_SEPOLIA
    ) {
      collectibleName = await loot8Collection.name();
      collectibleSymbol = await loot8Collection.symbol();
      collectible.dataURI = await loot8Collection.contractURI();
    }
    let ipfsData;

    try {
      if (
        collectible.dataURI &&
        collectible.dataURI !== '' &&
        collectible.dataURI !== 'ipfs://'
      ) {
        let response = await getIPFSData(collectible.dataURI);
        ipfsData = response && (await response.json());
      }
    } catch (e) {
      // LogToConsoleError(e);
    }

    if (ipfsData?.image) {
      collectible.imageProps.image = ipfsData && getIPFSLink(ipfsData?.image);
      collectible.imageProps.imageSize = ipfsData && ipfsData?.imageSize;
      collectible.imageProps.thumbnailImage =
        ipfsData && getIPFSLink(ipfsData?.thumbnailImage);
      collectible.imageProps.thumbnailImageSize =
        ipfsData && ipfsData?.thumbnailImageSize;
      collectible.imageProps.optimizedImage =
        ipfsData && getIPFSLink(ipfsData?.optimizedImage);
      collectible.imageProps.optimizedImageSize =
        ipfsData && ipfsData?.optimizedImageSize;
    }
    if (ipfsData?.video) {
      collectible.video = getIPFSLink(ipfsData.video);
      collectible.isVideoCollectible = true;
      collectible.imageProps.image = getIPFSLink(ipfsData.thumbnail);
    }

    if (ipfsData?.animation_url) {
      collectible.animationUrl = ipfsData.animation_url;
      let response = await getIPFSData(collectible?.animationUrl);
      collectible.animationUrlHtml = response && (await response.text());
    }

    if (
      ipfsData?.discord ||
      ipfsData?.telegram ||
      ipfsData?.facebook ||
      ipfsData?.twitter ||
      ipfsData?.instagram ||
      ipfsData?.tiktok
    ) {
      collectible.socialLinks = {};
      if (ipfsData?.discord && ipfsData.discord !== '') {
        collectible.socialLinks.discord = ipfsData.discord;
      }
      if (ipfsData?.telegram && ipfsData.telegram !== '') {
        collectible.socialLinks.telegram = ipfsData.telegram;
      }
      if (ipfsData?.facebook && ipfsData.facebook !== '') {
        collectible.socialLinks.facebook = ipfsData.facebook;
      }
      if (ipfsData?.twitter && ipfsData.twitter !== '') {
        collectible.socialLinks.twitter = ipfsData.twitter;
      }
      if (ipfsData?.instagram && ipfsData.instagram !== '') {
        collectible.socialLinks.instagram = ipfsData.instagram;
      }
      if (ipfsData?.tiktok && ipfsData.tiktok !== '') {
        collectible.socialLinks.tiktok = ipfsData.tiktok;
      }
    }

    collectible.details = ipfsData?.description ?? '';
    collectible.subTitle = ipfsData?.subtitle ?? '';
    collectible.title = ipfsData?.name ?? '';
    collectible.timestamp = ipfsData?.timestamp ?? '';
    collectible.htmlTemplate = ipfsData?.htmlTemplate ?? '';

    collectible.address = collectibleAddress;
    collectible.chain = collectibleChainID;
    collectible.isActive =
      collectionMetaData?.isActive ?? collectibleData?._isActive;
    collectible.area = convertAreaData(
      collectionMetaData?.areaPoints
        ? JSON.parse(collectionMetaData?.areaPoints)
        : collectibleData._areaPoints,
      collectionMetaData?.areaRadius
        ? Number(collectionMetaData?.areaRadius)
        : collectibleData._areaRadius,
    );
    collectible.isPremium = Boolean(
      collectibleData?._additionCollectionData?.isPremium,
    );
    collectible.category = collectibleData?._additionCollectionData?.category;

    collectible.entityAddress =
      collectionData?.entity ?? collectibleData?._data?.entity;
    let price = Number(collectionData?.price ?? collectibleData?._data?.price);
    collectible.price = Number(price / Math.pow(10, 18)).toFixed(2);
    collectible.start =
      Number(collectionData?.start ?? collectibleData?._data?.start) === 0
        ? null
        : new Date(
            Number(collectionData?.start ?? collectibleData?._data?.start) *
              1000,
          ); //blocktimestamp to timestamp
    collectible.end =
      Number(collectionData?.end ?? collectibleData?._data?.end) === 0
        ? null
        : new Date(
            Number(collectionData?.end ?? collectibleData?._data?.end) * 1000,
          ); //blocktimestamp to timestamp
    // collectible.checkInNeeded = collectibleData?._data?.checkInNeeded;
    collectible.offerType =
      collectionData?.offerType ?? collectibleData?._data?.offerType;
    collectible.linkCollectible = collectionMetaData?.linkedCollections
      ? JSON.parse(collectionMetaData?.linkedCollections)
      : collectibleData?._linkedCollections;
    collectible.collectionType =
      collectionMetaData?.collectionType ?? collectibleData._collectionType;

    collectible.passportType =
      collectionAdditionalData?.mintModel ??
      collectibleData?._additionCollectionData?.mintModel;

    collectible.totalSupply = Number(totalSupply) - 1;
    // collectible.tokensEarned = price > 0 ? Number(await tokenPriceCalculator.getTokensEligible(price)) / (1e18) : 0;
    collectible.maxMint = Number(
      collectionData?.maxMint ?? collectibleData?._data?.maxMint,
    );
    collectible.maxPurchase = Number(
      collectionData?.maxPurchase ?? collectibleData?._data?.maxPurchase,
    );
    collectible.maxBalance = Number(
      collectionAdditionalData?.maxBalance ??
        collectibleData?._additionCollectionData?.maxBalance,
    );
    collectible.mintWithLinked =
      collectionData?.mintWithLinked ?? collectibleData?._data?.mintWithLinked;
    collectible.mintWithLinkedOnly =
      collectionAdditionalData?.mintWithLinkedOnly ??
      collectibleData?._additionCollectionData?.mintWithLinkedOnly;
    collectible.isCoupon =
      Number(
        collectionAdditionalData?.isCoupon ??
          collectibleData?._additionCollectionData?.isCoupon,
      ) === 1;

    // marketPlace config
    if (
      collectible.collectionType === CollectionType.PASSPORT ||
      collectible.collectionType === CollectionType.COLLECTION ||
      collectible.collectionType === CollectionType.PREMIUM_ACCESS
    ) {
      const marketPlaceConfig = await collectionHelper.marketplaceConfig(
        collectibleAddress,
      ); // refreshItem
      collectible.marketPlaceConfig = {
        allowMarketplaceOps: marketPlaceConfig.allowMarketplaceOps,
        privateTradeAllowed: marketPlaceConfig.privateTradeAllowed,
        publicTradeAllowed: marketPlaceConfig.publicTradeAllowed,
      };

      collectible.socialMedia = await getSocialMediaAccess(
        collectibleAddress,
        Number(collectibleChainID),
      ); // refreshItem
    }

    if (collectible.collectionType === CollectionType.PASSPORT) {
      collectible.whitelistedCollections =
        await getAllWhitelistedCollectibleList({
          networkID,
          provider,
          passportAddress: collectibleAddress,
          fetchLatest,
        }); // refreshItem
    }

    collectible.name = collectibleName;
    collectible.symbol = collectibleSymbol;

    if (collectible.isCoupon) {
      const TierCollection = Loot8TieredCouponCollection__factory.connect(
        collectible.address,
        getAnynetStaticProvider(collectibleChainID),
      );
      collectible.couponPoapAddress = await TierCollection.tierCollection();
      collectible.couponMaxTokens = Number(await TierCollection.maxTokens());
    }

    if (collectible.passportType === EstPortalPassportType.SUBSCRIPTON) {
      const subscriptionConfig = await subscriptionManager.subscriptionConfig(
        collectibleAddress,
      );
      const floorPrice = subscriptionConfig.floorPrice;
      collectible.price = ethers.utils.formatUnits(floorPrice, 18);
      collectible.priceRate = Number(subscriptionConfig.priceRate) / 100;
      collectible.subscriptionSet = subscriptionConfig.subscriptionHasStarted;
    }

    await storeData(
      APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress),
      collectible,
    );

    return collectible;
  } catch (ex) {
    LogToLoot8Console('🚀 ~ getCollectibleDetails ~ ex:', ex);
  } finally {
    return collectible;
  }
};

// TODO: 5687
export const loadAllPassportDetails = createAsyncThunk(
  'passport/loadAllPassportDetails',
  async (
    {
      networkID,
      provider,
      entityAddress,
      address,
      wallet,
      isCache = true,
      isSuperAdmin,
    }: ICollectionBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let allPassportDetails: ICollectibleDetail[] = [];

    const passportAddressLst = await getAllCollectibleList({
      networkID,
      provider,
      collectibleType: CollectionType.PASSPORT,
      entity: entityAddress,
    });

    if (passportAddressLst && passportAddressLst.length > 0) {
      await Promise.all(
        passportAddressLst
          .filter(
            collectibleAddress => collectibleAddress.source !== ZERO_ADDRESS,
          )
          .map(async (collectible, _index) => {
            let passportDetails: ICollectibleDetail =
              await getCollectibleDetails(
                {
                  networkID,
                  provider,
                  userAddress: wallet?.address,
                  collectibleData: collectible,
                  index: _index,
                  entityAddress,
                  isSuperAdmin,
                },
                { isCache, fetchLatest: true },
              );
            if (passportDetails && passportDetails?.name !== '') {
              dispatch(updatePassportData(passportDetails));
              allPassportDetails.push(passportDetails);
            }
          }),
      );
    }
    if (allPassportDetails && allPassportDetails.length === 0) {
      dispatch(updatePassportData(null));
    }
  },
);

export const CreateCollection = createAsyncThunk(
  'Collections/CreateCollection',
  async (
    {
      networkID,
      provider,
      address,
      EntityAddress,
      collectibleData,
      dataURI,
      wallet,
      _transferable = true,
      chainID = networkID,
    }: {
      networkID;
      provider;
      address;
      EntityAddress;
      collectibleData: ICollectibleDetail | any;
      dataURI;
      wallet;
      _transferable?: boolean;
      chainID?: NetworkId | '';
    },
    { dispatch },
  ): Promise<any> => {
    let name = collectibleData?.name?.trim() ?? '';
    const manager = addresses[chainID].CollectionManager;

    const data = GetCreateCollectionMessage(
      EntityAddress,
      name,
      collectibleData?.symbol !== '' ? collectibleData?.symbol : name,
      dataURI !== '' ? dataURI : '',
      _transferable,
      manager,
      addresses[chainID].CollectionHelper,
      addresses[networkID].SubscriptionManager,
      addresses[chainID].LayerZeroEndPoint,
    );
    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[chainID].CollectionFactory,
        wallet: wallet,
        data: data,
        networkID: chainID,
        provider: getAnynetStaticProvider(chainID as NetworkId),
      };
      LogToLoot8Console('collectionCreation', msg);

      let res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        const createdCollection = GetCollectionCreatedLogData(
          res.payload?.eventLogs,
        );
        if (createdCollection === ZERO_ADDRESS) {
          LogToLoot8Console('Collection Creation New: transaction failed...');
        }
        return createdCollection;
      }
    }
    return ZERO_ADDRESS;
  },
);

export const AddFreeCollection = createAsyncThunk(
  'Collections/AddCollection',
  async (
    {
      networkID,
      provider,
      address,
      EntityAddress,
      isPremium,
      category,
      collectibleAddress,
      collectibleData,
      wallet,
      chainID = networkID,
    }: AddFreeExPassAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    let CollectibleType = collectibleData?.collectionType;
    let privateMessageCap = collectibleData?.privateMessageCap ?? 1;
    let startDate = collectibleData?.start
      ? Math.floor(new Date(collectibleData?.start).getTime() / 1000)
      : 0;
    let endDate = collectibleData?.end
      ? Math.floor(new Date(collectibleData?.end).getTime() / 1000)
      : 0;
    let area = [
      [collectibleData?.area?.latitude, collectibleData?.area?.longitude],
      Number(collectibleData?.area?.radius),
    ];
    const floorPrice = ethers.utils.parseUnits(0?.toString(), 18).toString();
    // collection data : [Entity, mintWithLinked, price, maxPurchase, start, end, checkInNeeded, maxMint, OfferType, passport, minRewardBalance, minVisits, minFriendVisits, _gap[20]]
    let CollectionData = [
      EntityAddress,
      collectibleData.mintWithLinked ?? false,
      floorPrice,
      collectibleData?.maxPurchase,
      startDate,
      endDate,
      false,
      collectibleData?.maxMint,
      collectibleData?.offerType,
      ZERO_ADDRESS,
      0,
      0,
      0,
      privateMessageCap,
      getZeroArray(19),
    ];
    let additionalCollectionData = [
      collectibleData?.maxBalance,
      collectibleData?.mintWithLinkedOnly ?? false,
      Number(collectibleData?.isCoupon),
      collectibleData.passportType ?? 0,
      false,
      category,
      getZeroArray(17),
    ];
    const _tradability =
      collectibleData?.marketPlaceConfig?.publicTradeAllowed ?? false;
    const data = GetAddCollectionMessage(
      collectibleAddress,
      chainID,
      CollectibleType,
      CollectionData,
      additionalCollectionData,
      area,
      _tradability,
    );

    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].CollectionManager,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      await dispatch(SendMetaTX(msg));
    }
  },
);

export const AddSubscriptionPassport = createAsyncThunk(
  'Collections/SubscriptionPassport',
  async (
    {
      networkID,
      provider,
      address,
      collectibleAddress,
      floorPrice,
      wallet,
      priceRate,
    }: any,
    { dispatch },
  ): Promise<any> => {
    // const feePercent = ethers.utils.parseUnits("0.10", 4); // 10% Fees
    const data = GetSubscriptionPassportDataMessage(
      collectibleAddress,
      floorPrice,
      priceRate ? Number(priceRate) * 100 : 1000,
      true,
      true,
    );
    // _passport, _peopleFeePercent, _platformFeePercent, _peopleFeeReceiver, _floorPrice, _priceRate, _tradingEnabled, _startSubscription
    if (data) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].SubscriptionManager,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      let res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        const createdCollection = GetAddSubscriptionPassportLogData(
          res.payload?.eventLogs,
        );
        if (createdCollection === ZERO_ADDRESS) {
          LogToLoot8Console('Collection Creation: transaction failed...');
        }
        return createdCollection;
      }
    }
    return ZERO_ADDRESS;
  },
);

export const SetMarketPlaceTradability = createAsyncThunk(
  'Collections/MarketPlaceTradability ',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      collectibleAddress,
      marketPlaceConfig,
    }: any,
    { dispatch },
  ): Promise<any> => {
    const data = GetMarketPlaceTradablityMessage(
      collectibleAddress,
      marketPlaceConfig?.privateTradeAllowed,
      marketPlaceConfig?.publicTradeAllowed,
    );

    if (data) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].CollectionHelper,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      await dispatch(SendMetaTX(msg));
    }
  },
);

const initialState: IPassportSliceData = {
  loading: false,
  AllEntityPassportDetails: null,
};

const PassportSlice = createSlice({
  name: 'PassportDetails',
  initialState,
  reducers: {
    updatePassportData(state, action) {
      let allPassportData = state.AllEntityPassportDetails ?? [];
      if (action.payload) {
        let collectionData = allPassportData.find(
          obj =>
            obj.address?.toLowerCase() ===
            action.payload.address?.toLowerCase(),
        );
        if (collectionData) {
          allPassportData = allPassportData.filter(
            obj =>
              obj.address?.toLowerCase() !==
              collectionData.address?.toLowerCase(),
          );
          collectionData = { ...collectionData, ...action.payload };
        } else {
          collectionData = action.payload;
        }
        allPassportData.push(collectionData);
      }
      state.AllEntityPassportDetails = allPassportData.sort(
        (a, b) => Number(b.index) - Number(a.index),
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAllPassportDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAllPassportDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(
        loadAllPassportDetails.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
        },
      );
  },
});

export const EstPortalPassportSliceReducer = PassportSlice.reducer;

const baseInfo = (state: RootState) => state.Passports;

export const { updatePassportData } = PassportSlice.actions;

export const getPassportState = createSelector(
  baseInfo,
  PassportSlice => PassportSlice,
);
