import { LogCustomError } from './AppLogger';
import { getAppConfiguration } from '../appconstants';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
// import { fetchUsersData } from './GraphQLHelper';
//LOOT8-5387
import { fetchUsersData } from './GraphQLHelperSubgraph';

let userQueryPageSize: number | null = 250;

const getPagedUserData = async (skip: number, pageSize: number) => {
  const response = await fetchUsersData(skip, pageSize);
  try {
    if (response?.records?.length > 0) {
      return response;
    } else {
      return null;
    }
  } catch (ex) {
    LogCustomError(
      'Error occurred: getPagedUserData sub query',
      null,
      ex.message,
      ex.stack,
      null,
    );
  }
};

export const getAllUsersDataFromQuery = async (pageSize: number = 0) => {
  try {
    if (!userQueryPageSize || userQueryPageSize === null) {
      let appConfig;
      try {
        appConfig = await getAppConfiguration();
      } catch (err) {
        LogToLoot8Console(
          'needLocationChangeTrigger: Error while reading app config',
        );
      }
      if (appConfig && appConfig.queryHelpers && appConfig.queryHelpers.user) {
        userQueryPageSize = appConfig.queryHelpers.user.pageSize;
      }
    }

    // If pageSize is not provided as function Param then only take from app config
    if (
      userQueryPageSize &&
      userQueryPageSize > 0 &&
      (pageSize === null || pageSize === 0)
    ) {
      pageSize = userQueryPageSize;
    }

    let allUserData = [];
    let skip = 0;
    let morePages = true;

    while (morePages) {
      let userData = await getPagedUserData(skip, pageSize);
      if (userData) {
        let data = userData?.records;
        if (data && data?.length > 0) {
          data.forEach(u => {
            let user = {
              id: Number(u.userId),
              name: u.name,
              wallet: u.wallet,
              avatarURI: u.avatarURI,
              dataURI: u?.dataURI ?? '',
            };
            allUserData.push(user);
          });

          // If the data length is less than the page size, it means it's the last page
          if (data?.length < pageSize) {
            morePages = false;
          }

          // Increment skip for the next iteration
          skip += pageSize;
        } else {
          // Stop if no more data is returned
          morePages = false;
        }
      }
    }

    return allUserData;
  } catch (ex) {
    LogCustomError(
      'Error occurred: getAllUsersData query',
      null,
      ex.message,
      ex.stack,
      null,
    );
  }
};
