import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  addresses,
  APP_STORAGE_ENTITY_LIST,
  getAppConfiguration,
  getSubgraphConfig,
  ZERO_ADDRESS,
} from '../appconstants';
import { LogCustomError } from '../helpers/AppLogger';
import { getData, storeData } from '../helpers/AppStorage';
import { showToastMessage } from '../helpers/Gadgets';
import { getIPFSData, getIPFSLink } from '../helpers/ipfs';
import { IEntity } from '../interfaces/IEntity.interface';
import { RootState } from '../store';
import {
  EntityRegistry__factory,
  Entity__factory,
} from '../typechain/factories';
import { setAll } from './helpers';
import { IWalletBaseAsyncThunk } from './interfaces';
// import { fetchAllEntityDetails } from '../helpers/GraphQLHelper';
// LOOT8-5387
import { fetchAllEntityDetails } from '../helpers/GraphQLHelperSubgraph';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';

const getAllEntityData = async (networkID, provider) => {
  let allEntityAddresses = null;
  let entityDetails = null;
  let entityData = [];
  try {
    const subgraphConfig = await getSubgraphConfig();

    if (
      subgraphConfig &&
      subgraphConfig.modules &&
      subgraphConfig.modules.entity
    ) {
      let hasMoreData = true;

      let skip = 0;
      const take = 250;

      while (hasMoreData) {
        const result = await fetchAllEntityDetails(skip, take, true);

        entityData = [...entityData, ...result];
        hasMoreData = result.length === take;
        skip += take;
      }
      
      if (entityData && entityData.length > 0) {
        allEntityAddresses = entityData.map(a => a.address);
      }
    }
  } catch (err) {
    LogToLoot8Console('Error - getAllEntityData');
    LogToLoot8Console(err);
    LogCustomError('getAllEntityData', err.name, err.message, err.stack);
  }
  //if indexer is disable or any error occured while fetching data from indexer then fetch data from contract
  if (allEntityAddresses === null) {
    const allentity = EntityRegistry__factory.connect(
      addresses[networkID].EntityRegistry,
      provider,
    );
    allEntityAddresses = await allentity.getAllEntities(true); //todo:pass the entity address later
    entityData = [];
  }

  entityDetails = {
    entityAddresses: allEntityAddresses,
    entityData: entityData,
  };
  return entityDetails;
};

export const getEntityDetails = async ({
  networkID,
  provider,
}: {
  networkID;
  provider;
}): Promise<any> => {
  // const signer = provider.getSigner();
  //todo:load all entity details check geocoding filters
  let allentityDetails = await getData(APP_STORAGE_ENTITY_LIST);
  if (allentityDetails) return allentityDetails;

  // const allentity = EntityRegistry__factory.connect(addresses[networkID].EntityRegistry, provider);
  // const getAllEntities = await allentity.getAllEntities(true); //todo:pass the entity address later

  const allEntities = await getAllEntityData(networkID, provider);
  let allEntityAddresses = [];
  if (
    allEntities &&
    allEntities !== null &&
    allEntities.entityAddresses?.length > 0
  ) {
    allEntityAddresses = allEntities.entityAddresses;
  }

  allentityDetails = {};
  if (allEntityAddresses.length > 0) {
    await Promise.all(
      allEntityAddresses
        .filter(entityAddress => entityAddress !== ZERO_ADDRESS)
        .map(async entityAddress => {
          let entityData = null;
          if (
            allEntities &&
            allEntities !== null &&
            allEntities.entityData?.length > 0
          ) {
            entityData = allEntities.entityData.find(
              e => e.address?.toLowerCase() === entityAddress?.toLowerCase(),
            );
          } else {
            const entity = Entity__factory.connect(entityAddress, provider);
            entityData = await entity.getEntityData();
          }

          if (entityData && entityData !== null) {
            const walletAddress = entityData.walletAddress; //todo:pass the entity address later
            const entityDataURI = entityData.dataURI;
            if (
              entityDataURI &&
              entityDataURI !== '' &&
              entityDataURI !== 'ipfs://'
            ) {
              let response = await getIPFSData(entityDataURI);
              if (response) {
                let responseJson = await response.json();
                allentityDetails[entityAddress] = responseJson;
                if (entityData.isActive) {
                  allentityDetails[entityAddress].logo = responseJson.meta?.logo
                    ? getIPFSLink(responseJson.meta?.logo)
                    : '';
                  allentityDetails[entityAddress].backgroundImage = responseJson
                    .meta?.backgroundimage
                    ? getIPFSLink(responseJson.meta?.backgroundimage)
                    : '';
                }
                if (
                  entityData.entityOnboarded !== undefined &&
                  entityData.entityOnboarded !== null
                ) {
                  allentityDetails[entityAddress].isOnboardedEntity =
                    entityData.entityOnboarded;
                } else {
                  const allentity = EntityRegistry__factory.connect(
                    addresses[networkID].EntityRegistry,
                    provider,
                  );
                  const isOnboardedEntity = await allentity.isOnboardedEntity(
                    entityAddress,
                  );
                  allentityDetails[entityAddress].isOnboardedEntity =
                    isOnboardedEntity;
                }
              }
            }
          }
        }),
    );
    await storeData(APP_STORAGE_ENTITY_LIST, allentityDetails);
  }
  return allentityDetails;
};
export const loadEntityDetails = createAsyncThunk(
  'app/loadEntity',
  async (
    { networkID, provider, address, wallet }: IWalletBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    let entitydata = await getEntityDetails({ networkID, provider });
    return { EntityData: entitydata };
  },
);

//   TODO:
// 1. get entity name and coordinates
// 2. get offers and passports linked to entities

export interface IEntitySliceData {
  readonly EntityData?: IEntity;
  readonly loading: boolean;
}

const initialState: IEntitySliceData = {
  EntityData: null,
  loading: false,
};

const EntitySlice = createSlice({
  name: 'Entity',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadEntityDetails.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(loadEntityDetails.fulfilled, (state, action) => {
        // setAll(state, action.payload);
        state.EntityData = action.payload.EntityData;
        state.loading = false;
      })
      .addCase(
        loadEntityDetails.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadEntityDetails',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const EntitySliceReducer = EntitySlice.reducer;

const baseInfo = (state: RootState) => state.Entity;

export const { fetchAppSuccess } = EntitySlice.actions;

export const getEntityState = createSelector(baseInfo, Entity => Entity);
