import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  View,
  Text,
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import { SheetManager } from 'react-native-actions-sheet';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';

import {
  ZERO_ADDRESS,
  ToastCustomMessageType,
  getAnynetStaticProvider,
} from '../../appconstants';
import ModalComponent from '../Modal';
import useStateRef from 'react-usestateref';
import { useAppSelector } from '../../hooks';
import useIsResponsive from '../../hooks/useIsResponsive';
import { showToastMessage } from '../../helpers/Gadgets';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import {
  searchPassport,
  setPassportSearchText,
  updatePassportMarketPlaceConfig,
} from '../../slices/PassportSlice';
import { loadCatalogPassport } from '../../slices/CatalogSlice';
import {
  PassportSubscriptionActions,
  PassportType,
} from '../../enums/passportCategory.enum';
import { defaultTheme } from '../../themes/loot8';
import { ScreenName } from '../../enums/screen.enum';
import { AppDispatch } from '../../store';
import { CatalogTypes } from '../../enums/catalog.enum';
import { wait } from '../../helpers/ipfs';
import {
  approveForListing,
  delistCollectionForMarketPlace,
  listCollectionForMarketPlace,
} from '../../slices/MarketPlaceSlice';
import { ListingType } from '../../enums/collection.enum';
import { getCollectibleDetails } from '../../slices/OfferSlice';
import { LogCustomError } from '../../helpers/AppLogger';
import TrendingExpassCard from '../TrendingExpassCard';

let EX_PASS_DATA = null;
const isWeb = Platform.OS === 'web';
const enum SortingOptions {
  NONE = 0,
  ALPHABETICAL_ASC = 1,
  ALPHABETICAL_DESC = 2,
  COST_ASC = 3,
  COST_DESC = 4,
}

interface TrendingExpassesProps {
  closeSortingOptionsMenu: () => void;
  selectedSortingOption?: any;
  selectedCategory?: any;
}
const TrendingExpasses: React.FC<TrendingExpassesProps> = ({
  closeSortingOptionsMenu,
  selectedSortingOption = SortingOptions.ALPHABETICAL_ASC,
  selectedCategory = undefined,
}) => {
  // local states
  const [selectedEXPass, setSelectedEXPass] = useState(null);
  const [showListingModal, setShowListingModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [privateMarket, setPrivateMarket] = useState(false);
  const [listingInProgress, setListingInProgress] = useState(false);
  const [salePrice, setSalePrice] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isPassportListed, setIsPassportListed] = useState(false);
  const [failedOp, setFailedOp] = useState(false);

  const [searchText, setSearchPassportText, searchPassportTextRef] =
    useStateRef('');

  // Hooks
  const navigation = useNavigation();
  const isResponsive = useIsResponsive();
  const { address, networkId, staticProvider, wallet, userInfo } =
    useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();
  const windowDimensions = useWindowDimensions();

  // app selectors
  const availablePassportDetails = useAppSelector(
    state => state?.Passports?.AllPassportDetailsSearchList,
    (oldValue, newValue) => isEqual(oldValue, newValue), // Using lodash's deep equality check
  );

  const entityData = useAppSelector(state => state.Entity.EntityData);
  const initialPassportLoaded = useAppSelector(
    state => state.Passports.initialPassportLoaded,
  );
  const passportLoading = useAppSelector(
    state => state.Passports.loadingAllPassport,
  );

  const storedSearchText = useAppSelector(
    state => state.Passports.passportSearchText,
  );

  const onPassportSearch = searchText => {
    setSearchPassportText(searchText);
    dispatch(debounce(searchPassport({ searchText }), 500));
  };

  useEffect(() => {
    onPassportSearch(storedSearchText);
    return () => {
      dispatch(setPassportSearchText(searchPassportTextRef.current));
    };
  }, []);

  // useEffect(() => {
  //   if (Platform.OS === 'web') {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     const passportAddress = searchParams.get('passportAddress');
  //     const chainId = searchParams.get('chainId');

  //     if (
  //       new URLSearchParams(window.location.search).get('page') ===
  //         'expasses' &&
  //       passportAddress &&
  //       chainId
  //     ) {
  //       navigateToPassportDetails(passportAddress, chainId);
  //     }
  //   }
  // }, []);

  const availableActivePassports = useMemo(() => {
    if (!availablePassportDetails) {
      return []; // Ensure there's always a fallback if data is not available.
    }

    const searchQuery = searchPassportTextRef?.current?.toLowerCase() || '';

    const freeCreators = availablePassportDetails?.filter(
      passport => passport.isPremium === false,
    );

    // Filtering for active passports with specified conditions.
    const activePassportDetails = freeCreators?.filter(item => {
      if (!item) {
        return false; // Prevent operations on undefined items.
      }

      const { isActive, passportType, collectibleIds, name } = item;

      const meetsPassportTypeCondition =
        passportType === PassportType.SUBSCRIPTION ||
        (collectibleIds && collectibleIds?.length > 0);

      const meetsSearchCondition =
        searchQuery.length === 1
          ? name?.toLowerCase()?.startsWith(searchQuery)
          : name?.toLowerCase()?.includes(searchQuery);

      return isActive && meetsPassportTypeCondition && meetsSearchCondition;
    });

    // Sorting logic with clear cases for different sort options.
    const sortedActivePassports = activePassportDetails?.sort((a, b) => {
      const textA = a?.name?.toUpperCase();
      const textB = b?.name?.toUpperCase();

      switch (selectedSortingOption) {
        case SortingOptions.ALPHABETICAL_ASC:
          return textA?.localeCompare(textB);
        case SortingOptions.ALPHABETICAL_DESC:
          return textB?.localeCompare(textA);
        case SortingOptions.COST_ASC:
          return a?.buyPrice - b?.buyPrice;
        case SortingOptions.COST_DESC:
          return b?.buyPrice - a?.buyPrice;
        default: // Default sort based on timestamps
          return b?.timestamp - a?.timestamp;
      }
    });

    return sortedActivePassports;
  }, [availablePassportDetails, searchPassportTextRef, selectedSortingOption]);

  const filteredActivePassports = useMemo(() => {
    if (!selectedCategory) {
      return availableActivePassports;
    }

    const filtered = availableActivePassports?.filter(
      passport => passport.category === selectedCategory.name,
    );

    return filtered;
  }, [selectedCategory, availableActivePassports]);

  // const availableActivePassports = useMemo(() => {
  //   const activePassportDetails = availablePassportDetails?.filter(item => {
  //     return (
  //       item.isActive &&
  //       (item.passportType === PassportType.SUBSCRIPTION ||
  //         item.collectibleIds?.length > 0) &&
  //       (searchPassportTextRef?.current?.length === 1
  //         ? item.name
  //             .toLowerCase()
  //             .startsWith(searchPassportTextRef?.current?.toLowerCase()) ===
  //           true
  //         : item.name
  //             .toLowerCase()
  //             .indexOf(searchPassportTextRef?.current?.toLowerCase()) > -1)
  //     );
  //   });

  //   const localAvailableActivePassports = activePassportDetails
  //     ?.filter(item => {
  //       return item.passportType === PassportType.SUBSCRIPTION;
  //     })
  //     .sort((a, b) => {
  //       if (selectedSortingOption === SortingOptions.ALPHABETICAL_ASC) {
  //         let textA = a.name.toUpperCase();
  //         let textB = b.name.toUpperCase();
  //         return textA < textB ? -1 : textA > textB ? 1 : 0;
  //       } else if (selectedSortingOption === SortingOptions.ALPHABETICAL_DESC) {
  //         let textA = a.name.toUpperCase();
  //         let textB = b.name.toUpperCase();
  //         return textA < textB ? 1 : textA > textB ? -1 : 0;
  //       } else if (selectedSortingOption === SortingOptions.COST_ASC) {
  //         return a.buyPrice - b.buyPrice;
  //       } else if (selectedSortingOption === SortingOptions.COST_DESC) {
  //         return b.buyPrice - a.buyPrice;
  //       } else {
  //         let textA = a.timestamp;
  //         let textB = b.timestamp;
  //         return textA < textB ? 1 : textA > textB ? -1 : 0;
  //       }
  //     });
  //   return localAvailableActivePassports;
  // }, [availablePassportDetails, selectedSortingOption]);

  // navigate to expass details
  const navigateToPassportDetails = (passportAddress, chainId) => {
    navigation.navigate(ScreenName.PASSPORT_DETAIL, {
      passportAddress,
      chainId,
    });
  };

  const onPassportSelection = useCallback(item => {
    setShowOptionsModal(false);
    navigateToPassportDetails(item.address, item.chainId);
    // setting url route in web to save the history state in url
    // if (Platform.OS === 'web') {
    //   const urlSearchParams = new URLSearchParams();
    //   urlSearchParams.set('page', 'expasses');
    //   urlSearchParams.set('passportAddress', item.address);
    //   urlSearchParams.set('chainId', item.chainId);
    //   const newURL = window.location.origin + '?' + urlSearchParams.toString();
    //   window.history.pushState(null, 'expasses', newURL);
    // }
    setSearchPassportText('');
  }, []);

  const processHidePassportsSheet = () => {
    setTimeout(
      () => {
        setSelectedEXPass(null);
      },
      Platform.OS === 'ios' ? 500 : 10,
    );
  };

  const hideSubscribePassportsModal = () => {
    if (Platform.OS === 'web') {
      window.history.replaceState(null, '', '/');
    }
    setTimeout(() => {
      setSelectedEXPass(null);
    }, 1000);
  };

  const onSubscribePassportClick = async item => {
    closeSortingOptionsMenu();
    setSelectedEXPass(item);
    // if (isWeb) {
    //   const urlSearchParams = new URLSearchParams();
    //   urlSearchParams.set('page', 'expasses');
    //   urlSearchParams.set('passportAddress', item.address);
    //   urlSearchParams.set('chainId', item.chainId);
    //   const newURL = window.location.origin + '?' + urlSearchParams.toString();

    //   window.history.pushState(null, 'expasses', newURL);
    // }
    EX_PASS_DATA = item;
    await SheetManager.show('SubscribePassportSheet', {
      payload: {
        hideSubscribePassportsModal: hideSubscribePassportsModal,
        selectedEXPass: item,
        setSearchPassportText: setSearchPassportText,
        navigation,
        collectionType: CatalogTypes.PASSPORT,
      },
    });
  };

  const handleResize = async () => {
    if (SheetManager.get('SubscribePassportSheet')?.current?.isOpen()) {
      await SheetManager.hide('SubscribePassportSheet');
      setTimeout(async () => {
        onSubscribePassportClick(EX_PASS_DATA);
      }, 500);
    }
  };

  useEffect(() => {
    if (isWeb) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      // Clear data
      // copilotEvents.off('stepChange', listener)
      if (isWeb) {
        window.removeEventListener('resize', handleResize);
        EX_PASS_DATA = null;
      }
    };
  }, []);

  const onListForSalePressed = async (itemFromParams?: any) => {
    setShowListingModal(true);
    if (itemFromParams) {
      setSelectedEXPass(itemFromParams);
      setIsPassportListed(false);
      await SheetManager.show('ListingModalSheet', {
        payload: {
          privateMarket: privateMarket,
          showSalePriceInput: showListingModal,
          onListForSaleClosed: onListForSaleClosed,
          salePrice: salePrice,
          setSalePrice: setSalePrice,
          collectibleToSend: itemFromParams,
          listed: false,
          listForMarketPlace: listForMarketPlace,
        },
      });
    } else if (selectedEXPass) {
      await SheetManager.show('ListingModalSheet', {
        payload: {
          privateMarket: privateMarket,
          showSalePriceInput: showListingModal,
          onListForSaleClosed: onListForSaleClosed,
          salePrice: salePrice,
          setSalePrice: setSalePrice,
          collectibleToSend: selectedEXPass,
          listed: isPassportListed,
          listForMarketPlace: listForMarketPlace,
        },
      });
    }
  };

  const onListForSaleClosed = () => {
    setShowListingModal(false);
    setSalePrice('');
    setPrivateMarket(false);
    processHidePassportsSheet();
    setFailedOp(false);
  };

  const listForMarketPlace = async (price, passportObj) => {
    setShowListingModal(false);
    //passportObj contains same data as in the selectedEXPass state
    if (passportObj) {
      let chainId = passportObj.chainId;
      let provider = getAnynetStaticProvider(chainId);

      try {
        if (Platform.OS === 'ios') {
          await wait(500);
        }
        setListingInProgress(true);
        let response = null;
        if (isPassportListed && passportObj?.collectibleIds.length > 0) {
          const selectedPassportListingIndex = (
            passportObj &&
            passportObj?.marketPlaceConfig?.listingIndex?.find(
              obj => obj.tokenId === Number(passportObj?.collectibleIds[0]),
            )
          ).listingId;

          const passportAddress = ZERO_ADDRESS;
          const tokenID =
            passportObj?.collectibleIds.length > 0
              ? Number(passportObj.collectibleIds[0])
              : 0;
          if (tokenID) {
            response = await dispatch(
              delistCollectionForMarketPlace({
                networkID: chainId,
                provider,
                wallet,
                address,
                collectibleAddress: passportObj.address,
                tokenId: tokenID,
                passport: passportAddress,
                chainId: passportObj.chainId,
                price: price,
                listingType: privateMarket
                  ? ListingType.PRIVATE
                  : ListingType.PUBLIC,
                listingID: selectedPassportListingIndex,
              }),
            );
          } else {
            LogToLoot8Console('unlistForMarketPlace: invalid passport tokenID');
          }
        } else {
          const tokenID =
            passportObj?.collectibleIds.length > 0
              ? Number(passportObj.collectibleIds[0])
              : 0;
          if (tokenID !== 0) {
            await dispatch(
              approveForListing({
                networkID: networkId,
                provider: provider,
                wallet,
                address,
                collectibleAddress: passportObj.address,
                tokenId: tokenID,
              }),
            );

            // To be updated with passport address for private market place
            const passportAddress = ZERO_ADDRESS;
            response = await dispatch(
              listCollectionForMarketPlace({
                networkID: networkId,
                provider: getAnynetStaticProvider(chainId),
                wallet,
                address,
                collectibleAddress: passportObj.address,
                tokenId: tokenID,
                passport: passportAddress,
                chainId: passportObj.chainId,
                price: price,
                listingType: privateMarket
                  ? ListingType.PRIVATE
                  : ListingType.PUBLIC,
              }),
            );
          } else {
            LogToLoot8Console('listForMarketPlace: invalid passport tokenID');
          }
        }
        if (response && response.payload) {
          const passport = await getCollectibleDetails(
            {
              networkID: passportObj.chainId,
              provider: getAnynetStaticProvider(passportObj.chainId),
              collectibleAddress: passportObj.address,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: true,
              isBalanceRefresh: false,
              isMarketPlaceRefresh: true,
            },
          );
          if (passport && passport?.marketPlaceConfig) {
            await dispatch(
              updatePassportMarketPlaceConfig({
                address: passport.address,
                marketPlaceConfig: passport.marketPlaceConfig,
              }),
            );
          }
          setSearchPassportText('');
          await dispatch(
            loadCatalogPassport({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
            }),
          );

          setListingInProgress(false);
        } else {
          setFailedOp(true);
          if (isPassportListed) {
            showToastMessage(
              ToastCustomMessageType.INFO,
              'Failed to unlist Collectible!',
            );
          } else {
            // showToastMessage(ToastCustomMessageType.INFO, 'Failed to list Collectible!');
          }
        }

        setTimeout(() => {
          setShowConfirmation(true);
        }, 500);
      } catch (e) {
        LogCustomError(
          'Error: Listing in Market Place',
          e.name,
          e.message,
          e.stack,
        );
      }
      setListingInProgress(false);
      //wait(500);
    }
  };

  const onSellPassportClick = async () => {
    setShowOptionsModal(false);
    setTimeout(
      async () => {
        await onListForSalePressed();
      },
      Platform.OS === 'ios' ? 500 : 10,
    );
  };

  const onUnsubscribeClick = async () => {
    // if(isPassportListed){
    //   setShowOptionsModal(false);
    //   setTimeout(() => {
    //    setShowWarningModal(true);
    //   }, 500);
    //   return;
    // }
    setShowOptionsModal(false);
    navigation.navigate(ScreenName.PASSPORT_BUY_SELL_CONFIRMATION, {
      selectedEXPass: selectedEXPass,
      passportAction: PassportSubscriptionActions.UNSUBSCRIBE,
      setSearchPassportText: setSearchPassportText,
    });
    processHidePassportsSheet();
  };

  const enableOption = useCallback(
    () =>
      selectedEXPass?.marketPlaceConfig &&
      selectedEXPass.marketPlaceConfig.allowMarketPlaceOps &&
      selectedEXPass?.marketPlaceConfig?.publicTradeability,
    [selectedEXPass],
  );

  return (
    <>
      {filteredActivePassports && filteredActivePassports?.length > 0 ? (
        <FlatList
          scrollEnabled={false}
          style={{ flex: 1, marginTop: 5 }}
          contentContainerStyle={{ flexGrow: 1 }}
          data={filteredActivePassports}
          renderItem={({ item }) => (
            <TrendingExpassCard navigation={navigation} item={item} />
          )}
          keyExtractor={(item: any) => item?.address}
          ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
          numColumns={isResponsive ? 2 : 1}
          key={isResponsive}
          columnWrapperStyle={
            isResponsive && { justifyContent: 'space-between' }
          }
        />
      ) : (
        <View>
          <Text
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              paddingVertical: 10,
              fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
              fontSize: defaultTheme.FONT_SIZE_XXSMALL,
              color: defaultTheme.PRIMARY_TEXT_COLOR,
            }}>
            {searchText === ''
              ? !initialPassportLoaded
                ? 'Collecting Your Digital Assets'
                : passportLoading
                ? 'Loading...'
                : 'No Profiles Found'
              : 'No search result found.'}
          </Text>
        </View>
      )}
      {showOptionsModal && selectedEXPass ? (
        <ModalComponent
          showModal={showOptionsModal}
          onDismiss={() => {
            setShowOptionsModal(false);
          }}
          enableHeader={false}
          modalStyle={{
            justifyContent: 'flex-end',
            marginBottom:
              Platform.OS == 'web'
                ? 60
                : windowDimensions.height - windowDimensions.height * 0.93,
          }}
          needCloseButton={true}
          modalBodyStyle={{
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            marginLeft: 10,
          }}>
          <View
            style={{
              paddingTop: 20,
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: 20,
            }}>
            <TouchableOpacity
              style={styles.passportActionContainer}
              onPress={() => onSellPassportClick()}
              disabled={!enableOption()}>
              <FontAwesome
                name="list"
                color={enableOption() ? '#FFFFFF' : 'rgba(255, 255, 255, 0.55)'}
                style={{ marginHorizontal: 10 }}
                size={14}
              />
              <Text
                style={[
                  styles.passportActionsText,
                  {
                    color: enableOption()
                      ? isPassportListed
                        ? defaultTheme.PRIMARY_TEXT_COLOR
                        : defaultTheme.FRIEND_ADD_BUTTON_BACKGROUND
                      : 'rgba(255, 255, 255, 0.55)',
                  },
                ]}>
                {selectedEXPass?.marketPlaceConfig && isPassportListed
                  ? 'Unlist from Marketplace'
                  : 'List on Marketplace'}
              </Text>
            </TouchableOpacity>
            {selectedEXPass.passportType === PassportType.SUBSCRIBED && (
              <TouchableOpacity
                disabled={isPassportListed}
                style={[styles.passportActionContainer, { paddingVertical: 0 }]}
                onPress={() => {
                  onUnsubscribeClick();
                }}>
                <MaterialCommunityIcons
                  name="minus-circle-outline"
                  size={16}
                  color={'#FFFFFF'}
                  style={{ marginHorizontal: 10 }}
                />
                <Text
                  style={[
                    styles.passportActionsText,
                    {
                      color: isPassportListed
                        ? 'rgba(255, 255, 255, 0.55)'
                        : '#FFFFFF',
                    },
                  ]}>
                  {'Unsubscribe ExPass'}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
    </>
  );
};

export default TrendingExpasses;

const styles = StyleSheet.create({
  passportActionContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center',
  },
  passportActionsText: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
});
