import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createSelector } from 'reselect';
import {
  addresses,
  APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS,
  APP_STORAGE_GET_COLLECTIBLEDETAILS,
  APP_STORAGE_GET_PASSPORTBALANCE,
  APP_STORAGE_PASSPORT_LIST,
  getAnynetEtherScanProvider,
  getAnynetStaticProvider,
  getAppConfiguration,
  getNetwork,
  getStaticProvider,
  isNativeChain,
  MINTED_COLLECTION,
  NetworkId,
  NETWORKS,
  slowProcessCollectionOnWeb,
  ZERO_ADDRESS,
} from '../appconstants';
import { getData, removeData, storeData } from '../helpers/AppStorage';
import { wait } from '../helpers/ipfs';
import { RootState } from '../store';
import { SendMetaTX } from './AppSlice';
import {
  getAllMintedCollectionForPatron,
  loadCatalogCollectiable,
  loadCatalogPassport,
} from './CatalogSlice';
import {
  getUserSubscribedPassports,
  isLocationAvailable,
  setAll,
} from './helpers';
import {
  IAddressBaseAsyncThunk,
  IBaseAsyncThunk,
  IMessageMetaData,
  IPassportAsyncThunk,
  IPassportFactoryBaseAsyncThunk,
  IPassportOfferFactoryBaseAsyncThunk,
  IPassportSubscribeAsyncThunk,
  IPassportUnsubscribeAsyncThunk,
} from './interfaces';
import { NotificationType, pushNotification } from './NotificationSlice';

import {
  getCollectibleDetails,
  getCollectibleIdsForPatron,
  getThirdPartyCollectiableListByPassport,
  loadPassportOfferList,
  loadThirpartyCollectibleList,
  updatePremiumCollectibles,
  sortCollectionLogs,
  pushCollectiableEntityOffer,
  throttledGetCollectibleDetails,
} from './OfferSlice';
import { showToastMessage } from '../helpers/Gadgets';
import { LogCustomError, LogInformation } from '../helpers/AppLogger';
import {
  CollectionHelper__factory,
  CollectionManager__factory,
  Loot8Collection__factory,
  Loot8Marketplace__factory,
  Loot8Token__factory,
  SubscriptionManager__factory,
} from '../typechain';
import {
  ApproveLoot8TokenForSubscription,
  ApprovePassportForUnsubscribe,
  GetBlockNumberFromLogsData,
  GetPassportMintMessage,
  GetSubscribePassportLogsData,
  SubscribePassportMessage,
  UnsubscribePassportMessage,
} from '../helpers/MsgHelper';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { CollectionType } from '../enums/collection.enum';
import {
  getAllDigitalCollectibles,
  loadAllDigitalCollectible,
  mintLinkedCollectibles,
  // loadAvailableDigitalCollectibleDetails,
  pushActiveDigitalCollectibleDetails,
  pushDigitalCollectibleDetails,
} from './DigitalCollectibleSlice';
import { getSyncedTime, isActiveTimeStamp } from '../helpers/DateHelper';
import { BigNumber, ethers } from 'ethers';
import { AppEnvironment } from '../enums/env.enum';
import Constants from 'expo-constants';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { CollectionManagerData } from '../data/CollectionManager';
import { PassportType } from '../enums/passportCategory.enum';
import { CatalogTypes } from '../enums/catalog.enum';
import { DeviceEventEmitter, NativeEventEmitter, Platform } from 'react-native';
import * as Device from 'expo-device';
import {
  fetchCollectionsWithChainId,
  fetchSubscriptionPrices,
} from '../helpers/GraphQLHelperSubgraph';
import { CustomDeviceEvents } from '../enums/screen.enum';
import { pushCreatorsDetails } from './CreatorsSlice';
import { parseOnlyActiveParam } from '../helpers/APIHelper';

// initilize passport minting variable.
let passportMintingQueue = new Map();
let event: any = DeviceEventEmitter;
if (Platform.OS === 'android') {
  event = new NativeEventEmitter();
}

export interface IPassportSliceData {
  readonly AllPassportDetailsList: ICollectibleDetail[]; //list of all passport details without any filter
  readonly AllPatronPassportDetailsList: ICollectibleDetail[]; //list of all passport which user holds
  readonly PassportRewardBalance: number;
  readonly loading: boolean;
  readonly loadingAllPassport: boolean;
  readonly loadingMyPassport: boolean;
  readonly searching: boolean;
  readonly isMintloading: boolean;
  readonly selectedPassport: ICollectibleDetail;
  readonly AllPassportDetailsSearchList: ICollectibleDetail[]; //list of all passport details without any filter for searching purpose
  readonly passportSearchText: string;
  readonly initialPassportLoaded: boolean;
  readonly isExPassPriceUpdateProgress: boolean;
}

export const getRewardBalanceForUser = async ({
  networkID,
  provider,
  passport,
  address,
}: {
  networkID;
  provider;
  passport;
  address;
}): Promise<any> => {
  const colletionManager = await CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  let collectibleDetails = await colletionManager.collectibleDetails(
    passport.address,
    passport.collectibleIds[0],
  );
  const userRewardBalance = Number(await collectibleDetails.rewardBalance);
  LogToLoot8Console('userRewardBalance', userRewardBalance);
  return Number((userRewardBalance / 1e18).toFixed(2));
};

export const getAllPassport = async (
  { networkID, provider }: { networkID; provider },
  isCache = true,
): Promise<
  {
    source: string;
    chainId: number;
    whitelisted?: {
      isWhitelisted?: boolean;
      passportId?: string;
    };
    prefetchedData?: {
      collectionMetadata?: CollectionMetadata;
      collectionData?: CollectionData;
      collectionDataAdditional?: CollectionDataAdditional;
    };
  }[]
> => {
  let availablePassport = isCache
    ? await getData(APP_STORAGE_PASSPORT_LIST)
    : null;
  if (availablePassport) return availablePassport;
  const collectionManager = CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  // availablePassport = (
  //   await collectionManager.getAllCollectionsWithChainId(
  //     CollectionType.PASSPORT,
  //     true,
  //   )
  // )
  //   .map(p => {
  //     if (p)
  //       return {
  //         source: p.source,
  //         chainId: BigNumber.from(p.chainId).toNumber(),
  //       };
  //   })
  //   .filter(p => p);

  availablePassport = await collectionManager.getListForCollectionType(
    CollectionType.PASSPORT,
  );
  const onlyActive = parseOnlyActiveParam(true);
  let parsedAvailablePassports = [];

  const ChunkSize = 100;
  const Chunks = [];
  let i = 0;

  // Break the array into chunks of 100
  for (i; i < availablePassport.length; i += ChunkSize) {
    Chunks.push(availablePassport.slice(i, i + ChunkSize));
  }

  // Process each chunk sequentially
  for (const chunk of Chunks) {
    try {
      //* Fetch from Subgraph
      const result = await fetchCollectionsWithChainId(chunk, onlyActive);
      const parsed = result.map(res => {
        return {
          source: res.source,
          chainId: Number(res.chainId),
          whitelisted: {
            isWhitelisted: res.isWhitelisted,
            passportId: res.passportId,
          },
          prefetchedData: {
            collectionData: res?.collectionData ?? undefined,
            collectionDataAdditional:
              res?.collectionDataAdditional ?? undefined,
            collectionMetadata: res?.collectionMetadata ?? undefined,
          },
        };
      });
      //* Only push source and chainId into the array
      parsedAvailablePassports.push(...parsed);
    } catch (error) {
      LogCustomError(
        'Subgraph call failed, fetching current chunk data directly from smart contract:',
        error?.name,
        error?.message,
        ' ',
      );

      //* Fallback: fetch data from smart contracts
      const contractResults = [];

      for (const collection of chunk) {
        try {
          //* Determine if you need the isActive Status or not
          //* If onlyActive is false (means all results are independent of isActive Status, then don't fetch it)
          //* If onlyActive is true (means only isActive = true should be processed, then fetch and ignore any collection where isActive = false)
          if (onlyActive.length === 1) {
            const isActive = await collectionManager.collectionIsActive(
              collection,
            );
            if (!isActive) continue;
          }
          const chainId = await collectionManager.collectionChainId(collection);
          //* Only push source and chainId into the array
          contractResults.push({
            source: collection,
            chainId: BigNumber.from(chainId).toNumber(),
          });
        } catch (contractError) {
          console.error('Smart contract call failed:', contractError);
        }
      }

      parsedAvailablePassports.push(...contractResults);
    }
  }

  parsedAvailablePassports = parsedAvailablePassports.filter(item => {
    //* Chain should be supported by the platform
    if (Boolean(NETWORKS[item.chainId].chainId)) {
      return true;
    }
    return false;
  });

  await storeData(APP_STORAGE_PASSPORT_LIST, parsedAvailablePassports);
  LogInformation(
    'PassportSlice/getAllPassport/availablePassport/' +
      parsedAvailablePassports?.length,
    null,
    networkID,
    null,
  );
  return parsedAvailablePassports;
};

export const getMarketPlaceDetails = async (
  collectibleAddress,
  collectibleCount,
  chainID,
) => {
  const arbChainId = getNetwork();
  const arbProvider = getStaticProvider();
  const collectionHelper = CollectionHelper__factory.connect(
    addresses[arbChainId].CollectionHelper,
    arbProvider,
  );
  const loot8MarketPlace = Loot8Marketplace__factory.connect(
    addresses[chainID].MarketPlace,
    getAnynetStaticProvider(chainID),
  );

  let marketPlaceConfig: any = {};
  const marketPlace = await collectionHelper.marketplaceConfig(
    collectibleAddress,
  );
  marketPlaceConfig.privateTradeability = marketPlace?.privateTradeAllowed;
  marketPlaceConfig.publicTradeability = marketPlace?.publicTradeAllowed;
  marketPlaceConfig.allowMarketPlaceOps = marketPlace?.allowMarketplaceOps;

  if (
    marketPlace?.allowMarketplaceOps &&
    collectibleCount > 0 &&
    (marketPlace?.privateTradeAllowed || marketPlace?.publicTradeAllowed)
  ) {
    const allListings = await loot8MarketPlace.getAllListingsForCollection(
      collectibleAddress,
    );
    marketPlaceConfig.listingExists = allListings.length > 0;
    marketPlaceConfig.listingIndex = allListings.map(({ id, tokenId }) => ({
      listingId: Number(id),
      tokenId: Number(tokenId),
    }));
  }

  return marketPlaceConfig;
};

export const refreshDataCache = createAsyncThunk(
  'appuser/refreshDataCache',
  async (
    {
      networkID,
      ethProvider,
      provider,
      address,
      userInfo,
      wallet,
      userStatus,
      userName,
      userAvatarURI,
      userLocation,
    }: any,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;

    const appConfig = await getAppConfiguration();
    if (
      !appConfig.backgroundCacheRefreshWithPassportLoad &&
      (!state.Passports.initialPassportLoaded ||
        state.Passports.loading ||
        state.Passports.loadingAllPassport)
    )
      return;

    const entityData = state.Entity.EntityData;
    const arbChainId = getNetwork();
    const arbProvider = getStaticProvider();
    const collectionManager = CollectionManager__factory.connect(
      addresses[arbChainId].CollectionManager,
      arbProvider,
    );

    if (state.AppUser?.UserData?.isExist) {
      LogToLoot8Console('in refreshCacheForEntityData');
      try {
        let passportList = await getAllPassport({
          networkID,
          provider: ethProvider,
        });
        let newPassportList = await getAllPassport(
          { networkID, provider: ethProvider },
          false,
        );

        if (newPassportList) {
          const newPassports = newPassportList
            .map(p => p.source)
            .filter(p => !passportList.map(q => q.source).includes(p));
          if (newPassports && newPassports.length > 0) {
            await dispatch(
              loadMyPassportDetails({
                networkID,
                provider,
                address,
                wallet,
                entities: [],
                userInfo,
                userLocation,
                isCache: false,
              }),
            );
            await dispatch(
              loadAllPassporDetails({
                networkID,
                provider,
                address,
                wallet,
                entities: [],
                userInfo,
                userLocation,
                isCache: false,
              }),
            );
            await dispatch(
              loadAvailablePassporDetails({
                networkID,
                provider,
                address,
                wallet,
                entities: [],
                userInfo,
                userLocation,
              }),
            );
          }
        }
        await wait(1000);
        // compare old and new collectible list, load latest collectible if any.
        let digitalCollectiblesList = await getAllDigitalCollectibles({
          networkID,
          provider: ethProvider,
        });
        let newDigitalCollectiblesList = await getAllDigitalCollectibles(
          { networkID, provider: ethProvider },
          false,
        );
        if (newDigitalCollectiblesList) {
          const newDigitalCollectibles = newDigitalCollectiblesList
            .map(p => p.source)
            .filter(
              p => !digitalCollectiblesList.map(q => q.source).includes(p),
            );
          if (newDigitalCollectibles && newDigitalCollectibles.length > 0) {
            await dispatch(
              loadAllDigitalCollectible({
                networkID,
                provider,
                address,
                wallet,
                entities: [],
                userInfo,
                userLocation,
              }),
            );
          }
        }

        await wait(1000);
        // udpate collectible in state
        if (digitalCollectiblesList && digitalCollectiblesList.length > 0) {
          const allPassportCollectibles = state?.Offers?.PassportCollectiables;
          for (let j = 0; j < digitalCollectiblesList.length; j++) {
            try {
              const digiCollectible = digitalCollectiblesList[j];
              await wait(1000);
              let ethProviderToPass = ethProvider;
              if (!isNativeChain(digiCollectible.chainId)) {
                ethProviderToPass = getAnynetEtherScanProvider(
                  digiCollectible.chainId,
                );
                if (
                  Constants.expoConfig.extra.ENVIRONMENT ==
                    AppEnvironment.PRODUCTION ||
                  Constants.expoConfig.extra.ENVIRONMENT ==
                    AppEnvironment.STAGING
                ) {
                  ethProviderToPass = getAnynetStaticProvider(
                    digiCollectible.chainId,
                  );
                }
              }
              // setting calltokenowner to true to fetch balance
              let collectible = await throttledGetCollectibleDetails(
                {
                  networkID: digiCollectible.chainId,
                  provider: ethProviderToPass,
                  collectibleAddress: digiCollectible.source,
                  address,
                  wallet,
                  callTokenOwner: true,
                  prefetchedData: digiCollectible?.prefetchedData ?? null,
                },
                { entityData },
                { isCache: false },
              );
              if (collectible != null && collectible.dataURI != '') {
                await dispatch(pushDigitalCollectibleDetails(collectible));
                let activeTimeStamp = true;
                if ((collectible.start, collectible.end)) {
                  activeTimeStamp = isActiveTimeStamp(
                    collectible.start,
                    collectible.end,
                  );
                }
                if (activeTimeStamp) {
                  await dispatch(
                    pushActiveDigitalCollectibleDetails(collectible),
                  );
                }
                if (allPassportCollectibles) {
                  const existPassportCollectible =
                    allPassportCollectibles?.find(
                      x =>
                        x?.address?.toLowerCase() ==
                        collectible?.address?.toLowerCase(),
                    );
                  if (existPassportCollectible) {
                    await dispatch(pushCollectiableEntityOffer(collectible));
                  }
                }
              }
            } catch (ex) {
              // LogToLoot8Console('errr', ex);
              LogCustomError(
                'refreshDataCache | digital collectible',
                ex.name,
                ex.message,
                ' ',
              );
            }
          }
        }

        await wait(1000);

        if (newPassportList && newPassportList.length > 0) {
          const collectionHelper = CollectionHelper__factory.connect(
            addresses[networkID].CollectionHelper,
            ethProvider,
          );
          for (let i = 0; i < newPassportList.length; i++) {
            try {
              const latestState = getState() as RootState;
              const newPassport = newPassportList[i];
              if (isNativeChain(newPassport.chainId)) {
                const existingPassport =
                  latestState.Passports.AllPassportDetailsList.find(
                    p =>
                      p.address.toLowerCase() ==
                      newPassport.source.toLowerCase(),
                  );

                if (existingPassport) {
                  const newLinkedCollection =
                    await collectionHelper.getLinkedCollections(
                      newPassport.source,
                    );
                  const diffInCollection = newLinkedCollection.filter(
                    p => !existingPassport?.linkCollectible.includes(p),
                  );

                  if (diffInCollection && diffInCollection.length > 0) {
                    for (
                      let j = 0;
                      j < existingPassport?.linkCollectible.length;
                      j++
                    ) {
                      await removeData(
                        APP_STORAGE_GET_COLLECTIBLEDETAILS(
                          existingPassport?.linkCollectible[j],
                        ),
                      );
                    }
                    await dispatch(
                      updatePassportLinkedCollections({
                        address: existingPassport.address,
                        linkedCollections: newLinkedCollection,
                      }),
                    );

                    if (
                      state.Passports.selectedPassport &&
                      state.Passports.selectedPassport.address?.toLowerCase() ==
                        existingPassport.address?.toLowerCase()
                    ) {
                      await dispatch(
                        loadPassportOfferList({
                          networkID,
                          provider,
                          passport: state.Passports.selectedPassport,
                          wallet,
                          address,
                        }),
                      );
                      await dispatch(
                        loadThirpartyCollectibleList({
                          networkID,
                          provider,
                          passport: state.Passports.selectedPassport,
                          wallet,
                          address,
                        }),
                      );
                    }
                  }

                  const newWhiteListedCollection =
                    await getThirdPartyCollectiableListByPassport(
                      {
                        networkID,
                        provider: ethProvider,
                        passportAddress: existingPassport.address,
                      },
                      false,
                    );
                  const existingWhiteListedCollection =
                    existingPassport.whitelistedCollections.map(p => p.source);
                  const diffInWhiteListedCollection = newWhiteListedCollection
                    ?.map(p => p.source)
                    .filter(p => !existingWhiteListedCollection?.includes(p));

                  let isCacheRefreshRequired = false;
                  if (
                    diffInWhiteListedCollection &&
                    diffInWhiteListedCollection.length
                  ) {
                    isCacheRefreshRequired = true;
                  }

                  //check if dataURI is changed
                  if (!isCacheRefreshRequired) {
                    let collData = await collectionManager.getCollectionInfo(
                      existingPassport.address,
                    );
                    if (
                      collData?._dataURI &&
                      collData?._dataURI !== existingPassport?.dataURI
                    ) {
                      isCacheRefreshRequired = true;
                    }
                  }

                  if (isCacheRefreshRequired) {
                    // setting calltokenowner to true to fetch balance
                    let passport = await getCollectibleDetails(
                      {
                        networkID,
                        provider,
                        collectibleAddress: existingPassport.address,
                        address,
                        wallet,
                        callTokenOwner: true,
                      },
                      { entityData },
                      { isCache: false },
                    );
                    if (passport != null && passport.dataURI != '') {
                      await dispatch(updateEntirePassportDetail(passport));
                    }
                  }
                }
              }
            } catch (ex) {
              LogToLoot8Console('errr', ex);
              LogCustomError(
                'refreshDataCache | passport',
                ex.name,
                ex.message,
                ex.stack,
              );
            }
          }
        }

        // await dispatch(loadAllCatList({ networkID, provider, address, wallet, userInfo }));
      } catch (ex) {
        LogToLoot8Console('errr', ex);
        LogCustomError('refreshDataCache', ex.name, ex.message, ex.stack);
      }
    }
  },
);

export const loadAllPassporDetails = createAsyncThunk(
  'passport/loadAllPassportDetails',
  async (
    {
      networkID,
      provider,
      entities,
      address,
      wallet,
      userInfo,
      userLocation,
      isCache = true,
    }: IPassportFactoryBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let passportAddressLst: { source: string; chainId: number }[] = [];
    // let allPassportDetails = [];
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch {}

    //await dispatch(clearAllPassportDetails());
    passportAddressLst = await getAllPassport({ networkID, provider }, isCache);

    const data = await getData(
      APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS(CollectionType.PASSPORT),
    );
    let storedMyPassports = data?.availableCollection || null;

    if (data && data?.timeOfCaching && storedMyPassports) {
      const now: any = new Date();
      // Check how much time has elasped since data has been cached in milliseconds
      const timeElapsed =
        now?.getTime() - new Date(data?.timeOfCaching)?.getTime?.();
      // Convert milliseconds to seconds
      const timeElapsedSecs = timeElapsed / 1000;
      // Check cache TTL, if not available then default is 30 minutes
      const thresholdSeconds = appConfig?.cacheTTL
        ? appConfig?.cacheTTL * 60
        : 30 * 60;

      if (timeElapsedSecs < thresholdSeconds) {
        // Cache is still valid
        if (storedMyPassports?.length > 0) {
          passportAddressLst = passportAddressLst.filter(
            p =>
              !storedMyPassports.find(
                q => q.source?.toLowerCase() == p.source?.toLowerCase(),
              ),
          );
        }
      } else {
        // Cache has expired
        // Do nothing, Let the data load
      }
    }

    let subscribedPassportList = [];

    if (passportAddressLst && passportAddressLst.length > 0) {
      //sort Passports by creation time
      let sortedAddresses = await sortCollectionLogs(networkID, isCache);
      sortedAddresses = sortedAddresses.map(a => {
        return a.toLowerCase();
      });

      if (sortedAddresses && sortedAddresses.length > 0) {
        passportAddressLst.sort((a, b) => {
          let addressA = a.source.toLowerCase();
          let addressB = b.source.toLowerCase();
          return (
            sortedAddresses.indexOf(addressB) -
            sortedAddresses.indexOf(addressA)
          );
        });
      }
      subscribedPassportList = await getUserSubscribedPassports(address);
    }

    const processEachPassport = async (passportAddress): Promise<any> => {
      try {
        let providerToPass = provider;
        let chainId = passportAddress.chainId;
        if (!isNativeChain(chainId)) {
          providerToPass = getAnynetStaticProvider(chainId);
        }
        // Fetch collection details from cache
        // Do not refresh marketplace config as the collection is not yet owned by user
        let passport = await throttledGetCollectibleDetails(
          {
            networkID: chainId,
            provider: providerToPass,
            collectibleAddress: passportAddress.source,
            address,
            wallet,
            prefetchedData: passportAddress?.prefetchedData ?? null,
          },
          { entityData },
          {
            isCache: isCache,
            isBalanceRefresh: false,
            isMarketPlaceRefresh: false,
          },
          subscribedPassportList,
        );
        // allPassportDetails.push(passport);

        if (passport != null && passport.dataURI != '') {
          let availablePassport = false;
          if (
            passport?.maxTokens &&
            passport.maxTokens > 0 &&
            passport.collectibleIds?.length == 0
          ) {
            availablePassport =
              passport.collectionCollectibleIds - 2 < passport.maxTokens;
          } else {
            availablePassport = true;
          }

          if (availablePassport) {
            return passport;
          } else {
            return null;
          }
        }
      } catch (error) {
        return null;
      }
    };

    const optimisedProcessingOfExPass = async () => {
      let startProcessingPassports = true,
        _passportsToProcess = [],
        processPassortsForUIStarted = false;
      let passportChunks = [];
      const chunkSize = 10;

      const validPassports = passportAddressLst.filter(
        passportAddress => passportAddress.source !== ZERO_ADDRESS,
      );

      const passportsToProcessFunc = () => _passportsToProcess;
      const splicePassportsToProcessFunc = () =>
        _passportsToProcess.splice(0, 1);

      async function processPassortsForUI() {
        processPassortsForUIStarted = true;
        return await new Promise(resolve => {
          const passportProcessingInterval = setInterval(async () => {
            if (passportsToProcessFunc().length > 0) {
              // Get first passport from list to push
              const passportToProcess = splicePassportsToProcessFunc();
              // Only push Free Profiles in the Explore section
              if (passportToProcess[0].isPremium === false) {
                await dispatch(pushPassportDetails(passportToProcess[0]));
                dispatch(updateLoadingState(false));
              }
            }
            if (
              !startProcessingPassports &&
              passportsToProcessFunc().length == 0
            ) {
              clearInterval(passportProcessingInterval);
              dispatch(updateAllPassportLoadingState(false));
              resolve(true);
            }
          }, 50);
        });
      }

      for (let i = 0; i < validPassports.length; i += chunkSize) {
        passportChunks.push(validPassports.slice(i, i + chunkSize));
      }
      for (let i = 0; i < passportChunks?.length; i++) {
        await Promise.all(
          passportChunks[i]
            .filter(passportAddress => passportAddress.source !== ZERO_ADDRESS)
            .map(async passportAddress => {
              const passport = await processEachPassport(passportAddress);
              if (passport) {
                _passportsToProcess.push(passport);
                if (!processPassortsForUIStarted) {
                  processPassortsForUI();
                }
              }
            }),
        );
      }
      startProcessingPassports = false;
    };

    if (
      Platform.OS == 'web' &&
      Device.isDevice &&
      slowProcessCollectionOnWeb()
    ) {
      await optimisedProcessingOfExPass();
    } else {
      if (
        (Platform.OS == 'android' || Platform.OS == 'ios') &&
        appConfig?.optimisedProcessExPassOnApp
      ) {
        await optimisedProcessingOfExPass();
      } else {
        await Promise.all(
          passportAddressLst
            .filter(passportAddress => passportAddress.source !== ZERO_ADDRESS)
            .map(async passportAddress => {
              const passport = await processEachPassport(passportAddress);
              if (passport) {
                // Only push Free Profiles in the Explore section
                if (passport.isPremium === false) {
                  await dispatch(pushPassportDetails(passport));
                }
              }
            }),
        );
      }
    }
  },
);

export const loadMyPassportDetails = createAsyncThunk(
  'passport/loadMyPassportDetails',
  async (
    {
      networkID,
      provider,
      entities,
      address,
      wallet,
      userInfo,
      userLocation,
      isCache = true,
    }: IPassportFactoryBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let passportAddressLst = [];
    // let allPassportDetails = [];
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    await dispatch(clearAllPassportDetails());
    passportAddressLst = await getAllMintedCollectionForPatron(
      { networkID, provider, address, collectionType: CollectionType.PASSPORT },
      false,
      true,
    );
    let subscribedPassportList = [];

    if (passportAddressLst && passportAddressLst.length > 0) {
      //sort Passports by creation time
      let sortedAddresses = await sortCollectionLogs(networkID, isCache);
      sortedAddresses = sortedAddresses.map(a => {
        return a.toLowerCase();
      });

      if (sortedAddresses && sortedAddresses.length > 0) {
        passportAddressLst.sort((a, b) => {
          let addressA = a.source.toLowerCase();
          let addressB = b.source.toLowerCase();
          return (
            sortedAddresses.indexOf(addressB) -
            sortedAddresses.indexOf(addressA)
          );
        });
      }
      //subscribedPassportList = await getUserSubscribedPassports(address);
    }

    const processEachPassport = async (passportAddress): Promise<any> => {
      try {
        let providerToPass = provider;
        let chainId = passportAddress.chainId;
        if (!isNativeChain(chainId)) {
          providerToPass = getAnynetStaticProvider(chainId);
        }
        // Fetch collection data from cache
        // Update marketplace at initial load
        let passport = await throttledGetCollectibleDetails(
          {
            networkID: chainId,
            provider: providerToPass,
            collectibleAddress: passportAddress.source,
            address,
            wallet,
            prefetchedData: passportAddress?.prefetchedData ?? null,
          },
          { entityData },
          {
            isCache: isCache,
            isBalanceRefresh: false,
            isMarketPlaceRefresh: true,
          },
          subscribedPassportList,
        );
        // allPassportDetails.push(passport);

        if (passport != null && passport.dataURI != '') {
          let availablePassport = false;
          if (
            passport?.maxTokens &&
            passport.maxTokens > 0 &&
            passport.collectibleIds?.length == 0
          ) {
            availablePassport =
              passport.collectionCollectibleIds - 2 < passport.maxTokens;
          } else {
            availablePassport = true;
          }

          if (availablePassport) {
            return passport;
          } else {
            return null;
          }
        }
      } catch (error) {
        return null;
      }
    };

    const optimisedProcessingOfExPass = async () => {
      let startProcessingPassports = true,
        _passportsToProcess = [],
        processPassortsForUIStarted = false;
      let passportChunks = [];
      const chunkSize = 10;

      const validPassports = passportAddressLst.filter(
        passportAddress => passportAddress.source !== ZERO_ADDRESS,
      );

      const passportsToProcessFunc = () => _passportsToProcess;
      const splicePassportsToProcessFunc = () =>
        _passportsToProcess.splice(0, 1);

      async function processPassortsForUI() {
        processPassortsForUIStarted = true;
        return await new Promise(resolve => {
          const passportProcessingInterval = setInterval(async () => {
            if (passportsToProcessFunc().length > 0) {
              // Get first passport from list to push
              const passportToProcess = splicePassportsToProcessFunc();
              if (passportToProcess[0].isPremium === true) {
                await dispatch(pushPassportDetails(passportToProcess[0]));
              } else if (passportToProcess[0].isPremium === false) {
                await dispatch(pushCreatorsDetails(passportToProcess[0]));
              }
              dispatch(updateLoadingState(false));
            }
            if (
              !startProcessingPassports &&
              passportsToProcessFunc().length == 0
            ) {
              clearInterval(passportProcessingInterval);
              dispatch(updateMyPassportLoadingState(false));
              resolve(true);
            }
          }, 50);
        });
      }

      for (let i = 0; i < validPassports.length; i += chunkSize) {
        passportChunks.push(validPassports.slice(i, i + chunkSize));
      }
      for (let i = 0; i < passportChunks?.length; i++) {
        await Promise.all(
          passportChunks[i]
            .filter(passportAddress => passportAddress.source !== ZERO_ADDRESS)
            .map(async passportAddress => {
              const passport = await processEachPassport(passportAddress);
              if (passport) {
                _passportsToProcess.push(passport);
                if (!processPassortsForUIStarted) {
                  processPassortsForUI();
                }
              }
            }),
        );
      }
      startProcessingPassports = false;
    };

    if (
      Platform.OS == 'web' &&
      Device.isDevice &&
      slowProcessCollectionOnWeb()
    ) {
      await optimisedProcessingOfExPass();
    } else {
      let appConfig;
      try {
        appConfig = await getAppConfiguration();
      } catch {}

      if (
        (Platform.OS == 'android' || Platform.OS == 'ios') &&
        appConfig?.optimisedProcessExPassOnApp
      ) {
        await optimisedProcessingOfExPass();
      } else {
        await Promise.all(
          passportAddressLst
            .filter(passportAddress => passportAddress.source !== ZERO_ADDRESS)
            .map(async passportAddress => {
              const passport = await processEachPassport(passportAddress);
              if (passport) {
                if (passport.isPremium === true) {
                  await dispatch(pushPassportDetails(passport));
                } else if (passport.isPremium === false) {
                  await dispatch(pushCreatorsDetails(passport));
                }
              }
            }),
        );
      }
    }
  },
);

export const loadAvailablePassporDetails = createAsyncThunk(
  'passport/loadAvailablePassporDetails',
  async (
    {
      networkID,
      provider,
      entities,
      address,
      wallet,
      userInfo,
      userLocation,
    }: IPassportFactoryBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let allPassportDetails: ICollectibleDetail[] = [];
    let mintablePassport = [];
    const collectionManager = CollectionManager__factory.connect(
      addresses[networkID].CollectionManager,
      provider,
    );

    // LogToLoot8Console("loadAvailablePassporDetails userLocation", userLocation);

    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    allPassportDetails = state.Passports.AllPassportDetailsList;

    await Promise.all(
      allPassportDetails?.map(async passportDetail => {
        try {
          let isOpenPassport: boolean = true;
          let providerToPass = provider;
          if (!isNativeChain(passportDetail.chainId)) {
            providerToPass = getAnynetStaticProvider(passportDetail.chainId);
          }

          let tokens = [];
          if (
            passportDetail?.address &&
            passportDetail?.area != null &&
            passportDetail.area.length == 2 &&
            passportDetail.area[1] > 0
          ) {
            let passportAddress = passportDetail.address;
            // check if passport minted by user

            tokens = await getCollectibleIdsForPatron({
              networkID: passportDetail.chainId,
              provider: providerToPass,
              collectibleAddress: passportAddress,
              address,
            });
            if (tokens && tokens.length > 0) {
              // Avoid geo-fence if passport minted by the current user.
              isOpenPassport = true;
            } else {
              // check geo-fence if passport is not minted.
              isOpenPassport = false;
              if (userLocation != null) {
                if (isLocationAvailable(userLocation, passportDetail.area)) {
                  isOpenPassport = true;
                }
              }
            }
          }

          if (isOpenPassport && passportDetail.maxTokens) {
            const passportCollection = Loot8Collection__factory.connect(
              passportDetail.address,
              provider,
            );
            //LogToLoot8Console(passportDetail.name, +(await passportCollection.collectionCollectibleIds()));
            if (
              +(await passportCollection.collectionCollectibleIds()) >
              passportDetail.maxTokens
            ) {
              isOpenPassport = false;
            }
          }

          if (passportDetail.start && passportDetail.end) {
            if (!isActiveTimeStamp(passportDetail.start, passportDetail.end)) {
              isOpenPassport = false;
            }
          }

          if (passportDetail.mintWithLinkedOnly && isOpenPassport) {
            /* passport has fullfilled all condition, but if it is mintWithLinkedOnly then need to check
            linked collection is minted or not
        */

            const whiteListedCollections =
              await getThirdPartyCollectiableListByPassport({
                networkID,
                provider,
                passportAddress: passportDetail.address,
              });

            if (whiteListedCollections && whiteListedCollections.length > 0) {
              for (let j = 0; j < whiteListedCollections.length; j++) {
                const whiteListedColl = whiteListedCollections[j];
                const loot8Coll = Loot8Collection__factory.connect(
                  whiteListedColl.source,
                  getAnynetStaticProvider(whiteListedColl.chainId),
                );
                if (+(await loot8Coll.balanceOf(address)) > 0) {
                  //whitelisted collection is minted for patron, passport is open for minting
                  isOpenPassport = true;
                  break;
                } else {
                  isOpenPassport = false;
                }
              }
            } else {
              isOpenPassport = false;
            }

            if (!isOpenPassport) {
              if (passportDetail.linkCollectible?.length > 0) {
                for (
                  let j = 0;
                  j < passportDetail.linkCollectible.length;
                  j++
                ) {
                  const linkedColl = passportDetail.linkCollectible[j];
                  const linkedCollChainId =
                    await CollectionManagerData.getCollectionChainId(
                      linkedColl,
                    );
                  if (!+linkedCollChainId) return;
                  if (+linkedCollChainId) {
                    const loot8Coll = Loot8Collection__factory.connect(
                      linkedColl,
                      getAnynetStaticProvider(+linkedCollChainId),
                    );
                    if (+(await loot8Coll.balanceOf(address)) > 0) {
                      //linked collection is minted for patron, passport is open for minting
                      isOpenPassport = true;
                      break;
                    } else {
                      isOpenPassport = false;
                    }
                  }
                }
              } else {
                isOpenPassport = false;
              }
            }
          }

          //mint the passports while loading
          if (passportDetail?.collectibleCount == 0 && isOpenPassport) {
            const subscriptionManager = SubscriptionManager__factory.connect(
              addresses[getNetwork()].SubscriptionManager,
              getStaticProvider(),
            );
            const preSubscribeChecksPassed =
              passportDetail?.passportType === PassportType.SUBSCRIPTION
                ? await subscriptionManager.passedPreAirdropChecks(
                    address,
                    passportDetail?.address,
                  )
                : [true, 'PRE-SUBSCRIPTION CHECKS SUCCESSFUL'];

            if (preSubscribeChecksPassed[0]) {
              // Fetch collection data from cache and also refresh marketplace config as it is mintable to user
              passportDetail = await getCollectibleDetails(
                {
                  networkID: passportDetail.chainId,
                  provider: providerToPass,
                  collectibleAddress: passportDetail.address,
                  address,
                  wallet,
                },
                { entityData },
                {
                  isCache: true,
                  isBalanceRefresh: true,
                  isMarketPlaceRefresh: true,
                },
              );
              if (
                passportDetail?.collectibleCount == 0 &&
                passportDetail?.isActive
              ) {
                mintablePassport.push(passportDetail?.address);
              }
            }
          }
          if (passportDetail?.collectibleCount > 0) {
            await dispatch(
              updatePassportDetails({
                address: passportDetail.address,
                collectibleIds: passportDetail?.collectibleIds,
                collectibleCount: passportDetail?.collectibleCount,
                isAvailable: isOpenPassport,
                passportType: passportDetail?.passportType,
              }),
            );
          }

          //Subscription passport buy price keeps changing hence need to fetch latest price on every reload
          if (passportDetail?.passportType === PassportType.SUBSCRIPTION) {
            const buyPrice = await getPassportFinalBuyPrice(
              passportDetail.address,
            );
            if (passportDetail.buyPrice !== buyPrice) {
              dispatch(
                updatePassportBuyPrice({
                  address: passportDetail.address,
                  buyPrice: buyPrice,
                }),
              );
            }
          }
        } catch (error) {
          LogToLoot8Console(
            'Error in allPassportDetails.map',
            passportDetail,
            error,
          );
          LogCustomError(
            'loadAvailablePassporDetails',
            error?.name,
            error?.message,
            error,
          );
        }
      }),
    );
    if (mintablePassport.length > 0) {
      let toBeMintedPassports = allPassportDetails.filter(x =>
        mintablePassport.find(y => y == x?.address),
      );

      dispatch(
        mintAvailablePassport({
          networkID,
          provider,
          address,
          wallet,
          toBeMintedPassports,
        }),
      ).then(x => {
        // mint digital collectible if geo-fence criteria match.
        // dispatch(
        // loadAvailableDigitalCollectibleDetails({
        //   networkID,
        //   provider,
        //   address,
        //   wallet,
        //   entities: [],
        //   userInfo,
        //   userLocation,
        //   isCache: false,
        // }),
        // ).then(x => {
        // reload catalog
        dispatch(
          loadCatalogCollectiable({
            networkID,
            provider,
            address,
            wallet,
            userInfo,
            isCache: false,
          }),
        );
        // });
        dispatch(
          loadCatalogPassport({
            networkID,
            provider,
            address,
            wallet,
            userInfo,
            isCache: false,
          }),
        );
      });
    }
  },
);

//todo: need to call the correct mint function-not clear which method needs to call in contract
export const mintAvailablePassport = createAsyncThunk(
  'Passports/mintAvailablePassport',
  async (
    { networkID, provider, address, wallet, toBeMintedPassports }: any,
    { dispatch, getState },
  ): Promise<any> => {
    LogToLoot8Console('calling passport mint');
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    for (let i = 0; i < toBeMintedPassports.length; i++) {
      const passportDetail: ICollectibleDetail = toBeMintedPassports[i];

      // clear cache
      await storeData(
        APP_STORAGE_GET_COLLECTIBLEDETAILS(passportDetail?.address),
        null,
      );

      try {
        // check minting queue
        let isPassportMintable = true;
        if (passportMintingQueue.has(toBeMintedPassports[i].address)) {
          // set flag if passport already in minting queue.
          isPassportMintable = false;
        } else {
          passportMintingQueue.set(toBeMintedPassports[i].address, true);
        }
        if (isPassportMintable) {
          let providerToPass = provider;
          if (!isNativeChain(passportDetail.chainId)) {
            providerToPass = getAnynetStaticProvider(passportDetail.chainId);
          }

          await dispatch(
            mintPassport({
              networkID: passportDetail.chainId,
              provider: providerToPass,
              address,
              passportAddress: passportDetail?.address,
              wallet,
            }),
          );
          await wait(1000);

          let passport = await getCollectibleDetails(
            {
              networkID: passportDetail.chainId,
              provider: providerToPass,
              collectibleAddress: passportDetail?.address,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: true,
              isBalanceRefresh: true,
              isMarketPlaceRefresh: true,
            },
          );

          if (passport && passport?.collectibleCount > 0) {
            await dispatch(
              updatePassportDetails({
                address: passportDetail.address,
                isAvailable: true,
                collectibleIds: passport.collectibleIds,
                collectibleCount: passport.collectibleCount,
                passportType: passport.passportType,
              }),
            );
            //push notification
            dispatch(
              pushNotification({
                subject: 'Yay! You got a new passport',
                body:
                  passportDetail?.name +
                  ' passport has been added to your portfolio',
                //uri: friend.avatarURI,
                timeStamp: Number.parseInt(getSyncedTime().toString()),
                blockNumber: await provider.getBlockNumber(),
                id: passportDetail?.address,
                notificationType: NotificationType.PassportMint,
                dataObject: passportDetail,
              }),
            );

            // mint linked collection token for holders
            // mintLinkedCollectionsTokensForHolders({networkID, provider, address, passportAddress: passport})

            dispatch(
              loadThirpartyCollectibleList({
                networkID,
                provider,
                passport: passport,
                wallet,
                address,
              }),
            );
          }
          // update passport minting queue to set current passport to false.
          // so it will be pulled to minting queue again in case of failure.
          passportMintingQueue.set(toBeMintedPassports[i].address, false);
        }
      } catch (error) {
        LogCustomError(
          'mintAvailablePassport',
          address,
          'Failed on mintAvailablePassport',
          error,
        );
      }
    }
  },
);
export const loadPassportRewardRate = createAsyncThunk(
  'Passports/loadPassportRewardRate',
  async (
    {
      networkID,
      provider,
      passport,
      address,
      wallet,
    }: IPassportOfferFactoryBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    let rewardBalance = await getData(
      APP_STORAGE_GET_PASSPORTBALANCE(passport.address),
    );
    if (!rewardBalance) {
      try {
        rewardBalance = await getRewardBalanceForUser({
          networkID,
          passport,
          provider,
          address,
        });
        await storeData(
          APP_STORAGE_GET_PASSPORTBALANCE(passport.address),
          rewardBalance,
        );
      } catch (ex) {
        rewardBalance = 0;
        //LogCustomError("loadPassportRewardRate-" + passport.address, ex.name, ex.message, ex.stack);
      }
    }
    return { PassportRewardBalance: rewardBalance };
  },
);

export const mintPassport = createAsyncThunk(
  'Passports/mintPassport',
  async (
    {
      networkID,
      provider,
      passportAddress,
      address,
      wallet,
    }: IPassportAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    const data = GetPassportMintMessage(address, passportAddress);
    let msg: IMessageMetaData = {
      to: !isNativeChain(networkID)
        ? passportAddress
        : addresses[networkID].CollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
    };
    await dispatch(SendMetaTX(msg));
  },
);

export const setPassportLoading = createAsyncThunk(
  'Passports/setPassportLoading',
  async (): Promise<any> => {
    return { loading: true };
  },
);

export const clearPassportMintingQueue = createAsyncThunk(
  'passport/clearPassportMintingQueue',
  async (
    { networkID, provider }: IBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    if (passportMintingQueue) {
      passportMintingQueue.clear();
    }
  },
);

export const searchPassport = createAsyncThunk(
  'passport/searchPassport',
  async ({ searchText }: { searchText }, { getState, dispatch }) => {
    const state = getState() as RootState;
    let allPassportSearchList = [
      ...state.Passports.AllPassportDetailsSearchList,
    ];
    let AllPassportDetailsList = [...state.Passports.AllPassportDetailsList];

    if (searchText && searchText.length > 0) {
      let searchTextLower = searchText.toLowerCase().trim();

      if (searchText.length === 1) {
        allPassportSearchList = AllPassportDetailsList.filter(
          p => p.name.toLowerCase().startsWith(searchTextLower) === true,
        );
      } else {
        allPassportSearchList = AllPassportDetailsList.filter(
          p => p.name.toLowerCase().indexOf(searchTextLower) > -1,
        );
      }
    } else {
      allPassportSearchList = AllPassportDetailsList;
    }

    return {
      allPassportDetailsSearchList: allPassportSearchList,
    };
  },
);

export const approveForSubscription = createAsyncThunk(
  'passport/approveForSubscription',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      subscription = true,
    }: { networkID; provider; wallet; address; subscription? },
    { dispatch, getState },
  ): Promise<any> => {
    const Loot8TokenContract = Loot8Token__factory.connect(
      addresses[networkID].Loot8Token,
      provider,
    );

    const allowanceForAddress = subscription
      ? addresses[networkID].SubscriptionManager
      : addresses[networkID].OrderDispatcher;
    const allowance = await Loot8TokenContract.allowance(
      address,
      allowanceForAddress,
    );

    if (Number(allowance) === 0) {
      const price = '10000000000';
      const data = ApproveLoot8TokenForSubscription(
        allowanceForAddress,
        ethers.utils.parseUnits(price.toString(), 18).toString(),
      );
      let msg: IMessageMetaData = {
        to: addresses[networkID].Loot8Token,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      const res = await dispatch(SendMetaTX(msg));
    }
  },
);

export const approveForUnsubscribe = createAsyncThunk(
  'passport/approveForUnsubscribe',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      passportAddress,
      tokenId,
    }: { networkID; provider; wallet; address; passportAddress; tokenId },
    { dispatch, getState },
  ): Promise<any> => {
    const collectibleContract = Loot8Collection__factory.connect(
      passportAddress,
      provider,
    );
    const approval = await collectibleContract.getApproved(Number(tokenId));

    if (approval !== addresses[networkID].SubscriptionManager) {
      const data = ApprovePassportForUnsubscribe(
        addresses[networkID].SubscriptionManager,
        Number(tokenId),
      );
      let msg: IMessageMetaData = {
        to: passportAddress,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      const res = await dispatch(SendMetaTX(msg));
    }
  },
);

export const subscribePassport = createAsyncThunk(
  'passport/subscribePassport',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      passportAddress,
      passportSubscribePrice,
      userInfo,
      collectionType,
    }: IPassportSubscribeAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let passportTokenID = '';
    const state = getState() as RootState;
    const currentLocation = state?.Location.currentLocation;

    const subscribePassportData = SubscribePassportMessage(passportAddress);
    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].SubscriptionManager,
        wallet: wallet,
        data: subscribePassportData,
        networkID: networkID,
        provider: provider,
      };
      await dispatch(SendMetaTX(msg)).then(async response => {
        try {
          if (response && response.payload?.eventLogs) {
            passportTokenID = GetSubscribePassportLogsData(
              response.payload?.eventLogs,
              address,
            );
          }
          if (
            response?.payload?.status == 'Success' &&
            passportTokenID !== ''
          ) {
            //refresh balance and update latest tokenID for the passport
            const state = getState() as RootState;
            const entityData = state.Entity.EntityData;
            let passport = await getCollectibleDetails(
              {
                networkID: networkID,
                provider: provider,
                collectibleAddress: passportAddress,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: true,
                isMarketPlaceRefresh: true,
              },
            );
            if (collectionType === CatalogTypes.PASSPORT) {
              if (passport && passport?.collectibleCount > 0) {
                // push minted collection to local storage.
                try {
                  let blockNumber = GetBlockNumberFromLogsData(
                    response?.payload?.eventLogs,
                  );
                  if (blockNumber) {
                    await storeData(MINTED_COLLECTION(passportAddress), {
                      address: passportAddress,
                      blockNumber: blockNumber,
                      collectionType: passport?.collectionType,
                      chainId: passport?.chainId,
                    });
                  }
                } catch (e) {}

                await dispatch(
                  mintLinkedCollectibles({
                    networkID: passport?.chainId,
                    provider,
                    address,
                    passportAddress,
                    wallet,
                  }),
                );

                await dispatch(
                  updatePassportDetails({
                    address: passportAddress,
                    isAvailable: true,
                    collectibleIds: passport.collectibleIds,
                    collectibleCount: passport.collectibleCount,
                    passportType: passport.passportType,
                  }),
                );
                await dispatch(
                  updatePassportSubscriptionStatus({
                    address: passportAddress,
                    passportType: PassportType.SUBSCRIBED,
                    buyPrice: passportSubscribePrice,
                    timestamp: passport.timestamp,
                  }),
                );
                dispatch(
                  loadCatalogPassport({
                    networkID,
                    provider,
                    address,
                    wallet,
                    userInfo,
                    isCache: false,
                  }),
                );
                // OLD: get collectible details again with BalanceRefresh false to get latest marketplace config after subscription
                // NEW: removing passport refresh since marketplace is added to balance refresh
                // let passportRefresh = (await getCollectibleDetails({ networkID: networkID, provider: provider, collectibleAddress: passportAddress, address, wallet }, { entityData }, true, false));
                if (passport && passport?.marketPlaceConfig) {
                  await dispatch(
                    updatePassportMarketPlaceConfig({
                      address: passport.address,
                      marketPlaceConfig: passport.marketPlaceConfig,
                    }),
                  );
                }
                // // mint link collectible once passport subscribed.
                // await dispatch(
                //   loadAvailableDigitalCollectibleDetails({
                //     networkID,
                //     provider,
                //     address,
                //     wallet,
                //     entities: [],
                //     userInfo,
                //     userLocation: currentLocation,
                //     isCache: false,
                //     passportAddress: passportAddress,
                //   }),
                // );
              }
            } else {
              await dispatch(
                updatePremiumCollectibles({
                  address: passportAddress,
                  collectibleIds: passport.collectibleIds,
                  collectibleCount: passport.collectibleCount,
                }),
              );
            }
          }
        } catch (ex) {
          LogCustomError(
            'subscribePassport' + passportAddress,
            ex.name,
            ex.message,
            ex.stack,
          );
        }
      });
    }
    return passportTokenID;
  },
);

export const unsubscribePassport = createAsyncThunk(
  'passport/unsubscribePassport',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      passportAddress,
      passportIds,
      userInfo,
      collectionType,
    }: IPassportUnsubscribeAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let txReceipt;
    const unsubscribePassportData = UnsubscribePassportMessage(
      passportAddress,
      passportIds,
    );

    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].SubscriptionManager,
        wallet: wallet,
        data: unsubscribePassportData,
        networkID: networkID,
        provider: provider,
      };
      await dispatch(SendMetaTX(msg)).then(async response => {
        try {
          if (response.payload.status == 'Success') {
            txReceipt = response.payload;

            //refresh balance and update latest tokenID for the passport
            const state = getState() as RootState;
            const entityData = state.Entity.EntityData;
            let passport = await getCollectibleDetails(
              {
                networkID: networkID,
                provider: provider,
                collectibleAddress: passportAddress,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: true,
                isMarketPlaceRefresh: true,
              },
            );
            if (collectionType === CatalogTypes.PASSPORT) {
              if (passport && passport?.collectibleCount > 0) {
                await dispatch(
                  updatePassportDetails({
                    address: passportAddress,
                    isAvailable: true,
                    collectibleIds: passport.collectibleIds,
                    collectibleCount: passport.collectibleCount,
                    passportType: passport.passportType,
                  }),
                );
              }
              dispatch(
                updatePassportSubscriptionStatus({
                  address: passportAddress,
                  passportType: PassportType.SUBSCRIPTION,
                  timestamp: null,
                }),
              );
              dispatch(
                loadCatalogPassport({
                  networkID,
                  provider,
                  address,
                  wallet,
                  userInfo,
                  isCache: false,
                }),
              );
              // OLD: get collectible details again with BalanceRefresh false to get latest marketplace config after subscription
              // NEW: removing passport refresh since marketplace is added to balance refresh
              // let passportRefresh = (await getCollectibleDetails({ networkID: networkID, provider: provider, collectibleAddress: passportAddress, address, wallet }, { entityData }, true, false));
              if (passport && passport?.marketPlaceConfig) {
                await dispatch(
                  updatePassportMarketPlaceConfig({
                    address: passport?.address,
                    marketPlaceConfig: passport?.marketPlaceConfig,
                  }),
                );
              }
            } else {
              await dispatch(
                updatePremiumCollectibles({
                  address: passportAddress,
                  collectibleIds: passport.collectibleIds,
                  collectibleCount: passport.collectibleCount,
                }),
              );
            }
          }
        } catch (ex) {
          LogCustomError(
            'unsubscribePassport' + passportAddress,
            ex.name,
            ex.message,
            ex.stack,
          );
        }
      });
    }

    return txReceipt;
  },
);

export const updateSubscriptionPrices = createAsyncThunk(
  'passport/updateSubscriptionPrices',
  async (p, { getState, dispatch }): Promise<void> => {
    const state = getState() as RootState;

    // If already running then do not rerun
    if (state.Passports.isExPassPriceUpdateProgress) return;

    await dispatch(setExPassPriceUpdateProgress(true));

    const exPassesForPrices = state.Passports.AllPassportDetailsList.filter(
      p =>
        p.collectionType == CollectionType.PASSPORT &&
        p.passportType == PassportType.SUBSCRIPTION,
    );

    const latestPrices = await fetchSubscriptionPrices(
      exPassesForPrices.map(p => p.address),
    );

    if (latestPrices?.length > 0) {
      await dispatch(updateAllPassportBuyPrice(latestPrices));

      await exPassesForPrices.map(async p => {
        const localStoredData = await getData(
          APP_STORAGE_GET_COLLECTIBLEDETAILS(p.address),
        );
        const latestPriceData = latestPrices.find(
          q => q.collection?.toLowerCase() == p.address?.toLowerCase(),
        );
        if (localStoredData && latestPriceData) {
          localStoredData.buyPrice =
            Number(latestPriceData.price) / Math.pow(10, 18);
          await storeData(
            APP_STORAGE_GET_COLLECTIBLEDETAILS(p.address),
            localStoredData,
          );
        }
      });
    }
    await dispatch(setExPassPriceUpdateProgress(false));

    //Emit an event once latest prices are updated, to update the components
    event.emit(CustomDeviceEvents.ExPassPriceUpdated);
  },
);

export const getPassportMarketSellPrice = async (passportAddress, tokenId) => {
  let sellPrice: number = 0;
  try {
    const SubscriptionManager = SubscriptionManager__factory.connect(
      addresses[getNetwork()].SubscriptionManager,
      getStaticProvider(),
    );
    let passportSellPrice = await SubscriptionManager.staked(
      passportAddress,
      tokenId,
    );
    if (passportSellPrice) {
      const floorPrice = Number(passportSellPrice) / Math.pow(10, 18);
      //const platformFee = passportSellPrice.length > 1 && passportSellPrice[1] !== null ? Number(passportSellPrice[1]) / Math.pow(10, 18) : 0;
      //const peopleFee = passportSellPrice.length > 2 && passportSellPrice[2] !== null ? Number(passportSellPrice[2]) / Math.pow(10, 18) : 0;
      sellPrice = floorPrice;
    }
  } catch (ex) {
    LogCustomError(
      'getPassportMarketSellPrice: getSellPrice' + passportAddress,
      ex.name,
      ex.message,
      ex.stack,
    );
  }
  return sellPrice;
};

export const getPassportFinalBuyPrice = async passportAddress => {
  let buyPrice: number = 0;
  try {
    const subscriptionManager = SubscriptionManager__factory.connect(
      addresses[getNetwork()].SubscriptionManager,
      getStaticProvider(),
    );
    let passportBuyPrice = await subscriptionManager.getSubscriptionPrice(
      passportAddress,
    );
    if (passportBuyPrice && passportBuyPrice.length > 0) {
      const floorPrice = Number(passportBuyPrice[0]) / Math.pow(10, 18);
      const platformFee =
        passportBuyPrice.length > 1 && passportBuyPrice[1] !== null
          ? Number(passportBuyPrice[1]) / Math.pow(10, 18)
          : 0;
      const peopleFee =
        passportBuyPrice.length > 2 && passportBuyPrice[2] !== null
          ? Number(passportBuyPrice[2]) / Math.pow(10, 18)
          : 0;
      buyPrice = floorPrice + platformFee + peopleFee;
    }
  } catch (ex) {
    LogCustomError(
      'getPassportMarketSellPrice: getSellPrice' + passportAddress,
      ex.name,
      ex.message,
      ex.stack,
    );
  }
  return buyPrice;
};

export const getArbitrumOnePassports = async ({
  networkID,
  provider,
  address,
}: IAddressBaseAsyncThunk): Promise<string[]> => {
  if (!Boolean(NETWORKS[networkID].chainId)) {
    return [];
  }

  const collectionManager = CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  return await collectionManager.getAllCollectionsForPatron(
    CollectionType.PASSPORT,
    address,
    true,
  );
};

const initialState: IPassportSliceData = {
  AllPassportDetailsList: [],
  AllPatronPassportDetailsList: [],
  loading: false,
  loadingAllPassport: false,
  loadingMyPassport: false,
  searching: false,
  isMintloading: false,
  PassportRewardBalance: 0,
  selectedPassport: null,
  AllPassportDetailsSearchList: [],
  passportSearchText: '',
  initialPassportLoaded: false,
  isExPassPriceUpdateProgress: false,
};

const PassportSlice = createSlice({
  name: 'PassportDetails',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updateSelectedPassport(state, action) {
      state.selectedPassport = action.payload;
    },
    clearAllPassportDetails(state) {
      state.AllPassportDetailsList = [];
    },
    resetSearchPassport(state) {
      state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
    },
    updatePassportBuyPrice(state, action) {
      if (
        state.AllPassportDetailsList &&
        state.AllPassportDetailsList.length > 0
      ) {
        let passport = state.AllPassportDetailsList.find(
          p => p.address == action.payload.address,
        );
        if (passport) {
          passport.buyPrice = action.payload.buyPrice;
        }
        state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
      }
    },
    updateAllPassportBuyPrice(
      state,
      action: PayloadAction<ISubscriptionPrice[]>,
    ) {
      if (
        state.AllPassportDetailsList &&
        state.AllPassportDetailsList.length > 0
      ) {
        state.AllPassportDetailsList = state.AllPassportDetailsList.map(p => {
          const updatedPass = action.payload.find(
            q => q.collection?.toLowerCase() == p.address?.toLowerCase(),
          );
          if (updatedPass) {
            p.buyPrice = Number(updatedPass.price) / Math.pow(10, 18);
          }
          return p;
        });
        state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
      }
    },
    updatePassportSubscriptionStatus(state, action) {
      if (
        state.AllPassportDetailsList &&
        state.AllPassportDetailsList.length > 0
      ) {
        let passport = state.AllPassportDetailsList.find(
          p => p.address == action.payload.address,
        );
        if (passport) {
          passport.passportType = action.payload.passportType;
          passport.timestamp = action.payload.timestamp; //to be able to show latest subscribed passport at first of the list
          if (action.payload.buyPrice) {
            passport.initialBuyPrice = action.payload.buyPrice;
          }
        }
        state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
      }
    },
    updatePassportDetails(state, action) {
      state.AllPassportDetailsList = state.AllPassportDetailsList.map(x => ({
        ...x,
        collectibleCount:
          action.payload.address == x.address
            ? action.payload.collectibleCount
            : x.collectibleCount,
        collectibleIds:
          action.payload.address == x.address
            ? action.payload.collectibleIds
            : x.collectibleIds,
        isAvailable:
          action.payload.address == x.address
            ? action.payload.isAvailable
            : x.isAvailable,
        passportType:
          action.payload.address == x.address
            ? action.payload.passportType
            : x.passportType,
      }));
      state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
    },
    updatePassportMarketPlaceConfig(state, action) {
      if (
        state.AllPassportDetailsList &&
        state.AllPassportDetailsList.length > 0
      ) {
        let passport = state.AllPassportDetailsList.find(
          p => p.address == action.payload.address,
        );
        if (passport) {
          passport.marketPlaceConfig = action.payload.marketPlaceConfig;
        }
        state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
      }
    },
    pushPassportDetails(state, action) {
      state.AllPassportDetailsList = state.AllPassportDetailsList.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.AllPassportDetailsList.push(action.payload);
      state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
    },
    removePassportDetails(state, action) {
      state.AllPassportDetailsList = state.AllPassportDetailsList.filter(x => {
        return x.address?.toLowerCase() != action.payload?.toLowerCase();
      });
      state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
    },
    updateEntirePassportDetail(state, action) {
      state.AllPassportDetailsList = state.AllPassportDetailsList.map(x => {
        if (x.address?.toLowerCase() == action.payload.address?.toLowerCase()) {
          return { ...action.payload };
        } else {
          return { ...x };
        }
      });
      state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
    },
    updatePassportLinkedCollections(state, action) {
      if (
        state.AllPassportDetailsList &&
        state.AllPassportDetailsList.length > 0
      ) {
        let passport = state.AllPassportDetailsList.find(
          p => p.address == action.payload.address,
        );
        if (passport) {
          passport.linkCollectible = action.payload.linkedCollections;
        }
        state.AllPassportDetailsSearchList = state.AllPassportDetailsList;
      }
    },
    setPassportSearchText(state, action) {
      state.passportSearchText = action.payload;
    },
    setInitialPassportLoaded(state, action) {
      state.initialPassportLoaded = action.payload;
    },
    updateLoadingState(state, action) {
      if (state.loading !== action.payload) {
        state.loading = action.payload;
      }
    },
    updateAllPassportLoadingState(state, action) {
      if (state.loadingAllPassport !== action.payload) {
        state.loadingAllPassport = action.payload;
      }
    },
    updateMyPassportLoadingState(state, action) {
      if (state.loadingMyPassport !== action.payload) {
        state.loadingMyPassport = action.payload;
      }
    },
    setExPassPriceUpdateProgress(state, action) {
      state.isExPassPriceUpdateProgress = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadMyPassportDetails.pending, (state, action) => {
        state.loading = true;
        state.loadingMyPassport = true;
      })
      .addCase(loadMyPassportDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loadMyPassportDetails.rejected, (state, action) => {
        state.loadingMyPassport = false;
      })
      .addCase(loadAllPassporDetails.pending, (state, action) => {
        state.loadingAllPassport = true;
      })
      .addCase(setPassportLoading.fulfilled, (state, action) => {
        state.loading = true;
      })
      .addCase(mintAvailablePassport.pending, (state, action) => {
        state.isMintloading = true;
      })
      .addCase(mintAvailablePassport.rejected, (state, { error }: any) => {
        state.isMintloading = false;
        showToastMessage();
        LogCustomError(
          'mintAvailableandnotifyPassport',
          error.name,
          error.message,
          error.stack,
        );
      })
      .addCase(mintAvailablePassport.fulfilled, (state, action) => {
        state.isMintloading = false;
      })
      .addCase(loadAvailablePassporDetails.fulfilled, (state, action) => {
        if (state.initialPassportLoaded) {
          state.loading = false;
        }
      })
      .addCase(
        loadAvailablePassporDetails.rejected,
        (state, { error }: any) => {
          state.loading = false;
          LogCustomError(
            'loadAvailablePassporDetails',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(
        loadAllPassporDetails.rejected,
        (
          state: { loading: boolean; loadingAllPassport: boolean },
          { error }: any,
        ) => {
          state.loading = false;
          state.loadingAllPassport = false;
          showToastMessage();
          LogCustomError(
            'loadAllPassporDetails',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadPassportRewardRate.fulfilled, (state, action) => {
        state.PassportRewardBalance = action.payload.PassportRewardBalance;
      })
      .addCase(
        loadPassportRewardRate.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadPassportRewardRate',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(searchPassport.pending, (state, action) => {
        state.searching = true;
      })
      .addCase(searchPassport.fulfilled, (state, action) => {
        state.AllPassportDetailsSearchList =
          action.payload.allPassportDetailsSearchList;
        state.searching = false;
      })
      .addCase(searchPassport.rejected, (state, { error }: any) => {
        state.searching = false;
      });
  },
});

export const PassportSliceReducer = PassportSlice.reducer;

const baseInfo = (state: RootState) => state.Passports;

export const {
  fetchAppSuccess,
  updateSelectedPassport,
  updatePassportDetails,
  clearAllPassportDetails,
  pushPassportDetails,
  removePassportDetails,
  updatePassportLinkedCollections,
  updateEntirePassportDetail,
  updatePassportSubscriptionStatus,
  updatePassportBuyPrice,
  updateAllPassportBuyPrice,
  setExPassPriceUpdateProgress,
  resetSearchPassport,
  setPassportSearchText,
  updatePassportMarketPlaceConfig,
  setInitialPassportLoaded,
  updateLoadingState,
  updateAllPassportLoadingState,
  updateMyPassportLoadingState,
} = PassportSlice.actions;

export const getPassportState = createSelector(
  baseInfo,
  PassportSlice => PassportSlice,
);
