import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { debounce } from 'lodash';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  RESPONSIVE,
  RESPONSIVE_WIDTH,
  SortingOptions,
  getAppConfiguration,
  getSubgraphConfig,
} from '../../../appconstants';
import { ListRetrivalStatus } from '../../../enums/marketPlace.enum';
import { LogToLoot8Console } from '../../../helpers/Loot8ConsoleLogger';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useWeb3AuthContext } from '../../../hooks/web3authContext';
import {
  clearCollectibles,
  loadPublicMarketPlaceData,
  searchCollectible,
  setLoadingCollectible,
  setOlderListLoading,
} from '../../../slices/MarketPlaceSlice';
import { defaultTheme } from '../../../themes/loot8';
import AppLoaderComponent from '../../Loader';
import CollectibleItem from './MPCollectibleItem';

const isWeb = Platform.OS === 'web';
const DEFAULT_PAGE_SIZE = isWeb ? 16 : 8;
const numOfColumns = 2;

const MPCollectibles = ({
  navigation,
  searchCollectibleText,
  setSearchCollectibleText,
  selectedSortingOption,
}) => {
  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();

  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const isResponsive =
    RESPONSIVE == 'true' && width > parseInt(RESPONSIVE_WIDTH);
  const windowWidth = width > 600 ? 600 : width;

  const tileWidth =
    width > 600
      ? isResponsive
        ? 300
        : windowWidth / numOfColumns - 30
      : windowWidth / numOfColumns - 30;
  const collectiblesList = useAppSelector(
    state => state?.MarketPlace?.listedCollectibleSearchList,
  );
  const loadingCollectible = useAppSelector(
    state => state?.MarketPlace?.loadingCollectible,
  );
  const olderListLoading = useAppSelector(
    state => state.MarketPlace.olderListLoading,
  );
  const initialListLoading = useAppSelector(
    state => state.MarketPlace.initialListLoading,
  );
  // const [searchCollectibleText, setSearchCollectibleText] = useState('');
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(DEFAULT_PAGE_SIZE);
  const [listRetrivalStatus, setListRetrivalStatus] = useState(
    ListRetrivalStatus.INITIAL_LIST,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hovered, setHovered] = useState(false);
  const ScrollViewRef = useRef<ScrollView>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const sortedCollectiblesList = useMemo(() => {
    const availableCollectibles = collectiblesList?.filter(x => {
      if (searchCollectibleText) {
        if (searchCollectibleText.length === 1) {
          return (
            x?.collectionData?.name
              .toLowerCase()
              .startsWith(searchCollectibleText?.toLowerCase().trim()) === true
          );
        } else {
          return (
            x?.collectionData?.name
              .toLowerCase()
              .indexOf(searchCollectibleText?.toLowerCase().trim()) > -1
          );
        }
      }
      return x;
    });
    // Sorting logic with clear cases for different sort options.
    const sortedActivePassports = availableCollectibles?.sort((a, b) => {
      const textA = a?.collectionData?.name?.toUpperCase();
      const textB = b?.collectionData?.name?.toUpperCase();

      switch (selectedSortingOption) {
        case SortingOptions.ALPHABETICAL_ASC:
          return textA?.localeCompare(textB);
        case SortingOptions.ALPHABETICAL_DESC:
          return textB?.localeCompare(textA);
        case SortingOptions.COST_ASC:
          return a?.price - b?.price;
        case SortingOptions.COST_DESC:
          return b?.price - a?.price;
        default: // Default sort based on timestamps
          return;
      }
    });
    return sortedActivePassports;
  }, [collectiblesList, searchCollectibleText, selectedSortingOption]);

  const pullCollectibles = async () => {
    const subgraphConfig = await getSubgraphConfig();

    if (
      subgraphConfig &&
      subgraphConfig.modules &&
      subgraphConfig.modules.marketplaceListing
    ) {
      setSkip(1);
    } else {
      setSkip(0);
    }
    setTake(DEFAULT_PAGE_SIZE);
    await dispatch(setLoadingCollectible(true));
    await dispatch(clearCollectibles());
    await dispatch(
      loadPublicMarketPlaceData({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
        orderBy: 'created',
        order: 'DESC',
        skip: 0,
        take: take,
        isClear: true,
        isCollectibleData: true,
        searchByName: searchCollectibleText?.toLowerCase().trim(),
      }),
    );
    setIsLoading(false);
    await dispatch(
      loadPublicMarketPlaceData({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
        orderBy: 'created',
        order: 'DESC',
        skip: 1,
        take: take,
        isClear: true,
        isCollectibleData: true,
        searchByName: searchCollectibleText?.toLowerCase().trim(),
      }),
    );
  };

  // update collectible list based on filter selection.
  useEffect(() => {
    setIsLoading(true);
    pullCollectibles();
  }, [selectedSortingOption, navigation.isFocused()]);

  const onCollectibleSearch = async (searchText: string) => {
    setSearchCollectibleText(searchText);

    const subgraphConfig = await getSubgraphConfig();

    if (
      subgraphConfig &&
      subgraphConfig.modules &&
      subgraphConfig.modules.marketplaceListing
    ) {
      await dispatch(
        loadPublicMarketPlaceData({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
          orderBy: 'name',
          order: 'DESC',
          skip: 0,
          take: take,
          isClear: true,
          isCollectibleData: true,
          searchByName: searchText?.toLowerCase().trim(),
        }),
      );
    } else {
      dispatch(debounce(searchCollectible({ searchText }), 500));
    }
  };

  const pullOlderList = async () => {
    setListRetrivalStatus(ListRetrivalStatus.OLDER_LIST);
    await dispatch(setOlderListLoading(true));
    let nextPage = 0;
    let orderBy = 'created';
    const subgraphConfig = await getSubgraphConfig();

    if (
      subgraphConfig &&
      subgraphConfig.modules &&
      subgraphConfig.modules.marketplaceListing
    ) {
      nextPage = skip + 1;
      orderBy = 'name';
    } else {
      nextPage = skip + take;
    }

    setSkip(nextPage);
    await dispatch(
      loadPublicMarketPlaceData({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
        orderBy: 'name',
        order: 'DESC',
        skip: nextPage,
        take: take,
        isClear: false,
        isOlderMessages: true,
        isCollectibleData: true,
        searchByName: searchCollectibleText,
      }),
    );
  };

  const onPullOlderMarketPlaceList = async ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    // pull older list.
    if (layoutMeasurement.height + contentOffset.y >= contentSize.height - 20) {
      await pullOlderList();
    }
  };

  useEffect(() => {
    return () => {
      // clear data
      dispatch(setLoadingCollectible(true));
      dispatch(clearCollectibles());
    };
  }, []);

  const scrollLeft = () => {
    if (ScrollViewRef.current && Platform.OS === 'web') {
      ScrollViewRef.current.scrollTo({
        x: Math.max(scrollPosition - tileWidth, 0),
        animated: true,
      });
    }
  };

  const scrollRight = () => {
    if (ScrollViewRef.current && Platform.OS === 'web') {
      pullOlderList();
      ScrollViewRef.current.scrollTo({
        x: scrollPosition + tileWidth,
        animated: true,
      });
    }
  };
  const onScrollEvent = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (Platform.OS === 'web') {
        const xCoordinate = event?.nativeEvent?.contentOffset.x;
        setScrollPosition(xCoordinate);
      }
    },
    [],
  );

  return (
    <>
      {
        <View
          style={[
            { marginTop: 5, flex: 1 },
            isResponsive && { marginLeft: 5 },
          ]}>
          <FlatList
            id={'collectibles'}
            data={sortedCollectiblesList}
            keyExtractor={item => item?.id?.toString()}
            renderItem={({ item }) => (
              <CollectibleItem item={item} navigation={navigation} />
            )}
            numColumns={isResponsive ? 2 : 1}
            contentContainerStyle={{ flexGrow: 1, paddingBottom: 150 }}
            showsVerticalScrollIndicator={false}
            key={'collectibles' + isResponsive}
            ItemSeparatorComponent={() => (
              <View style={{ marginVertical: 5 }} />
            )}
            scrollEnabled
            showsHorizontalScrollIndicator={false}
            columnWrapperStyle={
              isResponsive && { justifyContent: 'space-between' }
            }
            scrollToOverflowEnabled
            overScrollMode={'always'}
            horizontal={false}
            style={{
              flex: 1,
              paddingTop: 10,
              borderRadius: defaultTheme.CONTENT_RADIUS,
            }}
            ListFooterComponent={() => (
              <>
                {!loadingCollectible &&
                !olderListLoading &&
                sortedCollectiblesList?.length == 0 ? (
                  <View style={{ marginTop: 20 }}>
                    <Text
                      style={{
                        alignSelf: 'center',
                        textAlign: 'center',
                        paddingVertical: 10,
                        fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                        fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                        color: '#FFFFFF',
                      }}>
                      {'No results found.'}
                    </Text>
                  </View>
                ) : (
                  (loadingCollectible ||
                    (sortedCollectiblesList?.length == 0 &&
                      searchCollectibleText == '')) && (
                    <View style={{ marginTop: 20 }}>
                      <AppLoaderComponent
                        deemBg={false}
                        loaderText={{
                          text: 'Loading Digital Collectibles for Sale',
                        }}
                        size={'small'}
                        extraStyle={{
                          position: 'relative',
                        }}></AppLoaderComponent>
                    </View>
                  )
                )}
                {initialListLoading &&
                  !(
                    loadingCollectible ||
                    (sortedCollectiblesList?.length == 0 &&
                      searchCollectibleText == '')
                  ) && (
                    // (olderListLoading && !loadingCollectible && (sortedCollectiblesList?.length > 0 && searchCollectibleText == '' && listRetrivalStatus == ListRetrivalStatus.OLDER_LIST)) &&
                    <View style={{ marginTop: 20 }}>
                      <ActivityIndicator
                        size="small"
                        color={defaultTheme.PRIMARY_TEXT_COLOR}
                      />
                    </View>
                  )}
              </>
            )}
            // TODO: Improve the Pagination logic here
            onScroll={({ nativeEvent }) => {
              if (collectiblesList?.length > 0 && initialListLoading) {
                onPullOlderMarketPlaceList(nativeEvent);
              }
            }}
          />
        </View>
      }
      {Platform.OS === 'web' && hovered && (
        <>
          <View style={{ position: 'absolute', top: '50%', left: 0 }}>
            <Pressable
              onHoverIn={() => setHovered(true)}
              style={{ backgroundColor: '#FFFFFFE0', borderRadius: 20 }}
              onPress={scrollLeft}>
              <MaterialCommunityIcons
                name={'chevron-left'}
                size={40}
                color="#000000"
              />
            </Pressable>
          </View>
          <View style={{ position: 'absolute', top: '50%', right: 0 }}>
            <Pressable
              onHoverIn={() => setHovered(true)}
              style={{ backgroundColor: '#FFFFFFE0', borderRadius: 20 }}
              onPress={scrollRight}>
              <MaterialCommunityIcons
                name={'chevron-right'}
                size={40}
                color="#000000"
              />
            </Pressable>
          </View>
        </>
      )}
    </>
  );
};

export default MPCollectibles;
