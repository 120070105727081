import React from 'react';
import { Platform, View, Text, StyleSheet } from 'react-native';
import Constants from 'expo-constants';
import WebView from 'react-native-webview';
import * as FileSystem from 'expo-file-system';
import { Image, ImageContentFit } from 'expo-image';

import { getIPNSLink, isValidIpfsUrl } from '../../helpers/ipfs';
import { webViewHelper } from '../../helpers/Gadgets';
import { CatalogTypes } from '../../enums/catalog.enum';
import { LogCustomError } from '../../helpers/AppLogger';
import CustomImageBackground from '../CustomImageBackground';
import { CollectionType } from '../../enums/collection.enum';
import { imagesDir, pushToGIFFileList } from '../../appconstants';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { defaultTheme } from '../../themes/loot8';

type CachedImageProps = {
  source: {
    uri: string;
    cache?: string;
  };
  type?: CatalogTypes | CollectionType;
  item?: any;

  isBackground: boolean;
  isAnimationUrl?: boolean;
  isThumbnailImage?: boolean;
  isOptimizedImage?: boolean;
  isResponsive?: boolean;

  imageSize?: Number;
  optimizedImageSize?: Number;
  thumbnailImageSize?: Number;

  hovered?: boolean;
  noImageOnError?: boolean;
  noImageFile?: string;

  style?: any;
  imageStyle?: any;
  contentFit: ImageContentFit | undefined;

  children?: any;
};

export default class CachedImage extends React.Component<CachedImageProps> {
  mounted = true;
  state = {
    imgURI: '',
    brokenImageURI: null,
  };
  _interaction = null;
  downloadResumable = null;

  constructor(props) {
    super(props);
    this.state = {
      imgURI: '',
      brokenImageURI: null,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {
    if (prevProps.source.uri !== this.props.source.uri) {
      this.setState({ imgURI: this.props.source.uri });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    // Clean up resources if needed
  }

  errorOnLoadingImage = error => {
    if (!this.props.noImageOnError) {
      const brokenImageURI =
        getIPNSLink(Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY) +
        '/' +
        (this.props.noImageFile || 'no-image.png');
      this.setState({
        brokenImageURI,
      });
    }
    LogCustomError('ERROR-RENDER-IMAGE', error, this.state.imgURI, null);
  };

  render() {
    let source: any = this.state.imgURI ? { uri: this.state.imgURI } : null;
    if (!source && this.props.source) {
      // const loadingIndicatorSource = {
      //   uri: Platform.OS === 'web' ? '/loading.gif' : Image.resolveAssetSource(loadingImage).uri
      // }
      source = { ...this.props.source, cache: 'force-cache' };
    }

    if (
      this.props?.isAnimationUrl &&
      this.props?.type === CollectionType.COLLECTION
    ) {
      const isValidImage = isValidIpfsUrl(this.props?.item?.image);

      return (
        <CustomImageBackground
          {...this.props}
          style={{
            ...this.props?.style,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            borderRadius: 10,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            overflow: 'hidden',
          }}
          source={
            isValidImage
              ? { uri: this.props?.item?.image, cache: 'force-cache' }
              : undefined
          }
          hovered={this.props.hovered}>
          {!isValidImage ? (
            this.props.item?.details?.includes('streamyard') ? (
              <View style={streamyardStyles.container}>
                <Text style={streamyardStyles.live}>Live</Text>
                <Text style={streamyardStyles.streamYardLink}>STREAM YARD</Text>
              </View>
            ) : (
              <View
                style={{
                  backgroundColor: '#0009',
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  borderRadius: 5,
                }}>
                <Text
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}>
                  {this.props.item.name}
                </Text>
              </View>
            )
          ) : null}
        </CustomImageBackground>
      );
    } else if (this.props.isAnimationUrl) {
      return (
        <WebView
          nestedScrollEnabled
          style={{
            width: this.props?.style.maxWidth,
            height: this.props?.style.minHeight,
            ...this.props?.imageStyle,
            ...this.props?.style,
          }}
          source={this.props.source}
          javaScriptEnabledAndroid
          onMessage={event => {
            const data = event?.nativeEvent?.data;
            if (data && data.indexOf && data.indexOf('loot8') > -1) {
              webViewHelper(event.nativeEvent.data);
            }
          }}
          androidLayerType={'software'}
        />
      );
    } else if (this.props.isBackground) {
      return (
        <CustomImageBackground
          {...this.props}
          source={
            !this.state.brokenImageURI
              ? source
              : { uri: this.state.brokenImageURI }
          }
          hovered={this.props.hovered}>
          {this.props.children}
        </CustomImageBackground>
      );
    } else {
      return (
        <Image
          {...this.props}
          source={
            this.state.brokenImageURI
              ? { uri: this.state.brokenImageURI }
              : source
          }
          style={{
            width: this.props?.style.maxWidth,
            height: this.props?.style.minHeight,
            ...this.props?.imageStyle,
            ...this.props?.style,
          }}
          onError={({ error }) => {
            this.errorOnLoadingImage(error);
          }}
        />
      );
    }
  }
}

export async function deleteAllCachedImages() {
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    pushToGIFFileList([]);
    const dirInfo = await FileSystem.getInfoAsync(imagesDir);
    if (dirInfo.exists) {
      LogToLoot8Console('Deleting all Cached Images files...');
      await FileSystem.deleteAsync(imagesDir);
    }
  }
}

const streamyardStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  live: {
    color: '#EE6161',
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    letterSpacing: 2,
  },
  streamYardLink: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    color: '#fff',
  },
});
