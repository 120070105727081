import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  addresses,
  APP_STORAGE_TX_TIMESTAMPS,
  CASHOUT_EST_COMPLETION_TIME_IN_SECS,
  CASHOUT_TX_TIMESTAMP_EXPIRY_IN_MS,
  EXPO_LOOT8_PROJECT_ID,
  getAnynetStaticProvider,
  getAppConfiguration,
  getSubgraphConfig,
  LAST_BLOCK_NUMBER,
  LAST_TIMESTAMP,
  OFFRAMP_LAMBDA_URL_DEV,
  OFFRAMP_LAMBDA_URL_PROD,
  OFFRAMP_LAMBDA_URL_STAGING,
  timeout,
} from '../appconstants';
import {
  GetCreateUserMessage,
  GetDeleteUserMessage,
  GetUserAvatarMessage,
  GetUserCreatedLogData,
  GetUserNameMessage,
} from '../helpers/MsgHelper';
import { TransactionDetail } from '../interfaces/IOffer.interface';
import { RootState } from '../store';
import {
  Loot8FiatOffRamp__factory,
  Loot8Token__factory,
  User__factory,
} from '../typechain/factories';
import { SendMetaTX } from './AppSlice';
import { setAll } from './helpers';
import { IIPNSUserDetailType, IUser } from '../interfaces/IUser.interface';
import {
  IUserBaseAsyncThunk,
  IMessageMetaData,
  IUserSetStatusBaseAsyncThunk,
  IUserSetUserNamesBaseAsyncThunk,
  IUserCreateBaseAsyncThunk,
  IUserSetAvatarBaseAsyncThunk,
  IWalletBaseAsyncThunk,
} from './interfaces';
import {
  getUserDetailsFromIPFS,
  uploadUserData,
  getUserIPNSURL,
  publishMessageToIPFSTopic,
  uploadUserDetailsToMFS,
  uploadUserStatusMFS,
  getIPNSData,
  getUserJSONdata,
  retryFetch,
} from '../helpers/ipfs';
import { getData, storeData } from '../helpers/AppStorage';
import {
  loadAllPassporDetails,
  loadAvailablePassporDetails,
  loadMyPassportDetails,
  setInitialPassportLoaded,
  setPassportLoading,
} from './PassportSlice';
import {
  getUnsubsribedNotifications,
  processFriendRequestNotifications,
  setLastBlockNumber,
  setLastTimestamp,
  setUserWalletAddress,
  subscribeToEvents,
} from './NotificationSlice';
import { loadEntityDetails } from './EntitySlice';
import { showToastMessage as showToastMessage } from '../helpers/Gadgets';
import {
  LogCustomError,
  LogErrors,
  LogInformation,
} from '../helpers/AppLogger';
import { loadAllCatList, reloadCatPassportList } from './CatalogSlice';
import {
  getUsers,
  loadFriendsData,
  processInitialFriendsLoadPromise,
  subscribeToFriendsStatus,
} from './friendsSlice';
import { loadOrderDetail } from './OrderSlice';
import {
  loadAllDigitalCollectible,
  // loadAvailableDigitalCollectibleDetails,
} from './DigitalCollectibleSlice';
import { Wallet } from 'ethers';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import {
  getPrivateMsgPendingRequestForUser,
  loadLastMessageDetailsForMutualFriends,
} from './PrivateMessageSlice';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import {
  deleteNativeNotificationToken,
  setNativeNotificationToken,
  updateFriendsOnLambda,
} from '../helpers/Messages';
import { Platform } from 'react-native';
import { getAllUsersDataFromQuery } from '../helpers/QueryHelper';
import { hexlify } from 'ethers/lib/utils';
import { parse } from 'uuid';
import { AppEnvironment } from '../enums/env.enum';
import { OperatorType } from '../enums/tickets.enum';

export let publicKeys: { wallet: string; publicKey: string }[] = [];
export let thirdPartyVerifiedURLs: {
  address: string;
  thirdPartyURL: string[];
}[] = [];

export const pushUserPublicKey = (wallet: string, publicKey: string) => {
  if (!publicKeys.find(p => p.wallet == wallet)) {
    publicKeys.push({
      wallet,
      publicKey,
    });
  }
};

let _deviceExpoPushToken = '';
export const getDeviceExpoPushtoken = () => _deviceExpoPushToken;

export const getAllUsersData = createAsyncThunk(
  'appuser/AllUsersData',
  async (
    { networkID, provider }: { networkID; provider },
    { dispatch },
  ): Promise<any> => {
    const userContract = User__factory.connect(
      addresses[networkID].User,
      provider,
    );
    let usersData = [];
    let enableQueryHelper = false;
    try {
      const subgraphConfig = await getSubgraphConfig();

      if (
        subgraphConfig &&
        subgraphConfig.modules &&
        subgraphConfig.modules.user
      ) {
        usersData = await getAllUsersDataFromQuery();
      } else {
        usersData = await userContract.getAllUsers(false);
      }
      // LogToLoot8Console(users);
    } catch (e) {
      LogToLoot8Console('Error - getUsers:');
      LogToLoot8Console(e);
      LogCustomError('getUsers', e.name, e.message, e.stack);
    }

    await dispatch(setAllUserData({ AllUsersData: usersData }));
    return usersData;
  },
);

export const IsUserExists = async ({
  networkID,
  provider,
  address,
}: {
  networkID;
  provider;
  address;
}): Promise<any> => {
  const userFactory = User__factory.connect(
    addresses[networkID].User,
    provider,
  );
  const userAttributes = await userFactory.userAttributes(address);
  // const isExists = Number(userAttributes.id) != 0;
  return { userAttributes };
};

export const getUserStatus = createAsyncThunk(
  'appuser/getUserStatus',
  async (
    {
      networkID,
      provider,
      address,
      userAttributesData,
    }: { networkID; provider; address; userAttributesData },
    { getState },
  ): Promise<any> => {
    let userStoredStatus = await getData('@myStatus');
    if (userStoredStatus) return { status: userStoredStatus };
    try {
      if (
        userAttributesData.userAttributes &&
        userAttributesData.userAttributes?.wallet != ''
      ) {
        const userJsonData = await getUserJSONdata(
          userAttributesData?.userAttributes?.wallet,
        );
        if (userJsonData && userJsonData?.status) {
          userStoredStatus = userJsonData?.status ?? '';
        }
      }
    } catch (error) {
      LogCustomError('getUserStatus', error.name, error.message, error.stack);
    }
    return {
      status: userStoredStatus ?? '',
    };
  },
);

export const getUserAvatar = async (userStoredAvatar): Promise<any> => {
  //let userStoredStatus = await getData('@myStatus');
  //if (userStoredStatus) return userStoredStatus;
  // let userStoredAvatar = userAttributesData.userAttributes.avatarURI;

  if (userStoredAvatar && userStoredAvatar.includes('ipfs://')) {
    await getUserDetailsFromIPFS(userStoredAvatar).then(uri => {
      userStoredAvatar = uri;
    });
  }

  return userStoredAvatar ?? '';
};

export const storeUserDetailsToMFS = async (
  userDetails: IIPNSUserDetailType,
  wallet: Wallet,
) => {
  uploadUserDetailsToMFS(userDetails, wallet);
};

const getAndStoreNotificationToken = async (rootState, wallet) => {
  if (rootState.App.nativeNotificationPermitted) {
    try {
      //get latest notification token from Expo
      let notificationToken = (
        await Notifications.getExpoPushTokenAsync({
          projectId: EXPO_LOOT8_PROJECT_ID,
        })
      ).data;
      if (notificationToken && notificationToken != '') {
        _deviceExpoPushToken = notificationToken;
        //store latest token thorough lambda
        await setNativeNotificationToken(notificationToken, wallet);
      }
    } catch (err) {
      LogToLoot8Console(
        'getAndStoreNotificationToken: Error while fetching native notification token ',
        err,
      );
      LogCustomError(
        'getAndStoreNotificationToken: Error while fetching native notification token',
        err.name,
        err.message,
        err.stack,
      );
    }
  } else {
    try {
      deleteNativeNotificationToken(wallet);
    } catch (err) {
      LogToLoot8Console(
        'getAndStoreNotificationToken: Error while removing native notification token ',
        err,
      );
      LogCustomError(
        'getAndStoreNotificationToken: Error while removing native notification token',
        err.name,
        err.message,
        err.stack,
      );
    }
  }
};

export const loadUserDetail = createAsyncThunk(
  'appuser/checkUser',
  async (
    {
      networkID,
      provider,
      address,
      userInfo,
      wallet,
      userLocation,
      decryptMessage,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    try {
      const userFactory = User__factory.connect(
        addresses[networkID].User,
        provider,
      );
      const bartenderAt = await userFactory.getUserAdminList(
        address,
        OperatorType.GATEKEEPER,
      );
      if (bartenderAt && bartenderAt?.length > 0) {
        dispatch(setGateKeeperAt(bartenderAt));
      }
    } catch (error) {
      LogCustomError(
        '~ Failed to load bartenderAt for user-' + address,
        error.name,
        error.message,
        error.stack,
      );
    }

    let userAttributesData = await IsUserExists({
      networkID,
      provider,
      address,
    });
    let id = parseInt(userAttributesData.userAttributes.id, 16);
    let userStoredStatus = '';
    let userStoredAvatarUri = '';
    let thirdPartyVerifiedURL = [];

    if (id != 0) {
      /*
      check for user json data from MFS to get status, friends and public Key.
      if no data is available then upload blank friends.json to get from MFS.
      This will execute for new user and in case for any existing user does not have user json data in MFS
    */
      if (
        userAttributesData &&
        userAttributesData.userAttributes &&
        userAttributesData.userAttributes.wallet
      ) {
        const userJsonData = await getUserJSONdata(
          userAttributesData?.userAttributes?.wallet,
        );
        if (!userJsonData) {
          getUserIPNSURL(wallet).then(async response => {
            if (response) {
              storeUserDetailsToMFS(
                {
                  publicKey: wallet.publicKey,
                },
                wallet,
              );
            }
          });
        }
      }
      userStoredAvatarUri = await getUserAvatar(
        userAttributesData.userAttributes.avatarURI ?? '',
      );
      thirdPartyVerifiedURL = await getThirdPartyVerifiedURL({
        networkID,
        provider,
        address,
      });

      dispatch(
        getUserStatus({ networkID, provider, address, userAttributesData }),
      );
      let initialFriendsResolve = processInitialFriendsLoadPromise();
      dispatch(
        loadFriendsData({
          networkID,
          provider,
          wallet: wallet,
          address: address,
        }),
      ).then(() => {
        initialFriendsResolve(true);
        dispatch(
          getPrivateMsgPendingRequestForUser({
            networkID,
            provider,
            address: address,
            wallet: wallet,
            timestamp: null,
            decryptMessage: decryptMessage,
            publicKey: null,
            directionType: null,
          }),
        ).then(action => {
          processFriendRequestNotifications(
            address,
            action?.payload?.pendingRequest,
            false,
            dispatch,
          );
        });
      });

      dispatch(
        loadUserAllPageDetails({
          networkID,
          provider,
          address,
          userInfo,
          wallet,
          userLocation,
        }),
      );
      dispatch(setUserWalletAddress(address));
      if (
        userAttributesData.userAttributes &&
        userAttributesData.userAttributes?.wallet != ''
      ) {
        getUserJSONdata(userAttributesData?.userAttributes?.wallet)
          .then(async response => {
            if (!response?.details?.publicKey) {
              storeUserDetailsToMFS({ publicKey: wallet.publicKey }, wallet);
            }
          })
          .catch(p =>
            storeUserDetailsToMFS({ publicKey: wallet.publicKey }, wallet),
          );
      }

      if (Device.isDevice && Platform.OS !== 'web') {
        const state = getState() as RootState;
        getAndStoreNotificationToken(state, wallet);
      }
    }
    return {
      UserData: {
        ...userAttributesData.userAttributes,
        isExist: id != 0,
        avatarURI: userStoredAvatarUri,
        thirdPartyVerifiedURL:
          thirdPartyVerifiedURL?.length > 0 ? thirdPartyVerifiedURL : null,
      },
    };
  },
);

export const CreateUserDetail = createAsyncThunk(
  'appuser/CreateUserDetail',
  async (
    {
      networkID,
      provider,
      address,
      userInfo,
      wallet,
      userStatus,
      userName,
      userAvatarURI,
      userLocation,
      decryptMessage,
    }: any,
    { dispatch },
  ): Promise<any> => {
    const userExist = await IsUserExists({ networkID, provider, address });
    // * Check if User already exist
    // ? If not then create user otherwise load user details
    if (parseInt(userExist.userAttributes.id, 16) === 0) {
      await dispatch(
        CreateUser({
          networkID,
          provider,
          address,
          userInfo,
          wallet,
          userStatus,
          userName,
          userAvatarURI,
        }),
      );
    }

    await timeout(1000);
    await dispatch(
      loadUserDetail({
        networkID,
        provider,
        address,
        userInfo,
        wallet,
        userLocation,
        decryptMessage: decryptMessage,
      }),
    );
  },
);

export const loadUserBalance = createAsyncThunk(
  'appuser/userBalance',
  async ({ networkID, provider, address }: any, { dispatch }): Promise<any> => {
    const walletBalance = Loot8Token__factory.connect(
      addresses[networkID].DAOERC20,
      provider,
    );
    const bal = Number(await walletBalance.balanceOf(address)) / 1e18;
    return {
      UserWalletBalance: bal,
    };
  },
);

export const loadUserAllPageDetails = createAsyncThunk(
  'appuser/loadUserAllPageDetails',
  async (
    {
      networkID,
      provider,
      address,
      userInfo,
      wallet,
      userLocation,
    }: IUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    await dispatch(setPassportLoading());
    dispatch(getAllUsersData({ networkID, provider }));
    await dispatch(
      loadEntityDetails({ networkID, provider, address, wallet: wallet }),
    );
    await dispatch(
      loadMyPassportDetails({
        networkID,
        provider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation,
      }),
    );
    dispatch(
      reloadCatPassportList({ networkID, provider, address, wallet, userInfo }),
    );
    await dispatch(
      loadAllPassporDetails({
        networkID,
        provider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation,
      }),
    );

    await dispatch(setInitialPassportLoaded(false));
    await dispatch(
      loadAvailablePassporDetails({
        networkID,
        provider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation,
      }),
    );
    await dispatch(setInitialPassportLoaded(true));

    await dispatch(
      loadAllDigitalCollectible({
        networkID,
        provider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation,
      }),
    );
    // await dispatch(
    //   loadAvailableDigitalCollectibleDetails({
    //     networkID,
    //     provider,
    //     address,
    //     wallet,
    //     entities: [],
    //     userInfo,
    //     userLocation,
    //   }),
    // );

    dispatch(
      loadUserBalance({ networkID, provider, address, userInfo, wallet }),
    );
    dispatch(
      loadAllCatList({
        networkID,
        provider,
        address,
        wallet,
        userInfo,
        loadPassportList: false,
      }),
    );

    dispatch(
      getExternalLinkedAccounts({ networkID, provider, address, wallet }),
    ).then(() => {
      dispatch(initialExtAccLoaded(true));
    });

    const lastBlockNumber = (await getData(LAST_BLOCK_NUMBER)) || 0;
    const lastTimeStamp = (await getData(LAST_TIMESTAMP)) || 0;
    await dispatch(setLastBlockNumber(lastBlockNumber));
    await dispatch(setLastTimestamp(lastTimeStamp));
    dispatch(
      subscribeToFriendsStatus({
        networkID,
        provider,
        address: address,
        wallet: wallet,
      }),
    );
    dispatch(subscribeToEvents({ networkID, provider, address: address }));
    dispatch(
      getUnsubsribedNotifications({
        networkID,
        provider,
        address: address,
        wallet: wallet,
      }),
    );
    dispatch(loadOrderDetail({ networkID, provider, address, wallet }));

    // load unread messages
    const state = getState() as RootState;
    const mutualFriendsData = state.friends.mutualFriends;
    const latestMsgListLoaded = state.PrivateMessage.latestMsgListLoaded;
    if (!latestMsgListLoaded) {
      let friendsAddresses = mutualFriendsData.map(data => {
        return { friendAddress: data.wallet, dataURI: data?.dataURI ?? '' };
      });
      dispatch(
        loadLastMessageDetailsForMutualFriends({
          networkID,
          provider,
          address: null,
          wallet: wallet,
          friendsAddresses: friendsAddresses,
        }),
      );
    }
  },
);

export const setUserAvatar = createAsyncThunk(
  'appuser/setAvatar',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      newAvatarUri,
    }: IUserSetAvatarBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    //post new avatar URI to IPFS
    const ipfsURI = await uploadUserData(newAvatarUri, wallet);
    //LogToLoot8Console(ipfsURI);
    if (ipfsURI) {
      //update new IPFS Avatar URI [CID] in contract
      const avatarData = GetUserAvatarMessage(ipfsURI);
      if (address) {
        let msg: IMessageMetaData = {
          to: addresses[networkID].User,
          wallet: wallet,
          data: avatarData,
          networkID: networkID,
          provider: provider,
        };
        await dispatch(SendMetaTX(msg));
      }

      const state = getState() as RootState;
      let userData = { ...state.AppUser.UserData, avatarURI: newAvatarUri };

      return {
        UserData: userData,
      };
    }
    // });
  },
);

export const setUserStatus = createAsyncThunk(
  'appuser/setStatus',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      newStatus,
    }: IUserSetStatusBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const signature = await wallet.signMessage(newStatus);

    publishMessageToIPFSTopic(
      'loot8-friends-status-changes',
      wallet.address,
      newStatus,
      signature,
    );

    await storeData('@myStatus', newStatus);

    const state = getState() as RootState;
    let userData = { ...state.AppUser.UserData, status: newStatus };

    uploadUserStatusMFS(wallet, newStatus);

    return {
      UserData: userData,
    };
  },
);

export const setUserName = createAsyncThunk(
  'appuser/setUserName',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      newUserName,
    }: IUserSetUserNamesBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const data = GetUserNameMessage(newUserName);
    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].User,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      await dispatch(SendMetaTX(msg));
    }

    const state = getState() as RootState;
    let userData = { ...state.AppUser.UserData, name: newUserName };

    const signature = await wallet.signMessage(newUserName);
    publishMessageToIPFSTopic(
      'loot8-friends-name-changes',
      wallet.address,
      newUserName,
      signature,
    );

    return {
      UserData: userData,
    };
  },
);

export const CreateUser = createAsyncThunk(
  'appuser/createUser',
  async (
    {
      networkID,
      provider,
      address,
      userInfo,
      wallet,
      userStatus,
      userName,
      userAvatarURI,
    }: IUserCreateBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    //upload user avatar URI to IPFS and get IPFS URL
    let selectedAvatarURI = '';
    if (userAvatarURI != '') {
      await uploadUserData(userAvatarURI, wallet).then(async (ipfsURI: any) => {
        //LogToLoot8Console(ipfsURI);
        selectedAvatarURI = ipfsURI;
      });
    }
    //LogToLoot8Console('selected URI');
    //LogToLoot8Console(selectedAvatarURI);
    //LogToLoot8Console(userInfo.profileImage);
    let name = userName != '' ? userName : userInfo.name;
    let avatarURI =
      selectedAvatarURI != '' ? selectedAvatarURI : userInfo.profileImage;

    const data = GetCreateUserMessage(name, avatarURI, '');
    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].OrderDispatcher,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
        checkUserDetail: false,
      };
      LogToLoot8Console(msg);
      let res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        const createdUserID = GetUserCreatedLogData(res.payload?.eventLogs);
        if (createdUserID === 0) {
          //Fix for bug Loot8-679: Check if user already created before reinitiating user creation transaction to avoid duplicate create user transactions.
          await timeout(1000);
          let userAttributesData = await IsUserExists({
            networkID,
            provider,
            address,
          });
          let newUserId = 0;
          if (userAttributesData && userAttributesData.userAttributes) {
            newUserId = parseInt(userAttributesData.userAttributes.id, 16);
          }
          if (newUserId === 0) {
            LogToLoot8Console('reinitiating user creation...');
            // logs transaction detail in sentry in case user creation is failed in first attempt.
            LogErrors(
              'USER REGISTRATION FAILED ON FIRST ATTEMPT',
              wallet.address,
              networkID,
              [
                { tag: 'from', value: wallet.address },
                { tag: 'to', value: addresses[networkID].User },
                {
                  tag: 'autotaskId',
                  value: res?.payload?.response?.autotaskId,
                },
                {
                  tag: 'autotaskRunId',
                  value: res?.payload?.response?.autotaskRunId,
                },
                { tag: 'requestId', value: res?.payload?.response?.requestId },
                { tag: 'result', value: res?.payload?.response?.result },
              ],
            );
            let userCreationResponse = await dispatch(SendMetaTX(msg));
            if (
              userCreationResponse &&
              userCreationResponse.payload?.eventLogs
            ) {
              const createdUserID = GetUserCreatedLogData(
                userCreationResponse.payload?.eventLogs,
              );
              if (createdUserID === 0) {
                // logs transaction detail in sentry in case user creation is failed in second attempt.
                LogErrors(
                  'USER REGISTRATION FAILED ON SECOND ATTEMPT',
                  wallet.address,
                  networkID,
                  [
                    { tag: 'from', value: wallet.address },
                    { tag: 'to', value: addresses[networkID].User },
                    {
                      tag: 'autotaskId',
                      value:
                        userCreationResponse?.payload?.response?.autotaskId,
                    },
                    {
                      tag: 'autotaskRunId',
                      value:
                        userCreationResponse?.payload?.response?.autotaskRunId,
                    },
                    {
                      tag: 'requestId',
                      value: userCreationResponse?.payload?.response?.requestId,
                    },
                    {
                      tag: 'result',
                      value: userCreationResponse?.payload?.response?.result,
                    },
                  ],
                );
              }
            }
          }
        }
      }
    }

    //Store user status to MFS & local storage
    dispatch(
      setUserStatus({
        networkID,
        provider,
        address,
        wallet,
        newStatus: userStatus,
      }),
    );
  },
);

export const DeleteUser = createAsyncThunk(
  'appuser/DeleteUser',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      userFriends,
    }: IWalletBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    // uploadFriendsDataToMFS(wallet, { friends: [] });
    // LOOT8-5651 Adding this function to avoid uploading friends data directly with uploadFriendsDataToMFS
    userFriends = userFriends.length > 0 ? userFriends.map(f => f.wallet) : [];
    await updateFriendsOnLambda(userFriends, wallet, 'remove');
    uploadUserStatusMFS(wallet, ' ');

    const data = GetDeleteUserMessage();
    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].User,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      await dispatch(SendMetaTX(msg));
    }

    return;
  },
);

export const getExternalLinkedAccounts = createAsyncThunk(
  'appuser/getExternalLinkedAccounts',
  async ({
    networkID,
    provider,
    address,
  }: IWalletBaseAsyncThunk): Promise<string[]> => {
    const userFactory = User__factory.connect(
      addresses[networkID].User,
      provider,
    );
    const linkedAccounts = await userFactory.getLinkedAccountForUser(address);
    return linkedAccounts.map(p => p.account);
  },
);

export const getUserPublicKey = async (address): Promise<any> => {
  //get public key for decrypting message on UI
  let friendPublicKey = null;
  try {
    if (address) {
      const localPubKey = publicKeys.find(
        p => p.wallet.toLowerCase() == address.toLowerCase(),
      );
      if (localPubKey) {
        friendPublicKey = localPubKey.publicKey;
      } else {
        let friendData: any = (await getUserJSONdata(address))?.details;
        // if (!friendData) {
        //   friendData = await getUserJSONdataFromFile(userIPNSURI, USERDETAIL_IPNS_FILE_NAME);
        // }
        if (friendData) {
          if (friendData && friendData?.publicKey) {
            friendPublicKey = friendData?.publicKey;
            pushUserPublicKey(address, friendPublicKey);
          }
        }
      }
    }
  } catch (e) {
    LogCustomError('coudnt load public key', e.name, e.message, e.stack);
  }
  return friendPublicKey;
};

export const pushThirdPartyVerifiedURLs = (
  address: string,
  thirdPartyURL: string[],
) => {
  if (
    !thirdPartyVerifiedURLs.find(
      p => p.address?.toLocaleLowerCase() == address?.toLocaleLowerCase(),
    )
  ) {
    thirdPartyVerifiedURLs.push({
      address,
      thirdPartyURL,
    });
  }
};

export const getThirdPartyVerifiedURL = async ({
  networkID,
  provider,
  address,
}: {
  networkID;
  provider;
  address;
}): Promise<any> => {
  let thirdPartyURL = [];
  try {
    const localThirdPartyVerifiedURLs = thirdPartyVerifiedURLs.find(
      p => p.address?.toLocaleLowerCase() == address?.toLocaleLowerCase(),
    );
    if (localThirdPartyVerifiedURLs) {
      thirdPartyURL = localThirdPartyVerifiedURLs?.thirdPartyURL;
    } else {
      const userFactory = User__factory.connect(
        addresses[networkID].User,
        provider,
      );

      thirdPartyURL = await userFactory.getThirdPartyVerifiedProfileUrl(
        address,
      );
      if (thirdPartyURL?.length > 0) {
        thirdPartyURL.forEach(p => {
          pushThirdPartyVerifiedURLs(address, p);
        });
      }
    }
  } catch (e) {
    LogCustomError(
      "getThirdPartyVerifiedURL | coudn't load collection owner",
      e.name,
      e.message,
      e.stack,
    );
  }
  return thirdPartyURL;
};

export const getUserTXHistory = createAsyncThunk(
  'appuser/getUserTXHistory',
  async ({ address, networkId }: any): Promise<any> => {
    const appConfig = await getAppConfiguration();
    let TXHISTORY_LAMBDA_URL = '';
    if (appConfig.env === AppEnvironment.DEVELOPMENT) {
      TXHISTORY_LAMBDA_URL = OFFRAMP_LAMBDA_URL_DEV();
    } else if (appConfig.env === AppEnvironment.PRODUCTION) {
      TXHISTORY_LAMBDA_URL = OFFRAMP_LAMBDA_URL_PROD();
    } else if (appConfig.env === AppEnvironment.STAGING) {
      TXHISTORY_LAMBDA_URL = OFFRAMP_LAMBDA_URL_STAGING();
    } else {
      TXHISTORY_LAMBDA_URL = OFFRAMP_LAMBDA_URL_DEV();
    }
    const getTxHistoryURLPath =
      TXHISTORY_LAMBDA_URL + 'api/v1/payments/paypal/user/' + address;
    let payments;
    const combinedArray = [];
    const offRampContract = await Loot8FiatOffRamp__factory.connect(
      addresses[networkId].OffRamp,
      getAnynetStaticProvider(networkId),
    );
    const { _hex: nonceHex } = await offRampContract.nonce(address);
    if (nonceHex) {
      payments = retryFetch(() =>
        fetch(getTxHistoryURLPath, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }).then(async res => {
          if (res.status == 200) {
            const data = JSON.parse(await res.text()).data;
            // Iterate through the payments array
            for (const payment of data.payments) {
              // Find the corresponding event based on requestId and senderItemId
              const correspondingEvent = data.events.find(
                event => event.requestId === payment.senderItemId,
              );
              // If a corresponding event is found, add the "amount" key to the payment object
              if (correspondingEvent) {
                const combinedObject = {
                  ...payment, // Copy keys from the payment object
                  amount: correspondingEvent.amount, // Add the "amount" key from the event
                  transactionHash: Buffer.from(
                    correspondingEvent.transactionHash.data,
                  ).toString('hex'), // Add the "transactionHash" key from the event
                  platform:
                    correspondingEvent.platform === 0 ? 'paypal' : 'venmo', // Add the "platform" key from the event
                  nonce: parseInt(correspondingEvent.nonce), // Add the "platform" key from the event
                  requestId: correspondingEvent.requestId, // Add the "requestId" key from the event
                  blockTimestamp:
                    parseInt(correspondingEvent.blockTimestamp) * 1000, // Add the "blockTimestamp" key from the event
                  estCompletionTime:
                    (parseInt(correspondingEvent.blockTimestamp) +
                      (correspondingEvent?.estimatedTimeToPaymentService ||
                        CASHOUT_EST_COMPLETION_TIME_IN_SECS)) *
                    1000, // estCompletionTime hardcoded to 1 hour
                };

                // Push the combined object to the new array
                combinedArray.push(combinedObject);
              }
            }
            const sortedArray = [...combinedArray].sort(
              (a, b) => new Date(b.updatedOn) - new Date(a.updatedOn),
            );
            // Initialize maxNonce with a value lower than any possible nonce
            let maxNonceFromLambda = -1;

            // Loop through the array and update maxNonce if a higher nonce is found
            sortedArray.forEach(obj => {
              if (obj.nonce > maxNonceFromLambda) {
                maxNonceFromLambda = obj.nonce;
              }
            });
            if (maxNonceFromLambda === parseInt(nonceHex)) {
              return sortedArray;
            } else {
              // Handle the cached tx timestamps expiry
              const storedTxData = await getData(APP_STORAGE_TX_TIMESTAMPS);
              if (storedTxData && storedTxData?.length > 0) {
                const validTxData = storedTxData.filter((tx: any) => {
                  let nowMs = Date.now();
                  let timestampMs = tx.timestamp * 1000;
                  let diffMs = nowMs - timestampMs;

                  return diffMs < CASHOUT_TX_TIMESTAMP_EXPIRY_IN_MS;
                });

                if (storedTxData.length !== validTxData.length) {
                  // Update the local storage
                  await storeData(APP_STORAGE_TX_TIMESTAMPS, validTxData);
                }
              }

              let lambdaChainCombinedArray = [...sortedArray];
              // Create an array from the range [min, max)
              const rangeArray = Array.from(
                { length: parseInt(nonceHex) - 1 - maxNonceFromLambda },
                (_, index) => index + 1 + maxNonceFromLambda,
              );
              await Promise.all(
                rangeArray.map(async x => {
                  const currentRequest = await offRampContract.requests(
                    address,
                    x,
                  );

                  // For All transactions that come up here, we need to match Request IDs and assign the timestamp
                  const storedTxData = await getData(APP_STORAGE_TX_TIMESTAMPS);
                  let timestamp = undefined;
                  if (storedTxData && storedTxData?.length > 0) {
                    timestamp = storedTxData.find(
                      (obj: any) => obj.requestId === currentRequest[1],
                    )?.timestamp;
                  }

                  if (currentRequest) {
                    const combinedObject = {
                      completionTxHash:
                        currentRequest[0] === 1
                          ? 'completed, data from chain, tx hash will be available after lambda is updated'
                          : null,
                      failureTxHash:
                        currentRequest[0] === 2
                          ? 'failed, data from chain, tx hash will be available after lambda is updated'
                          : null,
                      amount: parseInt(currentRequest[2]._hex),
                      nonce: x,
                      platform: 'paypal',
                      requestId: currentRequest[1],
                      updatedOn: 0, // Does not exist when fetched from chain
                      blockTimestamp: timestamp ? timestamp * 1000 : undefined, // Set from Cached Timestamps
                      estCompletionTime: timestamp
                        ? (timestamp + CASHOUT_EST_COMPLETION_TIME_IN_SECS) *
                          1000
                        : undefined, // estCompletionTime hardcoded to 1 hour
                    };
                    lambdaChainCombinedArray.splice(0, 0, combinedObject);
                  }
                }),
              );
              return lambdaChainCombinedArray;
            }
          }
        }),
      ).catch(err => {
        LogToLoot8Console('fetch error for User tx history');
        LogCustomError('getUserTxHistory', err.name, err.message, err.stack);
        console.error(err);
      });
    }

    return payments;
  },
);
export interface IAppUserSliceData {
  readonly UserWalletBalance?: Number;
  readonly UserData?: IUser;
  readonly UserOrders?: TransactionDetail[];
  readonly loading: boolean;
  readonly txLoading: boolean;
  readonly isNewUser: boolean;
  readonly AllUsersData: [];
  readonly linkedExternalAccounts: string[];
  readonly initialLinkedExtAccLoaded: boolean;
  readonly UserTxHistory: any[];
  readonly gatekeeperAt: string[];
}

const initialState: IAppUserSliceData = {
  UserData: null,
  loading: false,
  UserWalletBalance: 0,
  UserOrders: null,
  isNewUser: false,
  AllUsersData: [],
  linkedExternalAccounts: [],
  initialLinkedExtAccLoaded: false,
  UserTxHistory: [],
  txLoading: false,
  gatekeeperAt: [],
};

const AppUserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updateUserDataURI(state, action) {
      state.UserData.dataURI = action.payload.dataURI;
    },
    clearUserState(state) {
      state.isNewUser = false;
    },
    setAllUserData(state, action) {
      state.AllUsersData = action.payload.AllUsersData;
    },
    setGateKeeperAt(state, action) {
      state.gatekeeperAt = action.payload;
    },
    resetAppUser(state) {
      setAll(state, initialState);
    },
    pushExternalLinkedAccount(state, action) {
      if (
        !state.linkedExternalAccounts.find(
          p => p?.toLowerCase() == action.payload?.toLowerCase(),
        )
      ) {
        state.linkedExternalAccounts.push(action.payload);
      }
    },
    removeExternalLinkedAccount(state, action) {
      if (
        state.linkedExternalAccounts.find(
          p => p?.toLowerCase() == action.payload?.toLowerCase(),
        )
      ) {
        state.linkedExternalAccounts = state.linkedExternalAccounts.filter(
          p => p?.toLowerCase() !== action.payload?.toLowerCase(),
        );
      }
    },
    initialExtAccLoaded(state, action) {
      state.initialLinkedExtAccLoaded = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadUserDetail.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(loadUserBalance.fulfilled, (state, action) => {
        state.UserWalletBalance = action.payload.UserWalletBalance;
        state.loading = false;
      })
      .addCase(
        loadUserBalance.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadUserBalance',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadUserDetail.fulfilled, (state, action) => {
        state.UserData = action.payload.UserData;
        state.loading = false;
      })
      .addCase(getUserStatus.fulfilled, (state, action) => {
        if (state.UserData) {
          state.UserData.status = action.payload?.status;
        }
        state.loading = false;
      })
      .addCase(
        loadUserDetail.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadUserDetail',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(setUserStatus.fulfilled, (state, action) => {
        if (state.UserData) {
          state.UserData.status = action.payload.UserData?.status;
        }
        state.loading = false;
      })
      .addCase(setUserStatus.pending, state => {
        state.loading = true;
      })
      .addCase(setUserName.pending, state => {
        state.loading = false;
      })
      .addCase(setUserName.fulfilled, (state, action) => {
        state.UserData = action.payload.UserData;
        state.loading = false;
      })
      .addCase(CreateUser.pending, state => {
        state.loading = true;
      })
      .addCase(CreateUser.fulfilled, state => {
        state.loading = false;
      })
      .addCase(CreateUser.rejected, (state, { error }: any) => {
        state.loading = false;
        showToastMessage();
        LogCustomError('CreateUser', error.name, error.message, error.stack);
      })
      .addCase(CreateUserDetail.pending, state => {
        state.isNewUser = false;
      })
      .addCase(CreateUserDetail.fulfilled, state => {
        state.loading = false;
        state.isNewUser = true;
      })
      .addCase(CreateUserDetail.rejected, (state, { error }: any) => {
        state.loading = false;
        LogCustomError(
          'CreateUserDetail',
          error.name,
          error.message,
          error.stack,
        );
      })
      .addCase(loadUserAllPageDetails.fulfilled, state => {
        state.loading = false;
      })
      .addCase(loadUserAllPageDetails.rejected, (state, { error }: any) => {
        state.loading = false;
        LogCustomError(
          'loadUserAllPageDetails',
          error.name,
          error.message,
          error.stack,
        );
      })
      .addCase(setUserAvatar.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(setUserAvatar.rejected, (state, { error }: any) => {
        state.loading = false;
        showToastMessage();
        LogCustomError('setUserAvatar', error.name, error.message, error.stack);
      })
      .addCase(setUserAvatar.fulfilled, (state, action) => {
        if (action.payload) {
          state.UserData = action.payload.UserData;
        }
        state.loading = false;
      })
      .addCase(DeleteUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteUser.rejected, (state, { error }: any) => {
        state.loading = false;
        LogCustomError('DeleteUser', error.name, error.message, error.stack);
      })
      .addCase(DeleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isNewUser = false;
      })
      .addCase(getExternalLinkedAccounts.fulfilled, (state, action) => {
        state.linkedExternalAccounts = action.payload;
      })
      .addCase(getUserTXHistory.pending, (state, action) => {
        state.txLoading = true;
      })
      .addCase(getUserTXHistory.fulfilled, (state, action) => {
        state.UserTxHistory = action.payload;
        state.txLoading = false;
      });
  },
});

export const AppUserSliceReducer = AppUserSlice.reducer;

const baseInfo = (state: RootState) => state.AppUser;

export const {
  fetchAppSuccess,
  updateUserDataURI,
  clearUserState,
  resetAppUser,
  setAllUserData,
  pushExternalLinkedAccount,
  removeExternalLinkedAccount,
  initialExtAccLoaded,
  setGateKeeperAt,
} = AppUserSlice.actions;

export const getUserState = createSelector(baseInfo, User => User);
